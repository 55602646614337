<template>
  <div id="app" class="container-fluid" >
    <HelloWorld/>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
html {
     overflow-x: hidden;
    width: 100%;
}
body {
    width: 100%
}
body.modal-open {
    overflow: visible;
}
* {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    -webkit-tap-highlight-color: transparent;
}
a:focus,a:visited,a:active{
  outline: none;
}
a{
  color:#888 !important;
}
.modal-open{
  overflow: hidden;
}
body{
    touch-action: manipulation;

}
@font-face {
    font-family: 'SukhumvitSet';
    font-style: normal;
    font-weight: normal;
    src: url(./assets/fonts/SukhumvitSet-Text.ttf) format('truetype');
}

@font-face {
    font-family: 'SukhumvitSet';
    font-style: normal;
    font-weight: bold;
    src: url(./assets/fonts/SukhumvitSet-Bold.ttf) format('truetype');
}

body{
   font-family: SukhumvitSet,Arial, sans-serif !important;
}
#app {
  font-family: SukhumvitSet,Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}
</style>
