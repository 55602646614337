<template>
     <div style="height:100vh;"> 
          
          <!-- PDF Generator -->
          <!-- Report 2 -->
          <div>
               <vue-html2pdf
               :show-layout="false"
               :float-layout="true"
               :enable-download="false"
               :preview-modal="true"
               :paginate-elements-by-height="16000"
               filename="Report3"
               :pdf-quality="2"
               :manual-pagination="false"
               pdf-format="a4"
               pdf-orientation="portrait"
               pdf-content-width="750px"
               :html-to-pdf-options="{
                    filename:'Report3',
                    margin : [8,12,8,8],
                    jsPDF: { format: 'a4', orientation: 'portrait' },
                    image: { type: 'jpeg',  quality: 1 },
                    html2canvas: { scale: 2, useCORS: true },
               }"
               @progress="genPDFProgress($event)"
               @hasStartedGeneration="hasStartedGeneration()"
               @hasGenerated="hasGenerated($event)"
               ref="Report3"
          >
               <section slot="pdf-content" style="font-size:0.9em;">
                         <div class="row pl-4 pr-4">
                              <div class="col-12 pl-4 pr-4" >
                              <h5 class="font-weight-bold text-center mb-3"> รายการจัดสินค้า{{todayDateX()}}</h5>
                              </div>
                              <div class="row w-100 mb-2">
                                   <div class="col-4 text-center font-weight-bold">ชื่อสินค้า</div>
                                   <div class="col-2 text-center font-weight-bold">นับเช้า</div>
                                   <div class="col-1 pl-0 pr-0 text-center font-weight-bold">ขายออก</div>
                                   <div class="col-1 pl-0 pr-0 text-center font-weight-bold">เหลือ</div>
                                   <div class="col-2  text-center font-weight-bold">นับเย็น</div>
                                   <div class="col-2  pl-0 text-center font-weight-bold">หมายเหตุ</div>
                              </div>
                              <div class="row w-100" v-for="(i,id) in random_stocks" :key="id" style="margin-bottom:2px;">
                                                  <div class="col-4 text-truncate" >
                                                       <strong>{{id+1}}.</strong> &nbsp;&nbsp;<span :style="(i.TODAY_SOR_QTY>0) ? 'text-decoration:underline;' : '' ">{{i.STKDES}}</span>
                                                  </div>
                                                  <div class="col-2 text-right">
                                                       <template v-if="i.counted_at == new Date().toJSON().slice(0, 10)">
                                                            <div class="w-100 text-center font-weight-bold">{{ getValueFromAtom(i.COUNT_ATOM,i.CFACTOR).CONCAT_NO_PLUS }}</div>
                                                             </template>
                                                             <template v-else>
                                                                  <div class="w-100" style="border:1px solid #aaa;">&nbsp;</div>
                                                            </template>
                                                  </div>
                                                  <div class="col-1 pl-1 pr-1 text-center">
                                                       {{ getValueFromAtom(i.TODAY_SELL_ATOM_QTY + (i.ATOM_ORDQTY-i.ATOM_ORDQTY_DONE),i.CFACTOR).CONCAT_NO_PLUS}} 
                                                  </div>
                                                  <div class="col-1 pl-1 pr-1 text-center">
                                                       <template v-if="i.counted_at == new Date().toJSON().slice(0, 10)">
                                                            {{ getValueFromAtom(i.COUNT_ATOM-i.ATOM_ORDQTY,i.CFACTOR).CONCAT_NO_PLUS}} 
                                                       </template>
                                                        <template v-else>
                                                                  <div class="w-100" style="border:1px solid #aaa;">&nbsp;</div>
                                                            </template>
                                                  </div>
                                                  <div class="col-2  text-right">
                                                       <template v-if="i.counted_at_last == new Date().toJSON().slice(0, 10) && i.counted_at == new Date().toJSON().slice(0, 10)">
                                                            <div class="w-100 text-center font-weight-bold" >
                                                            {{ getValueFromAtom(i.COUNT_ATOM_LAST,i.CFACTOR).CONCAT_NO_PLUS }}</div>
                                                            </template>
                                                       <template v-else> <div class="w-100" style="border:1px solid #aaa;">&nbsp;</div></template>
                                                  </div>
                                                  
                                                  <div class="col-2  pl-0 text-center">
                                                       <template v-if="i.counted_at_last == new Date().toJSON().slice(0, 10) && i.counted_at == new Date().toJSON().slice(0, 10)">
                                                            <span class="font-weight-bold">
                                                             {{ (i.COUNT_ATOM_LAST - (i.COUNT_ATOM-i.ATOM_ORDQTY) == 0) ? '✔' : 
                                                           (0 > (i.COUNT_ATOM_LAST - (i.COUNT_ATOM-i.ATOM_ORDQTY))) ? 'ขาด' : 'เกิน'}}  </span>
                                                           <span v-show="(i.COUNT_ATOM_LAST - (i.COUNT_ATOM-i.ATOM_ORDQTY) != 0)">
                                                           {{getValueFromAtom(i.COUNT_ATOM_LAST - (i.COUNT_ATOM-i.ATOM_ORDQTY),i.CFACTOR).CONCAT}}  
                                                           {{i.PQUCOD}}
                                                           </span> 
                                                            </template>
                                                       <template v-else> <div class="w-100" style="border:1px solid #aaa;">&nbsp;</div></template>
                                                  </div>
                              </div>
     
                              <div class="row w-100 mt-2 pl-2 pr-2">
     
                                   <div class="col-3 pl-1 pr-1 pt-1 pb-0" v-for="(truck,i) in random_stocks_trucks" :key="i">
                                        <div class="w-100 pl-0 pr-0 pt-0 pb-0" style="border:1px solid #888;height:140px;">
                                              <div class="row pl-3 pr-3 pt-0 pb-0" > 
                                                    <div class="col-6 p-0 pb-1 bg-dark text-center text-light" style="margin-left:-2px;"><span class="font-weight-bold" style="font-size:1em;">{{i}}</span></div>
                                                  <div class="col-6 p-0 pb-1 text-right font-weight-bold">{{truck.total_value.toLocaleString(undefined, {minimumFractionDigits: 0})}}฿</div>
                                              </div>
                                             <div class="row pl-3 pr-3 pt-0 pb-0" style="font-size:0.8em;">
                                                  <div class="col-6 p-1">
                                                       <div class="text-truncate" v-for="(cus,k) in truck.cus_name.filter((a,b)=>b<6)" :key="k">{{k+1}}. {{cus}}  </div>
                                                  </div>
                                                  <div class="col-6 p-1">
                                                       <div class="text-truncate" v-for="(cus,k) in truck.cus_name.filter((a,b)=>b>=6)" :key="k">
                                                            <span v-show="(k+7)<=12">
     
                                                                 <template v-if="truck.cus_name.length > 10 && (k+7)==12">...และอีก {{truck.cus_name.length-11}} ราย</template>
     
                                                                 <template v-else-if="(k+7) <= 12">
                                                                      {{k+7}}. {{cus}}
                                                                 </template>
                                                            </span>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <div class="row w-100 mt-2 pl-2 pr-2">
                                   <div class="col-1 pl-1 pr-1" >
                                         <span style="font-size:0.8em;position:absolute;top:0px;left:13px;"><strong>ซ้อเบิก</strong></span>
                                        <div class="w-100 text-center" style="border:1px solid #999;height:50px;position:relative;line-height:60px;vertical-align:bottom;">
                                             <span style="font-size:1.3em;">
                                                  {{
                                                       random_stocks.reduce((total,value)=>{
                                                             if(value.TODAY_SOR_QTY>0) total = '✔';
                                                             if(value.TODAY_SOR_QTY==0) total = '';
                                                            return total;
                                                       },0)
                                                  }}
                                             </span>
                                        </div>
                                   </div>
                                   <div class="col-2 pl-1 pr-1" >
                                        <span style="font-size:0.8em;position:absolute;top:0px;left:8px;"><strong>มูลค่าสินค้าขาด</strong></span>
                                        <div class="w-100 text-center" style="border:1px solid #999;height:50px;position:relative;line-height:60px;vertical-align:bottom;">
                                             <span class="font-weight-bold" style="font-size:1em;">
                                                  
                                                  <template v-if="random_stocks_diff_value">
                                                       {{ random_stocks_diff_value.toLocaleString(undefined, {minimumFractionDigits: 0}) }}฿
                                                  </template>
                                                  <template v-else>-</template>
                                                  
                                                   
                                             </span>
                                        </div>
                                   </div>
                                   <div class="col-3 pl-1 pr-1" >
                                        <span style="font-size:0.8em;position:absolute;top:0px;left:8px;">ผู้รับผิดชอบ <strong>นับเช้า</strong> 
                                       {{today_sells.atom_qty}} : {{today_sells.volumn-today_sells.cost}}</span>
                                        <div class="w-100" style="border:1px solid #999;height:50px;"></div>
                                   </div>
                                   <div class="col-3 pl-1 pr-1" >
                                        <span style="font-size:0.8em;position:absolute;top:0px;left:8px;">ผู้รับผิดชอบ <strong>คีย์ข้อมูล</strong></span>
                                        <div class="w-100" style="border:1px solid #999;height:50px;"></div>
                                   </div>
                                   <div class="col-3 pl-1 pr-1" >
                                        <span style="font-size:0.8em;position:absolute;top:0px;left:8px;">ผู้รับผิดชอบ <strong>นับเย็น</strong></span>
                                        <div class="w-100" style="border:1px solid #999;height:50px;"></div>
                                   </div>
                                   
                              </div>
     
                                        
                                        
                                       
                              
                         </div>
               </section>
               </vue-html2pdf>
          </div>
          <!-- Report 2 -->
          <div>
               <vue-html2pdf
               :show-layout="false"
               :float-layout="true"
               :enable-download="true"
               :preview-modal="false"
               :paginate-elements-by-height="1400"
               filename="Report2"
               :pdf-quality="2"
               :manual-pagination="false"
               pdf-format="a5"
               pdf-orientation="portrait"
               pdf-content-width="500px"
               :html-to-pdf-options="{
                    filename:'Report2',
                    margin : [8,8,8,8],
                    jsPDF: { format: 'a5', orientation: 'portrait' },
                    image: { type: 'jpeg',  quality: 1 },
                    html2canvas: { scale: 2, useCORS: true },
               }"
               @progress="genPDFProgress($event)"
               @hasStartedGeneration="hasStartedGeneration()"
               @hasGenerated="hasGenerated($event)"
               ref="Report2"
          >
               <section slot="pdf-content">
                         <div class="row pl-4 pr-4">
                              <div class="col-12 pl-4 pr-4" >
                              <h6 class="font-weight-bold text-center mb-1">รายการสินค้าประเภทน้ำมัน {{todayDateX()}}</h6>
                                        <table class="table table-sm table-borderless no-padding m-0 p-0 " style="font-size:0.75em;color:black;">
                                                       <tbody >
                                                       <tr v-for="(i,id) in stocks_by_STKGRP('230A')" :key="id" >
                                                            <td>
                                                                 <section v-for="(n,k) in i" :key="k" class="pdf-item m-0 p-0" >
                                                                      <div class="d-flex p-0 m-0" >
                                                                           <div  class="w-100 pl-1 " style="position:relative;padding-top:1px;padding-bottom:1px;border-bottom:1px solid #999;border-left:1px solid #999;" :style="(k==0) && 'border-top:1px solid #999;'">
                                                                                <strong>{{n.STKDES}}</strong>
                                                                           </div>
                                                                           <div class="text-left" style="width:200px;padding-top:1px;padding-bottom:1px;border-bottom:1px solid #999;color:#777;font-size:0.9em;" :style="(k==0) && 'border-top:1px solid #999;'">ขาย : {{n.AVG_SELL}} {{n.PQUCOD}}/{{(n.order_frequency == 30) ? "เดือน" : (n.order_frequency == 7) ? "สัปดาห์" : "เดือน" }}</div>
                                                                           <div class=" text-center" style="position:relative;color:black;width:80px;padding-top:1px;padding-bottom:1px;border-bottom:1px solid #111;border-left:1px solid #111;border-right:1px solid #111;" :style="(k==0) && 'border-top:1px solid #111;'">
                                                                                <span v-if="!n.NOT_COUNT" style="position:absolute;z-index:990;font-size:1.1em;left:0;right:0;top:0px;" :style="(!n.count_expired) ? 'font-weight:bold' : 'font-weight:normal;' ">{{n.LOCBAL}}</span>
                                                                                <div class="p-0 text-center" style="position:absolute;left:0;top:0;height:100%;width:100%;height:100%;">
                                                                                     <div  style="height:100%;background:#bbb !important;" :style="(n.PERCENT>100) ? 'width:100%' : (n.PERCENT<=0) ? 'width:0px' : 'width:'+n.PERCENT+'%'"></div>
                                                                                </div>
                                                                                
                                                                           </div>
                                                                      </div>
                                                                      <div v-if="n.seq == 30 && n.length > 30" class="html2pdf__page-break"/>
                                                                  </section>
                                                                  <br style="content: ''; margin: 2em;display: block;font-size: 30%;"/>
                                                            </td>
                                                       </tr>
                                                       </tbody>
                                        </table>
                              </div>
                         </div>
     
                         <div class="html2pdf__page-break"/>
                         <div class="row pl-4 pr-4">
                              <div class="col-12 pl-4 pr-4" >
                              <h6 class="font-weight-bold text-center mb-1">รายการสินค้าประเภทแป้ง {{todayDateX()}}</h6>
                                        <table class="table table-sm table-borderless no-padding m-0 p-0 " style="font-size:0.75em;color:black;">
                                                       <tbody >
                                                       <tr v-for="(i,id) in stocks_by_STKGRP('110A')" :key="id" >
                                                             <td>
                                                                 <section v-for="(n,k) in i" :key="k" class="pdf-item m-0 p-0" >
                                                                      <div class="d-flex p-0 m-0" >
                                                                           <div  class="w-100 pl-1 " style="position:relative;padding-top:1px;padding-bottom:1px;border-bottom:1px solid #999;border-left:1px solid #999;" :style="(k==0) && 'border-top:1px solid #999;'">
                                                                                <strong>{{n.STKDES}}</strong>
                                                                           </div>
                                                                           <div class="text-left" style="width:200px;padding-top:1px;padding-bottom:1px;border-bottom:1px solid #999;color:#777;font-size:0.9em;" :style="(k==0) && 'border-top:1px solid #999;'">ขาย : {{n.AVG_SELL}} {{n.PQUCOD}}/{{(n.order_frequency == 30) ? "เดือน" : (n.order_frequency == 7) ? "สัปดาห์" : "เดือน" }}</div>
                                                                           <div class=" text-center" style="position:relative;color:black;width:80px;padding-top:1px;padding-bottom:1px;border-bottom:1px solid #111;border-left:1px solid #111;border-right:1px solid #111;" :style="(k==0) && 'border-top:1px solid #111;'">
                                                                                <span v-if="!n.NOT_COUNT" style="position:absolute;z-index:990;font-size:1.1em;left:0;right:0;top:0px;" :style="(!n.count_expired) ? 'font-weight:bold' : 'font-weight:normal;' ">{{n.LOCBAL}}</span>
                                                                                <div class="p-0 text-center" style="position:absolute;left:0;top:0;height:100%;width:100%;height:100%;">
                                                                                     <div  style="height:100%;background:#bbb !important;" :style="(n.PERCENT>100) ? 'width:100%' : (n.PERCENT<=0) ? 'width:0px' : 'width:'+n.PERCENT+'%'"></div>
                                                                                </div>
                                                                                
                                                                           </div>
                                                                      </div>
                                                                      <div v-if="n.seq == 30 && n.length > 30" class="html2pdf__page-break"/>
                                                                  </section>
                                                                  <br style="content: ''; margin: 2em;display: block;font-size: 30%;"/>
                                                            </td>
                                                       </tr>
                                                       </tbody>
                                        </table>
                              <h6 class="font-weight-bold text-center mb-1">เฮลบลูบอย {{todayDateX()}}</h6>
                                        <table class="table table-sm table-borderless no-padding m-0 p-0 " style="font-size:0.75em;color:black;">
                                                       <tbody >
                                                       <tr v-for="(i,id) in stocks_by_STKGRP('210B')" :key="id">
                                                             <td>
                                                                 <section v-for="(n,k) in i" :key="k" class="pdf-item m-0 p-0" >
                                                                      <div class="d-flex p-0 m-0" >
                                                                           <div  class="w-100 pl-1 " style="position:relative;padding-top:1px;padding-bottom:1px;border-bottom:1px solid #999;border-left:1px solid #999;" :style="(k==0) && 'border-top:1px solid #999;'">
                                                                                <strong>{{n.STKDES}}</strong>
                                                                           </div>
                                                                           <div class="text-left" style="width:200px;padding-top:1px;padding-bottom:1px;border-bottom:1px solid #999;color:#777;font-size:0.9em;" :style="(k==0) && 'border-top:1px solid #999;'">ขาย : {{n.AVG_SELL}} {{n.PQUCOD}}/{{(n.order_frequency == 30) ? "เดือน" : (n.order_frequency == 7) ? "สัปดาห์" : "เดือน" }}</div>
                                                                           <div class=" text-center" style="position:relative;color:black;width:80px;padding-top:1px;padding-bottom:1px;border-bottom:1px solid #111;border-left:1px solid #111;border-right:1px solid #111;" :style="(k==0) && 'border-top:1px solid #111;'">
                                                                                <span v-if="!n.NOT_COUNT" style="position:absolute;z-index:990;font-size:1.1em;left:0;right:0;top:0px;" :style="(!n.count_expired) ? 'font-weight:bold' : 'font-weight:normal;' ">{{n.LOCBAL}}</span>
                                                                                <div class="p-0 text-center" style="position:absolute;left:0;top:0;height:100%;width:100%;height:100%;">
                                                                                     <div  style="height:100%;background:#bbb !important;" :style="(n.PERCENT>100) ? 'width:100%' : (n.PERCENT<=0) ? 'width:0px' : 'width:'+n.PERCENT+'%'"></div>
                                                                                </div>
                                                                                
                                                                           </div>
                                                                      </div>
                                                                      <div v-if="n.seq == 30 && n.length > 30" class="html2pdf__page-break"/>
                                                                  </section>
                                                                  <br style="content: ''; margin: 2em;display: block;font-size: 30%;"/>
                                                            </td>
                                                       </tr>
                                                      
                                                       </tbody>
                                        </table>
                              <h6 class="font-weight-bold text-center mb-1">รายการสินค้าประเภทเส้น {{todayDateX()}}</h6>
                                        <table class="table table-sm table-borderless no-padding m-0 p-0 " style="font-size:0.75em;color:black;">
                                                       <tbody >
                                                       <tr v-for="(i,id) in stocks_by_STKGRP('230C')" :key="id">
                                                             <td>
                                                                 <section v-for="(n,k) in i" :key="k" class="pdf-item m-0 p-0" >
                                                                      <div class="d-flex p-0 m-0" >
                                                                           <div  class="w-100 pl-1 " style="position:relative;padding-top:1px;padding-bottom:1px;border-bottom:1px solid #999;border-left:1px solid #999;" :style="(k==0) && 'border-top:1px solid #999;'">
                                                                                <strong>{{n.STKDES}}</strong>
                                                                           </div>
                                                                           <div class="text-left" style="width:200px;padding-top:1px;padding-bottom:1px;border-bottom:1px solid #999;color:#777;font-size:0.9em;" :style="(k==0) && 'border-top:1px solid #999;'">ขาย : {{n.AVG_SELL}} {{n.PQUCOD}}/{{(n.order_frequency == 30) ? "เดือน" : (n.order_frequency == 7) ? "สัปดาห์" : "เดือน" }}</div>
                                                                           <div class=" text-center" style="position:relative;color:black;width:80px;padding-top:1px;padding-bottom:1px;border-bottom:1px solid #111;border-left:1px solid #111;border-right:1px solid #111;" :style="(k==0) && 'border-top:1px solid #111;'">
                                                                                <span v-if="!n.NOT_COUNT" style="position:absolute;z-index:990;font-size:1.1em;left:0;right:0;top:0px;" :style="(!n.count_expired) ? 'font-weight:bold' : 'font-weight:normal;' ">{{n.LOCBAL}}</span>
                                                                                <div class="p-0 text-center" style="position:absolute;left:0;top:0;height:100%;width:100%;height:100%;">
                                                                                     <div  style="height:100%;background:#bbb !important;" :style="(n.PERCENT>100) ? 'width:100%' : (n.PERCENT<=0) ? 'width:0px' : 'width:'+n.PERCENT+'%'"></div>
                                                                                </div>
                                                                                
                                                                           </div>
                                                                      </div>
                                                                      <div v-if="n.seq % 30 ==0 && n.length>30" class="html2pdf__page-break"/>
                                                                  </section>
                                                                  <br style="content: ''; margin: 2em;display: block;font-size: 30%;"/>
                                                            </td>
                                                       </tr>
                                                      
                                                       </tbody>
                                        </table>
                              <h6 class="font-weight-bold text-center mb-1">เกลือ {{todayDateX()}}</h6>
                                        <table class="table table-sm table-borderless no-padding m-0 p-0 " style="font-size:0.75em;color:black;">
                                                       <tbody >
                                                       <tr v-for="(i,id) in stocks_by_STKGRP('220C')" :key="id">
                                                            <td>
                                                                 <section v-for="(n,k) in i" :key="k" class="pdf-item m-0 p-0" >
                                                                      <div class="d-flex p-0 m-0" >
                                                                           <div  class="w-100 pl-1 " style="position:relative;padding-top:1px;padding-bottom:1px;border-bottom:1px solid #999;border-left:1px solid #999;" :style="(k==0) && 'border-top:1px solid #999;'">
                                                                                <strong>{{n.STKDES}}</strong>
                                                                           </div>
                                                                           <div class="text-left" style="width:200px;padding-top:1px;padding-bottom:1px;border-bottom:1px solid #999;color:#777;font-size:0.9em;" :style="(k==0) && 'border-top:1px solid #999;'">ขาย : {{n.AVG_SELL}} {{n.PQUCOD}}/{{(n.order_frequency == 30) ? "เดือน" : (n.order_frequency == 7) ? "สัปดาห์" : "เดือน" }}</div>
                                                                           <div class=" text-center" style="position:relative;color:black;width:80px;padding-top:1px;padding-bottom:1px;border-bottom:1px solid #111;border-left:1px solid #111;border-right:1px solid #111;" :style="(k==0) && 'border-top:1px solid #111;'">
                                                                                <span v-if="!n.NOT_COUNT" style="position:absolute;z-index:990;font-size:1.1em;left:0;right:0;top:0px;" :style="(!n.count_expired) ? 'font-weight:bold' : 'font-weight:normal;' ">{{n.LOCBAL}}</span>
                                                                                <div class="p-0 text-center" style="position:absolute;left:0;top:0;height:100%;width:100%;height:100%;">
                                                                                     <div  style="height:100%;background:#bbb !important;" :style="(n.PERCENT>100) ? 'width:100%' : (n.PERCENT<=0) ? 'width:0px' : 'width:'+n.PERCENT+'%'"></div>
                                                                                </div>
                                                                                
                                                                           </div>
                                                                      </div>
                                                                      <div v-if="n.seq == 30 && n.length > 30" class="html2pdf__page-break"/>
                                                                  </section>
                                                                  <br style="content: ''; margin: 2em;display: block;font-size: 30%;"/>
                                                            </td>
                                                       </tr>
                                                      
                                                       </tbody>
                                        </table>
                              </div>
                         </div>
     
                         <div class="html2pdf__page-break"/>
                         <div class="row pl-4 pr-4">
                              <div class="col-12 pl-4 pr-4" >
                              <h6 class="font-weight-bold text-center mb-1">รายการสินค้าประเภทน้ำตาล {{todayDateX()}}</h6>
                                        <table class="table table-sm table-borderless no-padding m-0 p-0 " style="font-size:0.75em;color:black;">
                                                       <tbody >
                                                       <tr v-for="(i,id) in stocks_by_STKGRP('220D')" :key="id">
                                                             <td>
                                                                 <section v-for="(n,k) in i" :key="k" class="pdf-item m-0 p-0" >
                                                                      <div class="d-flex p-0 m-0" >
                                                                           <div  class="w-100 pl-1 " style="position:relative;padding-top:1px;padding-bottom:1px;border-bottom:1px solid #999;border-left:1px solid #999;" :style="(k==0) && 'border-top:1px solid #999;'">
                                                                                <strong>{{n.STKDES}}</strong>
                                                                           </div>
                                                                           <div class="text-left" style="width:200px;padding-top:1px;padding-bottom:1px;border-bottom:1px solid #999;color:#777;font-size:0.9em;" :style="(k==0) && 'border-top:1px solid #999;'">ขาย : {{n.AVG_SELL}} {{n.PQUCOD}}/{{(n.order_frequency == 30) ? "เดือน" : (n.order_frequency == 7) ? "สัปดาห์" : "เดือน" }}</div>
                                                                           <div class=" text-center" style="position:relative;color:black;width:80px;padding-top:1px;padding-bottom:1px;border-bottom:1px solid #111;border-left:1px solid #111;border-right:1px solid #111;" :style="(k==0) && 'border-top:1px solid #111;'">
                                                                                <span v-if="!n.NOT_COUNT" style="position:absolute;z-index:990;font-size:1.1em;left:0;right:0;top:0px;" :style="(!n.count_expired) ? 'font-weight:bold' : 'font-weight:normal;' ">{{n.LOCBAL}}</span>
                                                                                <div class="p-0 text-center" style="position:absolute;left:0;top:0;height:100%;width:100%;height:100%;">
                                                                                     <div  style="height:100%;background:#bbb !important;" :style="(n.PERCENT>100) ? 'width:100%' : (n.PERCENT<=0) ? 'width:0px' : 'width:'+n.PERCENT+'%'"></div>
                                                                                </div>
                                                                                
                                                                           </div>
                                                                      </div>
                                                                      <div v-if="n.seq == 30 && n.length > 30" class="html2pdf__page-break"/>
                                                                  </section>
                                                                  <br style="content: ''; margin: 2em;display: block;font-size: 30%;"/>
                                                            </td>
                                                       </tr>
                                                      
                                                       </tbody>
                                        </table>
                              </div>
                         </div>
     
                         <div class="html2pdf__page-break"/>
                         <div class="row pl-4 pr-4">
                              <div class="col-12 pl-4 pr-4" >
                              <h6 class="font-weight-bold text-center mb-1">รายการสินค้าประเภทซอส {{todayDateX()}}</h6>
                                        <table class="table table-sm table-borderless no-padding m-0 p-0 " style="font-size:0.75em;color:black;">
                                                       <tbody >
                                                       <tr v-for="(i,id) in stocks_by_STKGRP('220B')" :key="id">
                                                          <td>
                                                                 <section v-for="(n,k) in i" :key="k" class="pdf-item m-0 p-0" >
                                                                      <div class="d-flex p-0 m-0" >
                                                                           <div  class="w-100 pl-1 " style="position:relative;padding-top:1px;padding-bottom:1px;border-bottom:1px solid #999;border-left:1px solid #999;" :style="(k==0) && 'border-top:1px solid #999;'">
                                                                                <strong>{{n.STKDES}}</strong>
                                                                           </div>
                                                                           <div class="text-left" style="width:200px;padding-top:1px;padding-bottom:1px;border-bottom:1px solid #999;color:#777;font-size:0.9em;" :style="(k==0) && 'border-top:1px solid #999;'">ขาย : {{n.AVG_SELL}} {{n.PQUCOD}}/{{(n.order_frequency == 30) ? "เดือน" : (n.order_frequency == 7) ? "สัปดาห์" : "เดือน" }}</div>
                                                                           <div class=" text-center" style="position:relative;color:black;width:80px;padding-top:1px;padding-bottom:1px;border-bottom:1px solid #111;border-left:1px solid #111;border-right:1px solid #111;" :style="(k==0) && 'border-top:1px solid #111;'">
                                                                                <span v-if="!n.NOT_COUNT" style="position:absolute;z-index:990;font-size:1.1em;left:0;right:0;top:0px;" :style="(!n.count_expired) ? 'font-weight:bold' : 'font-weight:normal;' ">{{n.LOCBAL}}</span>
                                                                                <div class="p-0 text-center" style="position:absolute;left:0;top:0;height:100%;width:100%;height:100%;">
                                                                                     <div  style="height:100%;background:#bbb !important;" :style="(n.PERCENT>100) ? 'width:100%' : (n.PERCENT<=0) ? 'width:0px' : 'width:'+n.PERCENT+'%'"></div>
                                                                                </div>
                                                                                
                                                                           </div>
                                                                      </div>
                                                                      <div v-if="n.seq == 30 && n.length > 30" class="html2pdf__page-break"/>
                                                                  </section>
                                                                  <br style="content: ''; margin: 2em;display: block;font-size: 30%;"/>
                                                            </td>
                                                       </tr>
                                                      
                                                       </tbody>
                                        </table>
                              </div>
                         </div>
     
     
                         
               </section>
               
                
     
               </vue-html2pdf>
          </div>
          <!-- Report 1 -->
          <div>
               <vue-html2pdf
               :show-layout="false"
               :float-layout="true"
               :enable-download="true"
               :preview-modal="false"
               :paginate-elements-by-height="1400"
               :filename="today"
               :pdf-quality="2"
               :manual-pagination="false"
               pdf-format="a5"
               pdf-orientation="portrait"
               pdf-content-width="560px"
     
               @progress="genPDFProgress($event)"
               @hasStartedGeneration="hasStartedGeneration()"
               @hasGenerated="hasGenerated($event)"
               ref="Report1"
          >
               <section slot="pdf-content">
                         <div class="row m-2 ml-3" style="height:778px;">
                              <div class="col-6 pl-3 pr-3 pt-0 pb-0" style="border-right:1px solid #ddd;height:778px;">
                              <div class="row" style="height:778px;">
                                   <div class="col-12 p-2 pl-2 pr-2 " style="border-bottom:1px solid #ddd;height:50%;overflow:hidden;">
                                        
                                        <h6 class="font-weight-bold text-center" style="position:ralative;"><img  src="../assets/bss2008.png" class="d-inline-block mr-1" style="height:40px;margin-top:2px;">{{todayDateX()}}</h6>
                                        <table class="table table-sm table-borderless no-padding  p-0 mt-2 " style="font-size:0.9em;">
                                        <tbody>
                                             <tr>
                                                  <td >สินค้าใกล้หมด</td>
                                                  <td class="text-right">{{stocks_warning_today_counted.length}}/{{stocks_warning.length}} </td>
                                                  <td class="text-right">รายการ</td>
                                             </tr>
                                             <tr>
                                                  <td>สินค้าตามรอบ</td>
                                                  <td class="text-right">{{stocks_schedule_count}}/{{mushCount_count}}</td>
                                                  <td class="text-right">รายการ</td>
                                             </tr>
                                             <tr>
                                                  <td >สินค้าอื่นๆ</td>
                                                  <td class="text-right">{{stocks_today_counted.length-(stocks_warning_today_counted.length+stocks_schedule_count)}} </td>
                                                  <td class="text-right">รายการ</td>
                                             </tr>
                                             <tr >
                                                  <td class="font-weight-bold">รวมนับสินค้าวันนี้</td>
                                                  <td class="text-right font-weight-bold">{{stocks_today_counted.length}} </td>
                                                  <td class="text-right font-weight-bold">รายการ</td>
                                             </tr>
                                             <tr><td colspan="3" style="font-size:0.5em;">&nbsp;</td></tr>
                                             
                                             <tr>
                                                  <td>สั่งสินค้าใกล้หมด</td>
                                                  <td class="text-right">{{stocks_warning_today_po.length}}/{{stocks_warning.length}} </td>
                                                  <td class="text-right">รายการ</td>
                                             </tr>
                                             <tr>
                                                  <td >สั่งสินค้าอื่นๆ</td>
                                                  <td class="text-right">{{stocks_today_po.length-stocks_warning_today_po.length}} </td>
                                                  <td class="text-right">รายการ</td>
                                             </tr>
                                                  <tr>
                                                  <td class="font-weight-bold">รวมเปิด PO วันนี้</td>
                                                  <td class="text-right font-weight-bold">{{stocks_today_po.length}} </td>
                                                  <td class="text-right font-weight-bold">รายการ</td>
                                             </tr>
                                             <tr><td colspan="3" style="font-size:0.5em;">&nbsp;</td></tr>
                                                  <tr>
                                                  <td >รับสินค้าเข้าไม่มี PO</td>
                                                  <td class="text-right">{{stocks_today_in.length - stocks_today_in_isPo.length}} </td>
                                                  <td class="text-right">รายการ</td>
                                             </tr>
                                             <tr>
                                                  <td >รับสินค้าเข้ามี PO</td>
                                                  <td class="text-right">{{stocks_today_in_isPo.length}}  </td>
                                                  <td class="text-right">รายการ</td>
                                             </tr>
                                             <tr>
                                                  <td class="font-weight-bold">รวมรับสินค้าวันนี้</td>
                                                  <td class="text-right font-weight-bold">{{stocks_today_in.length}} </td>
                                                  <td class="text-right font-weight-bold">รายการ</td>
                                             </tr>
                                             <tr><td colspan="3" style="font-size:0.5em;">&nbsp;</td></tr>
                                             <tr>
                                                  <td class="font-weight-bold">สินค้าค้างนับ</td>
                                                  <td class="text-right font-weight-bold">{{notCountFilter_count}} </td>
                                                  <td class="text-right font-weight-bold">รายการ</td>
                                             </tr>
     
     
                                             
                                             
                                        </tbody>
                                        </table>
                                   </div>
                                   <div class="col-12 p-2" style="height:50%;overflow:hidden;">
                                        <h6 class="font-weight-bold text-center mb-1">สินค้าใกล้หมดไม่มี PO {{stocks_warning.length}} รายการ</h6>
                                        <table class="table table-sm table-borderless no-padding m-0 p-0 " style="font-size:0.7em;">
                                                       <tbody >
                                                       <tr v-for="(i,id) in (stocks_warning.length >= 20) ? 20 : stocks_warning.length" :key="id">
                                                            <td class="text-truncate" >
                                                                 <div class="p-0 m-0 d-flex align-item-start">
                                                                 <span class="d-inline-block text-right p-0 m-0 mr-1" style="width:15px;"><strong>{{id+1}}.</strong></span> 
                                                                 <span  style="display:inline-block;width:170px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{stocks_warning[id].STKDES}}</span>
                                                                 </div>
                                                            </td>
                                                            <td  class="text-right ">{{stocks_warning[id].LOCBAL}}</td>
                                                            <td  class="text-right"> {{stocks_warning[id].PQUCOD}}</td>
                                                       </tr>
                                                       <tr v-if="stocks_warning.length >= 20">
                                                            <td class="text-center " colspan="3" style="font-style: italic;">... และอื่นๆอีก {{stocks_warning.length - 20}} รายการ</td> 
                                                       </tr>
                                                       </tbody>
                                        </table>
                                   </div>
                              </div>
     
                              </div>
     
                              <div class="col-6 pl-3 pr-3 pt-0 pb-0" style="height:778px;">
     
                              <div class="row">
                                   <div class="col-12 p-2 pb-3"  style="max-height:389px; border-bottom:1px solid #ddd;overflow:hidden;">
                                             <h6 class="font-weight-bold text-center mb-1">สต็อกขาดและเกิน {{stocks_today_counted_peterDiff.length}} รายการ</h6>
     
                                             <table v-if="stocks_today_counted_peterDiff.length > 0" class="table table-sm table-borderless no-padding m-0 p-0" style="font-size:0.7em;">
                                                  <tbody >
                                                  <tr v-for="(i,id) in (stocks_today_counted_peterDiff.length >= 20) ? 20 : stocks_today_counted_peterDiff.length" :key="id">
                                                       <td class="text-truncate" >
                                                            <div class="p-0 m-0 d-flex align-item-start">
                                                            <span class="d-inline-block text-right p-0 m-0 mr-1" style="width:15px;"><strong>{{id+1}}.</strong></span> 
                                                            <span  style="display:inline-block;width:170px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                                                                 <span v-if="stocks_today_counted_peterDiff[id].FIRST_TIME_COUNT" style="color:#aaa;">[ตั้งต้น] </span>
                                                                 {{stocks_today_counted_peterDiff[id].STKDES}} </span>
                                                            </div>
                                                       </td>
                                                       <td  class="text-right ">{{getValueFromAtom(stocks_today_counted_peterDiff[id].peter_DIFF,stocks_today_counted_peterDiff[id].CFACTOR).BIG}} </td>
                                                       <td  class="text-right"> {{stocks_today_counted_peterDiff[id].PQUCOD}}</td>
                                                  </tr>
                                                  <tr v-if="stocks_today_counted_peterDiff.length >= 20">
                                                       <td class="text-center " colspan="3" style="font-style: italic;">... และอื่นๆอีก {{stocks_today_counted_peterDiff.length - 20}} รายการ</td> 
                                                  </tr>
                                                  </tbody>
                                             </table>
                                             
                                   </div>
                                   <div class="col-12 p-2"  style="overflow:hidden;">
                                             <h6 class="font-weight-bold text-center mb-1">สินค้าค้างรับ {{stocks_is_po.length}} รายการ</h6>
                                        <table class="table table-sm table-borderless no-padding m-0 p-0 " style="font-size:0.7em;">
                                                       <tbody >
                                                       <tr v-for="(i,id) in (stocks_is_po.length >= 20) ? ((40 - ((stocks_today_counted_peterDiff.length > 20) ? 20 : stocks_today_counted_peterDiff.length)) > stocks_is_po.length) ? stocks_is_po.length : (40 - ((stocks_today_counted_peterDiff.length > 20) ? 20 : stocks_today_counted_peterDiff.length)) : stocks_is_po.length " :key="id">
                                                            <td class="text-truncate" >
                                                                 <div class="p-0 m-0 d-flex align-item-start">
                                                                 <span class="d-inline-block text-right p-0 m-0 mr-1" style="width:15px;"><strong>{{id+1}}.</strong></span> 
                                                                 <span  style="display:inline-block;width:120px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{stocks_is_po[id].STKDES}}</span>
                                                                 </div>
                                                            </td>
                                                            <td  class="text-right ">{{dayAgo(stocks_is_po[id].LASTEST_PODAT)}}</td>
                                                            
                                                       </tr>
                                                       <tr v-if="stocks_is_po.length > (((40 - ((stocks_today_counted_peterDiff.length > 20) ? 20 : stocks_today_counted_peterDiff.length)) > stocks_is_po.length) ? stocks_is_po.length : (40 - ((stocks_today_counted_peterDiff.length > 20) ? 20 : stocks_today_counted_peterDiff.length)))">
                                                            <td class="text-center " colspan="3" style="font-style: italic;">... และอื่นๆอีก {{stocks_is_po.length - (((40 - ((stocks_today_counted_peterDiff.length > 20) ? 20 : stocks_today_counted_peterDiff.length)) > stocks_is_po.length) ? stocks_is_po.length : (40 - ((stocks_today_counted_peterDiff.length > 20) ? 20 : stocks_today_counted_peterDiff.length)))}} รายการ</td> 
                                                       </tr>
                                                       </tbody>
                                        </table>
                                   </div>
                              </div>
     
                              </div>
     
                         </div>
               </section>
               </vue-html2pdf>
          </div>
     
          <!-- genPDF -->
          <div v-if="genPDF" class="loading" style="width:100%;height:80%;z-index:999;">
               <div class="d-flex align-item-center justify-content-center h-100">
               <div class="text-center d-flex flex-column align-item-center justify-content-center">
               <div >
                    <span class="text-danger" style="font-size: 5rem;"><b-icon icon="file-earmark-text-fill"  ></b-icon></span>
                    <div class="pl-1 pr-1 h5 font-weight-bold ">กำลังสร้างรายงาน...</div>
               </div>
               </div>
               </div>
          </div>
          <!-- Offline -->
          <div v-if="!onLine" class="loading" style="width:100%;height:80%;z-index:999;">
               <div class="d-flex align-item-center justify-content-center h-100">
               <div class="text-center d-flex flex-column align-item-center justify-content-center">
               <div >
                    <img src="../assets/offline.gif">
                    <div class="pl-1 pr-1 h5 font-weight-bold ">กำลังเชื่อมต่อ...</div>
               </div>
               </div>
               </div>
          </div>
          <!-- Loading -->
          <div v-if="(mainLoading && onLine)" class="loading" style="width:100%;height:80%;z-index: 999;">
               <div class="d-flex align-item-center justify-content-center h-100">
               <div class="text-center d-flex flex-column align-item-center justify-content-center">
               <div >
                    <img src="../assets/loading.gif">
                    <div class="pl-1 pr-1 h4 font-weight-bold ">กำลังโหลดข้อมูล</div>
                    <div class="row m-0 p-0" style="border:1px solid #ddd;border-radius:0.25em;">
                         <div class="bg-success text-dark" :class="['col-'+loadingClass]"> {{Math.floor(loadingClass*(100/12))}}% </div>
                    </div>
                    <div class="text-center" style="font-size:0.8em;color:#aaa;">&nbsp;{{fileName}}</div>
               </div>
               </div>
               </div>
          </div>
     
     
          <!-- START CONTENT -->
          <div v-if="(!mainLoading && onLine && !genPDF)">
               
               <!-- Modal Image -->
               <b-modal id="modal_image" modal-class="peter-modal" body-class="p-0 pt-0 pb-0 mt-0"
               footer-class="p-0 m-0 pl-3 pr-3 " no-fade no-close-on-backdrop centered>
               <template #modal-header>
                       &nbsp;
                    </template>
               <template #default>
                    <div class="col-12 pl-1 pr-1 pt-0 pb-0 text-center">
                         <div ref="imgContainer">
                          
                         </div>
                    </div>
               </template>
               <template #modal-footer>
                    <div class="row w-100 mt-1">
                    <div class="col-12 p-2">
                         <b-button block variant="warning" @click.prevent="closeModal('modal_image');">
                         <span style="font-size:1.4em;font-weight:bold;">ปิด</span>
                         </b-button>
                    </div>
     
     
     
     
                    </div>
               </template>
               </b-modal>
               <!-- Modal Count Stock 1 -->
               <b-modal  id="modal_count_1" 
                    ref="modal_count_1"
                    modal-class="peter-modal" 
                    body-class="pt-0 pb-0" 
                    header-class="p-0 m-0 pl-1 pr-1" 
                    footer-class="p-0 m-0 pl-3 pr-3 " 
                    no-close-on-backdrop centered no-fade>
                    <template #modal-header>
                         <div class="w-100 text-truncate " style="padding-left:1px;">
                              <b-badge variant="info" class="pl-2 pr-2 mr-1 pt-1 pb-1"><span style="font-size:1.3em;margin-left:1px;">{{stocks[modalResource].STKCOD}}</span></b-badge> &nbsp;&nbsp;{{stocks[modalResource].STKDES}}
                         </div>
                    </template>
                    <template #default>
                    <div class="row pl-0 pr-0">
                         <!-- Product Infomations -->
                         <div class="col-12 p-1">
                              <div class="card" :class="stocks[modalResource].BACKGROUND_CLASS" style="border:1px solid black;">
                              <div class="card-body pt-2 pb-3 px-3 " style="position:relative">
                              <div class="row">
                                   <div class="col-12">
                                   <div class="row">
                                        <div class="col-8">
                                        <h4 class="card-title text-truncate mb-0 font-weight-bold pt-1" style="color:#2B2301">
                                        {{ stocks[modalResource].STKDES }}
                                        </h4>
                                        <h6 class="text-truncate" style="color:#888;font-size:0.9em;">
                                        {{ (stocks[modalResource].SUPNAM_SUPPLIER != null) ? stocks[modalResource].SUPNAM_SUPPLIER : "ไม่มีข้อมูล" }}
                                        </h6>
                                        </div>
                                        <div class="col-4 pl-0 pr-3 d-flex flex-column justify-content-center">
     
                                        <template v-if="!addBarcodeTrigger && (stocks[modalResource].barcode != null || stocks[modalResource].unique_code != null )">
                                        <div v-if="stocks[modalResource].barcode != null" @click="addBarcodeTrigger = true">
                                        <VueBarcode :value="stocks[modalResource].barcode" format="CODE128" background="none"></VueBarcode>
                                        </div>
                                        <div v-else-if="stocks[modalResource].barcode == null && stocks[modalResource].unique_code != null" @click="addBarcodeTrigger = true">
                                        <VueBarcode :value="stocks[modalResource].unique_code" format="CODE128" background="none" height="20"></VueBarcode>
                                        </div>
                                        </template>
     
                                        <template v-if="addBarcodeTrigger || (stocks[modalResource].barcode == null && stocks[modalResource].unique_code == null)">
                                        <div v-if="barcodeStateTrigger == null && uniqueCodeStateTrigger == null" class="p-0 text-right h3 m-0">
                                             <b-icon class="text-dark mr-1" icon="file-spreadsheet" @click.prevent="addBarcodeTrigger=true;addUniqueCode(modalResource);"></b-icon>
                                             <b-icon class="text-dark mr-0" icon="upc-scan" :class="{'mr-2' : stocks[modalResource].barcode != null || stocks[modalResource].unique_code != null}" @click="addBarcodeTrigger=true;addBarcode(modalResource)"></b-icon>
                                             <b-icon v-show="(stocks[modalResource].barcode != null || stocks[modalResource].unique_code != null)" class="text-dark" icon="x-circle-fill" @click="addBarcodeTrigger=false"></b-icon>
                                        </div>
                                        <div v-else-if="barcodeStateTrigger != null && uniqueCodeStateTrigger == null" class="p-0 text-right h2 m-0"
                                        @click.prevent="barcodeStateTrigger=null;addBarcodeTrigger=false;countScan=0" style="position:relative">
                                        <span class="p-0 m-0 pr-1 text-danger"
                                             style="position:relative;top:-6px;font-size:0.6em;line-height:1px;">
                                             {{(countScan==0) ? 'สแกน ' : 'ครั้งที่ '+countScan+'/3'}}
                                        </span>
                                        <b-icon icon="upc-scan" animation="throb" variant="danger" scale="1"></b-icon>
                                        </div>
                                        <div v-else-if="barcodeStateTrigger == null && uniqueCodeStateTrigger != null" class="p-0 text-right h2 m-0" style="position:relative">
                                        <input v-model="stocks[modalResource].unique_code" type="number" pattern="[0-9]*" inputmode="numeric" 
                                        style="width:100%;padding-top:4px;padding-bottom:4px;padding-left:4px;padding-right:4px;font-size:0.4em;text-align:left;font-weight:bold;" 
                                        @focus="$event.target.select()" @blur="save(modalResource);uniqueCodeStateTrigger=null;addBarcodeTrigger=false;" autofocus/>
                                        </div>
     
                                        </template>
                                        
     
                                        
                                        
                                        </div>
                                   </div>
     
                                   <div class="row">
                                        <div class="col-6 d-flex flex-row mb-1 p-0 pl-3">
                                        <div class="p-1  pl-2 pr-2 text-center text-light bg-danger mr-0 font-weight-bold"
                                        style="min-width:35px;border-radius: 0.25rem;font-size:1em;">
                                        <span style="position:relative;padding:0;margin:0">1 {{stocks[modalResource].PQUCOD}}</span>
                                        </div>
                                        <div class="p-1  text-center text-dark mr-0 font-weight-bold"
                                        style="border-radius: 0.25rem;font-size:1em;">
                                        X
                                        </div>
                                        <div class="p-1 pl-2 pr-2 text-center text-dark mr-0 font-weight-bold"
                                        style="background:#FFBF00; min-width:35px;border-radius: 0.25rem;font-size:1em;">
                                        {{stocks[modalResource].CFACTOR}} {{stocks[modalResource].QUCOD}}
                                        </div>
     
     
                                        </div>
                                        <div class="col-6 mb-1 d-flex flex-column justify-content-center p-0 pl-3 pr-2">
                                        <div class="pl-1 pr-0 w-100">
                                        <div class="row p-0 text-center mr-2 "
                                             style="border:1px solid black;border-radius: 0.25rem;position:relative;">
                                             <div style="padding-left:8px;position:absolute;z-index:990">ระดับสต็อก {{stocks[modalResource].PERCENT}}%
                                             </div>
                                             <div class="pl-2 text-left" style="border-radius: 0.25rem;"
                                             :class="stocks[modalResource].PERCENT_CLASS">&nbsp;</div>
                                        </div>
                                        </div>
                                        </div>
                                   </div>
                                   </div>
                                   <div class="col-12 mt-2">
                                   <div class="row">
                                        <div class="col-6 p-0 pr-1 pl-3 text-center">
                                        <div class="bg-danger p-0 pt-1 d-flex flex-column" style="border-radius: 0.25rem;">
                                        <div>หน่วยใหญ่ ({{stocks[modalResource].PQUCOD}})</div>
                                        <div class="w-100 pb-3 text-center align-self-center font-weight-bold" 
                                        :class="{ 
                                             'text-secondary' : stocks[modalResource].counted_at === null, 
                                             'text-light': (stocks[modalResource].counted_at != null && (stocks[modalResource].counted_at != new Date().toJSON().slice(0, 10))) || ((stocks[modalResource].STAT != null && stocks[modalResource].COUNT_STAT != null) && (stocks[modalResource].STAT != stocks[modalResource].COUNT_STAT)),
                                             'text-yellow': ((stocks[modalResource].counted_at == new Date().toJSON().slice(0, 10)) && (stocks[modalResource].STAT == stocks[modalResource].COUNT_STAT) && (stocks[modalResource].STAT == stocks[modalResource].COUNT_STAT)) || ((stocks[modalResource].STAT == null) && (stocks[modalResource].counted_at == new Date().toJSON().slice(0, 10)))
                                        }">
                                             <span style="font-size:3em;line-height:50px;">
                                             {{getValueFromAtom((stocks[modalResource].ATOM_LOCBAL + stocks[modalResource].express_DIFF),stocks[modalResource].CFACTOR).BIG}}
                                             </span>
                                        </div>
                                        </div>
                                        </div>
                                        <div class="col-6 p-0 pr-3 pl-1 text-center">
     
                                        <div class="p-0 pt-1 d-flex flex-column" style="border-radius: 0.25rem;background:#FFBF00;">
                                        <div>หน่วยย่อย ({{stocks[modalResource].QUCOD}})</div>
                                        <div class="w-100 pb-3 text-center align-self-center font-weight-bold " :class="{ 
                                             'text-secondary' : stocks[modalResource].counted_at === null, 
                                             'text-light' : (stocks[modalResource].counted_at != null && (stocks[modalResource].counted_at != new Date().toJSON().slice(0, 10))) || ((stocks[modalResource].STAT != null && stocks[modalResource].COUNT_STAT != null) && (stocks[modalResource].STAT != stocks[modalResource].COUNT_STAT)) , 
                                             'text-yellow' : ( (stocks[modalResource].counted_at == new Date().toJSON().slice(0, 10)) && (stocks[modalResource].STAT == stocks[modalResource].COUNT_STAT) && (stocks[modalResource].STAT == stocks[modalResource].COUNT_STAT))  || ((stocks[modalResource].STAT == null) && (stocks[modalResource].counted_at == new Date().toJSON().slice(0, 10)))
                                        }">
                                             <span style="font-size:3em;line-height:50px;">
                                                  {{getValueFromAtom((stocks[modalResource].ATOM_LOCBAL + stocks[modalResource].express_DIFF),stocks[modalResource].CFACTOR).SMALL}}
                                             </span>
     
     
                                        </div>
     
                                        </div>
                                        </div>
                                   </div>
                                   </div>
                              </div>
     
                              </div>
                              </div>
                         </div>
                         <!-- TABS -->
                         <div class="col-12 p-1">
                         <b-tabs content-class="mt-0 border-tab" nav-class="h5" active-nav-item-class="font-weight-bold " fill no-fade >
                              <!-- STOCK History -->
                              <b-tab title="สต็อก" :active="productTab == 0" @click="productTab=0">
                                   <div  style="min-height:167px;">
                                   <div class="row p-3">
                                   <template v-if="!stocks[modalResource].stocksHistoryTrigger">
                                        <div class="col-12 pl-0 pr-0 pt-4 pb-4 text-center d-flex flex-column item-align-center justify-content-center" style="min-height:140px;">
                                        <div>
                                        <!-- <img src="../assets/loading.gif" style="width:100px;"> -->
                                        กำลังโหลดข้อมูล...
                                        </div>
                                        </div>
                                   </template>
                                   <template v-if="stocks[modalResource].stocksHistoryTrigger">
                                   
     
                                   <template v-if="stocks[modalResource].history">
                                   <div class="col-12 ">
                                        <div class="row pl-1 pr-1 pb-1">
                                             <div class="col-12  pt-1 pb-1 pl-2 pr-2" style="background:#ADD8E6;border:2px solid #72BCD4">
                                                   <div class="row">
                                                       <div class="col-3 pr-0 font-weight-bold text-nowrap">&nbsp;</div>
                                                       <div class="col-3 pl-0  pr-0 font-weight-bold text-right">เอ็กเพรส</div>
                                                       <div class="col-3 pl-0  pr-2 font-weight-bold text-right">สต็อก</div>
                                                       <div class="col-3 pl-0 pr-2 font-weight-bold text-right">ส่วนต่าง</div>
                                                  </div>
                                                   <div class="row">
                                                       <div class="col-3 pl-3 pr-0 text-nowrap font-weight-bold">สต็อกล่าสุด</div>
                                                       <div class="col-3 pl-0 pr-0  text-right"> {{getValueFromAtom((stocks[modalResource].ATOM_LOCBAL),stocks[modalResource].CFACTOR).CONCAT_NO_PLUS}}</div>
                                                       <div class="col-3 pl-0  pr-2 text-right">
                                                            {{getValueFromAtom((stocks[modalResource].ATOM_LOCBAL + stocks[modalResource].express_DIFF),stocks[modalResource].CFACTOR).CONCAT_NO_PLUS}}
                                                       </div>
                                                       <div class="col-3 pl-0 pr-2 text-right">
                                                            <b-badge  :variant="((getValueFromAtom(stocks[modalResource].express_DIFF,stocks[modalResource].CFACTOR).CONCAT).includes('-')) ? 'danger' : ((getValueFromAtom(stocks[modalResource].express_DIFF,stocks[modalResource].CFACTOR).CONCAT).includes('+')) ? 'warning' : 'success'">
                                                                 <div class="w-100 text-truncate" style="font-size:1.3em;position:relative;top:0px;">
                                                                      {{getValueFromAtom(stocks[modalResource].express_DIFF,stocks[modalResource].CFACTOR).CONCAT}}
                                                                 </div>
                                                            </b-badge>
                                                       </div>
                                                  </div>
                                                  
                                             </div>
                                        </div>
                                   <div class="row pl-1 pr-1 pb-1" v-if="stocks[modalResource].history.length">
                                        <div class="col-12 pt-1 pb-1 pl-2 pr-2" style="background:#F0E2B6;border:2px solid rgb(246, 190, 0);position:relative;" :style="(Math.ceil(stocks[modalResource].history.length/maxPerPage) > 1) ? 'min-height:185px;' : '' ">
                                            
                                              <div class="row">
                                                  <div class="col-3 pr-0 font-weight-bold text-nowrap">ประวัติสต็อก</div> 
                                                  <div class="col-3 pl-0  pr-0 font-weight-bold text-right">สต็อก</div>
                                                  <div class="col-3 pl-0  pr-2 font-weight-bold text-right">นับได้</div>
                                                  <div class="col-3 pl-0 pr-2 font-weight-bold text-right">ส่วนต่าง</div>
                                             </div>
     
                                             <template v-for="(sh,index) in stocks[modalResource].history.slice().reverse().filter(e=> ((e.ship_in_ref==null && e.is_adjust) || e.is_adjust==false) && e.counted_at != new Date().toJSON().slice(0, 10))">
                                                  <template v-if="index >= start_item() && index <= end_item()">
                                                       <div class="row pl-1 pr-1"  
                                                       :class="{'font-weight-bold' : index==0 && stocks[modalResource].counted_at != new Date().toJSON().slice(0, 10) }" :key="index">
                                                       <div class="col-3 pl-2 pr-0 text-nowrap">
                                                            <!--Running Number -->
                                                            <div class="bg-success p-0 m-0 text-center text-light font-weight-bold" style="border-radius: 3em; min-width: 18px; display: inline-block;line-height:18px;">{{stocks[modalResource].history.length -index}} </div> 
                                                                 <template v-if="sh.is_adjust ">
                                                                      ปรับสต็อก
                                                                 </template>
                                                                 <template v-else>
                                                                      {{date(sh.counted_at)}} 
                                                                 </template>
                                                       
                                                       </div>
                                                       <template v-if="sh.is_adjust">
                                                            <div class="col-3 pl-0  pr-0 text-right" >
                                                                 {{getValueFromAtom(sh.COUNT_ATOM,sh._CFACTOR).CONCAT}}
                                                            </div>
                                                            <div class="col-3 pl-0 pr-2 text-right" >
                                                                 {{sh.ADJUST_USER}}
                                                            </div>
                                                            <div class="col-3 pl-0 pr-2 text-right" >
                                                                 {{date(sh.counted_at)}}
                                                            </div>
                                                       </template>
                                                       <template v-if="!sh.is_adjust">
                                                            <div class="col-3 pl-0  pr-0 text-right"  :class="{'font-weight-bold' : index==0 && stocks[modalResource].counted_at != new Date().toJSON().slice(0, 10) }">
                                                                 <!-- มีกรณีที่ peter_ATOM เป็น NULL เพราะทำ Function นี้เพิ่ม -->
                                                                 <template v-if="sh.peter_ATOM == null">
                                                                      <span style="color:#999">{{getValueFromAtom(sh.COUNT_ATOM-sh.aj_diff,sh._CFACTOR).CONCAT_NO_PLUS}}</span>
                                                                 </template>
                                                                 <!-- กรณี Versio ใหม่ปรกติ -->
                                                                 <template v-else>
                                                                      {{getValueFromAtom(sh.peter_ATOM,sh._CFACTOR).CONCAT_NO_PLUS}}
                                                                 </template>
                                                            </div>
                                                            <div class="col-3 pl-0 pr-2 text-right"  :class="{'font-weight-bold' :index==0 && stocks[modalResource].counted_at != new Date().toJSON().slice(0, 10) }">
                                                                 {{getValueFromAtom(sh.COUNT_ATOM,sh._CFACTOR).CONCAT_NO_PLUS}}
                                                            </div>
                                                            <div class="col-3 pl-0 pr-2 text-right"  :class="{'font-weight-bold' : index==0 && stocks[modalResource].counted_at != new Date().toJSON().slice(0, 10) }">
                                                                 <template v-if="sh.peter_ATOM == null">
                                                                 {{getValueFromAtom((sh.COUNT_ATOM - (sh.COUNT_ATOM-sh.aj_diff) ),sh._CFACTOR).CONCAT}}
                                                                 </template>
                                                                 <template v-else>
                                                                      {{getValueFromAtom((sh.COUNT_ATOM - sh.peter_ATOM ),sh._CFACTOR).CONCAT}}
                                                                 </template>
                                                            </div>
                                                       </template>
     
     
                                                       </div>
                                                  </template>
                                             </template>
                                             
                                             <div style="position:absolute; bottom:4px; right:8px;" v-if="Math.ceil(stocks[modalResource].history.length/maxPerPage) > 1">
                                              <div class="row d-flex justify-content-end pl-2 pr-2">
                                                   <template v-for="i in Math.ceil(stocks[modalResource].history.length/maxPerPage)">
                                                       <span v-if="i<=5" class="d-block text-center" style="width:25px;height:25px;line-height:25px;vertical-align:middle;border:1px solid rgb(246, 190, 0);margin-right:3px;" 
                                                       :class="{'bg-warning-x text-not-count' : currentPage != i, 'bg-warning font-weight-bold' : currentPage == i}"
                                                       :key="i" @click="currentPage=i">{{i}}</span>
                                                   </template>
                                             </div>
                                             </div>
     
                                        </div>
                                   </div>
     
                                   <!--วันนี้ -->
                                   <div class="row pl-1 pr-1 pb-1" v-if="is_has_today_transaction(modalResource) || is_today_count(modalResource)">
                                        <div class="col-12 pt-1 pb-1 pl-2 pr-2 bg-success-x border-success" style="border:2px solid">
                                              <div class="row ">
                                                  <div class="col-3 pr-0 font-weight-bold text-nowrap">:: วันนี้ :: </div> 
                                                  <div class="col-3 pl-0  pr-0 font-weight-bold text-right">สต็อก</div>
                                                  <div class="col-3 pl-0  pr-2 font-weight-bold text-right">นับได้</div>
                                                  <div class="col-3 pl-0 pr-2 font-weight-bold text-right">ส่วนต่าง</div>
                                             </div>
                                             <template v-for="(sh,index) in stocks[modalResource].history.filter(e=>e.counted_at == new Date().toJSON().slice(0, 10))">
                                             <div class="row pl-1 pr-1" :key="index" >
                                             <div class="col-3 pl-2 pr-0 text-nowrap">
                                                  <!--Running Number -->
                                                  <div class="bg-success p-0 m-0 text-center text-light font-weight-bold" style="border-radius: 3em; min-width: 18px; display: inline-block;line-height:18px;">{{index+1}} </div> 
                                                   <template v-if="sh.adjust_ref.length && sh.is_adjust">
                                                       <strong><span class="text-danger">&nbsp;ปรับสต็อก</span></strong>
                                                  </template> 
                                                   <template v-if="sh.ship_in_ref && sh.is_adjust">
                                                       <strong><span class="text-danger">&nbsp;ของเข้า</span></strong>
                                                  </template> 
                                                  <template v-if="!sh.is_adjust">
                                                       <span :class="{'font-weight-bold' : (sh.counted_at == stocks[modalResource].counted_at)}"> {{date(sh.counted_at)}}</span>
                                                  </template>
                                             </div>
                                             <template v-if="sh.is_adjust">
                                                  <div class="col-3 pl-0  pr-0 text-right text-danger font-weight-bold">
                                                        {{getValueFromAtom(sh.COUNT_ATOM,sh._CFACTOR).CONCAT}}
                                                  </div>
                                                   <div class="col-3 pl-0 pr-2 text-right text-danger font-weight-bold" >
                                                     {{sh.ADJUST_USER}}
                                                  </div>
                                                  <div class="col-3 pl-0 pr-2 text-right text-danger font-weight-bold" style="font-size:0.8em;line-height:25px;">
                                                     {{sh.ADJUST_DOCNUM}} 
                                                  </div>
                                             </template>
                                             <template v-if="!sh.is_adjust">
                                                  <div class="col-3 pl-0  pr-0 text-right font-weight-bold" >
                                                       <!-- มีกรณีที่ peter_ATOM เป็น NULL เพราะทำ Function นี้เพิ่ม -->
                                                       <template v-if="sh.peter_ATOM == null">
                                                           <span style="color:#999">{{getValueFromAtom(sh.COUNT_ATOM-sh.aj_diff,sh._CFACTOR).CONCAT_NO_PLUS}}</span>
                                                        </template>
                                                        <!-- กรณี Versio ใหม่ปรกติ -->
                                                        <template v-else>
                                                             {{getValueFromAtom(sh.peter_ATOM,sh._CFACTOR).CONCAT_NO_PLUS}}
                                                       </template>
                                                  </div>
                                                  <div class="col-3 pl-0 pr-2 text-right font-weight-bold"  >
                                                       {{getValueFromAtom(sh.COUNT_ATOM,sh._CFACTOR).CONCAT_NO_PLUS}}
                                                  </div>
                                                  <div class="col-3 pl-0 pr-2 text-right font-weight-bold" >
                                                        <template v-if="sh.peter_ATOM == null">
                                                       {{getValueFromAtom(sh.COUNT_ATOM - (sh.COUNT_ATOM-sh.aj_diff),sh._CFACTOR).CONCAT}}
                                                       </template>
                                                       <template v-else>
                                                       {{getValueFromAtom((sh.COUNT_ATOM - sh.peter_ATOM ),sh._CFACTOR).CONCAT}}
                                                       </template>
                                                  </div>
                                             </template>
     
     
                                             </div>
                                             </template>
                                        </div>
                                   </div>
                                   
     
                                   </div>
                                   </template>
     
                                  
     
     
                                   <!--Count Stock Details -->
                                   <div class="col-12 mt-3 noselect">
                                        <div class="d-flex align-items-center justify-content-center">
                                        <div class="row w-100">
     
                                        <div class="col-6 p-0 pr-1 pl-3 text-center"
                                             :class="{ 
                                             // ถ้าไม่มีการนับวันนี้ หรือ นับสินค้าขณะที่วันนี้มีการจัดของ และ นับไปแล้วและมีการจัดของเสร็จแล้ว
                                             'old_count_stock' : !is_today_count(modalResource) || is_count_stat_change(modalResource) || stocks[modalResource].is_overrided
                                             }"
                                        >
                                             <div class="bg-light p-0 pt-1 d-flex flex-column" style="border-radius: 0.25rem;">
                                             <div>หน่วยใหญ่ ({{stocks[modalResource].PQUCOD}})</div>
                                             <div class="w-100 pb-3 text-center align-self-center font-weight-bold text-danger">
                                                  <span style="font-size:3em;line-height:50px;" >
                                                  {{
                                                       (stocks[modalResource].history == null) ? '-' : 
                                                       (getValueFromAtom(get_last_count_stock_history(modalResource).COUNT_ATOM,stocks[modalResource].CFACTOR).BIG == null) ? '-' :  
                                                       getValueFromAtom(get_last_count_stock_history(modalResource).COUNT_ATOM,stocks[modalResource].CFACTOR).BIG
                                                  }}
                                                  </span>
                                             </div>
                                             </div>
                                        </div>
                                        <div class="col-6 p-0 pr-3 pl-1  text-center"
                                             :class="{ 
                                             // ถ้าไม่มีการนับวันนี้ หรือ นับสินค้าขณะที่วันนี้มีการจัดของ และ นับไปแล้วและมีการจัดของเสร็จแล้ว
                                             'old_count_stock' : !is_today_count(modalResource) || is_count_stat_change(modalResource) || stocks[modalResource].is_overrided
                                             }">
                                             <div class="p-0 pt-1 d-flex flex-column bg-light" style="border-radius: 0.25rem;">
                                             <div>หน่วยย่อย ({{stocks[modalResource].QUCOD}})</div>
                                             <div class="w-100 pb-3 text-center align-self-center font-weight-bold"
                                                  style="color:#FFBF00">
     
                                             <span style="font-size:3em;line-height:50px;" >
                                                  {{(stocks[modalResource].history == null) ? '-' : (getValueFromAtom(((stocks[modalResource].history[stocks[modalResource].history.length-1].COUNT_ATOM)),stocks[modalResource].CFACTOR).SMALL == null) ? '-' :  getValueFromAtom(((stocks[modalResource].history[stocks[modalResource].history.length-1].COUNT_ATOM)),stocks[modalResource].CFACTOR).SMALL}}
                                                  </span>
         
                                             </div>
                                             </div>
                                        </div>
                                        <div class="col-12 mt-2 text-center">
                                             <template v-if="stocks[modalResource].counted_at != null">
                                             <span
                                                  :class="{ 'text-success font-weight-bold' : (stocks[modalResource].counted_at == new Date().toJSON().slice(0, 10)) && (stocks[modalResource].STAT == stocks[modalResource].COUNT_STAT)}">นับสินค้าล่าสุด 
                                                  {{dayAgo(stocks[modalResource].counted_at)}} {{date(stocks[modalResource].counted_at)}}</span>
                                                  <template v-if="stocks[modalResource].counted_at == new Date().toJSON().slice(0, 10)">
                                                  <span  v-if="!delCountTrigger" class="bg-danger text-light ml-2 pl-2 pr-2" style="cursor:pointer;border-radius:0.25em" @click="delCountTrigger=true" >ลบ</span>
                                                  <span  v-if="delCountTrigger" class="bg-secondary text-light ml-2 pl-2 pr-2" style="cursor:pointer;border-radius:0.25em" @click="delCountTrigger=false" >ยกเลิก</span>
                                                  </template>
                                                  <span v-show="delCountTrigger" @click.prevent="del_count_stock(modalResource);delCountTrigger = false;" class="bg-warning text-light ml-2 pl-2 pr-2" style="cursor:pointer;border-radius:0.25em">ยืนยัน</span>
                                             </template>
                                             
                                        </div>
                                        </div>
                                        </div>
                                   </div>
                                   </template>
                                   </div>
                                   </div>
                              </b-tab>
                              <!-- PRODUCT Details -->
                              <b-tab title="ข้อมูล" :active="productTab == 1" @click="productTab=1">
                              <!--Product Destails-->
                              <div class="p-1" style="min-height:167px;">
                                   <div class="row p-2">
     
                                   <div class="col-4">กลุ่มสินค้า</div>
                                   <div class="col-4 pl-0 text-right">
                                        <template v-if="stocks[modalResource].SUPNAM_SUPPLIER != 'ไม่มีข้อมูลจากประวัติ'">
                                        <b-badge variant="secondary" class="w-100" @click="openGroupModal('modal_group_1','SUPNAM_SUPPLIER',stocks[modalResource].SUPNAM_SUPPLIER)">
                                             <div class="w-100 text-truncate" style="font-size:1.2em;position:relative;top:0px;">
                                                  {{stocks[modalResource].SUPNAM_SUPPLIER}}
                                             </div>
                                        </b-badge>
                                        </template><template v-else><span style="color:#ccc;">ไม่มีข้อมูล</span></template>
                                   </div>
                                   <div class="col-4 pl-0 text-right">
                                   
                                   <template v-if="stocks[modalResource].count_code">
                                   <b-badge variant="warning" class="w-100" @click="openGroupModal('modal_group_1','count_code',stocks[modalResource].count_code)">
                                        <span style="font-size:1.3em;position:relative;top:0px;">กลุ่ม#{{stocks[modalResource].count_code}}</span>
                                   </b-badge>
                                   </template><template v-else><span style="color:#ccc;">ยังไม่มีกลุ่ม</span></template>
     
                                   </div>
     
                                   <div class="col-4 font-weight-bold">มูลค่าสินค้า</div>
                                   <div class="col-4 pl-0 text-right">{{numberFormat(stocks[modalResource].LOCVAL)}}</div>
                                   <div class="col-4 pl-0 text-right text-success font-weight-bold">
                                        <span >MG.
                                        {{numberFormat(stocks[modalResource].SELLPR1 - processDiscount(stocks[modalResource]._UNITPR,stocks[modalResource].LPDISC))}}
                                        </span>
                                   </div>
                                   <div class="col-4 pr-0">ราคาล่าสุด 
                                   </div>
                                   <div class="col-4 pl-0 text-right text-danger font-weight-bold">
                                        <span > ซื้อ
                                        {{numberFormat(processDiscount((stocks[modalResource].LPURPR != null) ? stocks[modalResource]._UNITPR : 0,stocks[modalResource].LPDISC))}}</span>
                                   </div>
                                   <div class="col-4 pl-0 text-right text-primary font-weight-bold">
                                       <span >ขาย
                                        {{numberFormat(stocks[modalResource].SELLPR1)}} </span>
                                   </div>
                                   <div class="col-4">ปริมาณขาย</div>
                                   <div class="col-4 font-weight-bold text-right">{{stocks[modalResource].AVG_SELL}} {{stocks[modalResource].PQUCOD}}</div>
                                   <div class="col-4 text-right font-weight-bold">
                                        ต่อ{{((stocks[modalResource].order_frequency == 30) || (stocks[modalResource].order_frequency == null)) ? "เดือน" : (stocks[modalResource].order_frequency == 7) ? "สัปดาห์" : (stocks[modalResource].order_frequency == -1) ? "เดือน (ซ้อ)" : "เดือน" }}
                                   </div>
                                   <div class="col-4">ขายล่าสุด</div>
                                   <div class="col-4 text-right">
                                        <template v-if="stocks[modalResource].TRNQTY_OUT">
                                        {{getValueFromAtom(stocks[modalResource].TRNQTY_OUT,stocks[modalResource].CFACTOR).CONCAT_NO_PLUS }}
                                         {{stocks[modalResource].PQUCOD}}
                                        </template> <template v-else><span style="color:#ccc;">ไม่มีข้อมูล</span></template>
                                   </div>
                                   <div class="col-4 text-right"> 
                                        <span :style="(dayAgo(stocks[modalResource].LSK) == 'ไม่มีข้อมูล') ?'color:#ccc' : ''">{{ dayAgo(stocks[modalResource].LSK) }}</span>
                                       </div>
     
                                   <div class="col-4 font-weight-bold pr-0">ของเข้าล่าสุด</div>
                                   <div class="col-4 text-right">
                                        <template v-if="stocks[modalResource].LAST_BUY_IN_ATOM">
                                        <b-badge><span style="font-size:1.2em;position:relative;top:0px;">{{getValueFromAtom(stocks[modalResource].LAST_BUY_IN_ATOM,stocks[modalResource].CFACTOR).CONCAT_NO_PLUS }}
                                             {{stocks[modalResource].PQUCOD}}</span></b-badge>
                                        </template>
                                        <template v-else><span style="color:#ccc;">ไม่มีข้อมูล</span></template>
                                   </div>
                                   <div class="col-4 text-right"><span :style="(dayAgo(stocks[modalResource].LLK) == 'ไม่มีข้อมูล') ?'color:#ccc' : ''">{{ dayAgo(stocks[modalResource].LLK) }}</span></div>
                                  
                                   <div v-if="stocks[modalResource].makeOrderTrigger" class="col-12 p-3 mt-1 text-center" >
                                        กำลังบันทึกข้อมูล...
                                   </div>
     
                                   
                                   <!-- <div class="col-12 pl-3 pr-3 pt-1 pb-0">
                                        <div class="w-100 text-center p-2 bg-success text-light h5 font-weight-bold" style="border:1px solid #eee; border-radius:0.25em;" @click="save_make_order(modalResource)">สั่งสินค้า</div>
                                   </div> -->
                                   
                                   </div>
                              </div>
                              </b-tab>
                              <!-- PRODUCT Details -->
                              <b-tab title="ลูกค้า" :active="productTab == 3" @click="productTab=3">
                              <!--Product Destails-->
                              <div class="p-3" style="min-height:167px;">
                                         
                                        
                                         
                                   
                                    <div v-if="stocks[modalResource].AVG_SELL <= 0" class="d-flex flex-column align-item-center justify-content-center " style="min-height:167px;">
                                        <div class="text-center h-100" >ไม่มีข้อมูล</div>
                                   </div>
                                  
                                   <template v-if="stocks[modalResource].AVG_SELL > 0">
                                       
                                   <div v-if="stocks[modalResource].AVG_SELL > 0" class="w-100 text-center mb-1">
                                        <h5  class="text-center font-weight-bold mb-2 pb-0">
                                        คิดจากยอดขาย {{stocks[modalResource].AVG_SELL}} {{stocks[modalResource].PQUCOD}} <u> {{(stocks[modalResource].order_frequency==7) ? 'ต่อสัปดาห์' : 'ต่อเดือน'}}</u>
                                   </h5>
                                        <div class="row">
                                              <div v-for="(ct,cti) in stocks[modalResource].CUSTYPES" class="col" style="line-height:20px;" :key="cti">
                                                   <div class="font-weight-bold" 
                                                   :class="{'text-danger' : ct.SORT==1, 'text-primary' : ct.SORT==2, 'text-warning' : ct.SORT==3,'text-secondary' : ct.SORT==4}">{{ct.TYPES}}</div>
                                                   <div style="font-size:1.1em;">{{Math.floor((ct.ATOM/stocks[modalResource].TOTAL_ATOM)*100)}}%</div>
                                              </div>
                                        </div>
                                        <div class="bg-danger p-0 m-0 text-center text-light font-weight-bold" style="border-radius: 3em; min-width: 11px; display: inline-block;line-height:11px;">&nbsp;</div> <span style="font-size:0.9em;">ลูกค้าประจำ</span> &nbsp;
                                        <div class="bg-success p-0 m-0 text-center text-light font-weight-bold" style="border-radius: 3em; min-width: 11px; display: inline-block;line-height:11px;">&nbsp;</div> <span style="font-size:0.9em;">ลูกค้าทั่วไป</span> &nbsp;
                                        <div class="bg-warning p-0 m-0 text-center text-light font-weight-bold" style="border-radius: 3em; min-width: 11px; display: inline-block;line-height:11px;">&nbsp;</div> <span style="font-size:0.9em;">ลูกค้าขาจร </span>
                                   </div>
                                   <div v-for="(cus,i) in stocks[modalResource].CUSTOMERS" :key="i"
                                   class="d-flex justify-content-between w-100 "
                                   >
                                        <template v-if="i<11">
                                        <div class="flex-fill text-truncate" style="width:80%;">
                                             <div v-if="((i+1)<11)" class="bg-success p-0 m-0 text-center text-light font-weight-bold" style="border-radius: 3em; min-width: 18px; display: inline-block;line-height:18px;" :class="{'bg-danger' : (cus.FREQ.length>=5),'bg-warning' : (cus.FREQ.length<=2)}">{{(i+1)}}</div>
                                             <div v-else style="display: inline-block;width:18px;">&nbsp;</div>
                                             <span class="pl-1" :class="{'text-danger' : cus.TYPE==1, 'text-primary' : cus.TYPE==2,'text-warning' : cus.TYPE==3,'text-dark' : cus.TYPE==4,'text-dark' : i==10}">{{cus.CUSNAM}}</span>
                                        </div>
                                        <div class="d-flex" style="width:130px;">
                                        <div class="text-right" style="width:60px;">
                                             {{
                                                  (getValueFromAtom((cus.ATOM/180)*stocks[modalResource].order_frequency,stocks[modalResource].CFACTOR).BIG > 0) ? getValueFromAtom((cus.ATOM/180)*stocks[modalResource].order_frequency,stocks[modalResource].CFACTOR).BIG : "~1"
                                             }}
                                              {{stocks[modalResource].PQUCOD}}
                                        </div>
                                        <div class="text-right" style="width:50px;">
                                             {{(parseInt((getValueFromAtom((cus.ATOM/180)*stocks[modalResource].order_frequency,stocks[modalResource].CFACTOR).BIG)/(stocks[modalResource].AVG_SELL)*100)>0) ? parseInt((getValueFromAtom((cus.ATOM/180)*stocks[modalResource].order_frequency,stocks[modalResource].CFACTOR).BIG)/(stocks[modalResource].AVG_SELL)*100) : '~1'}}%
                                              
                                        </div>
                                        </div>
                                        </template>
                                   </div>
                                   
                                   </template>
                                </div>
                                   
                              </b-tab>
                              <!-- PRODUCT Config -->
                              <b-tab title="ตั้งค่า" :active="productTab == 2" @click="productTab=2">
                              <div class="row pl-3 pr-3" style="min-height:167px;">
                                  
                              <div class="col-6 pl-3 pt-3 pr-1 text-center" >
                                   <!-- สั่งทุกเดือน / สัปดาห์ -->
                                   <div>
                                        <b-form-radio-group 
                                             v-model="stocks[modalResource].order_frequency"
                                             v-on:change="save(modalResource)"
                                             :options="order_frequency_options" class="peter-radio" value-field="item" text-field="name" disabled-field="notEnabled" button-variant="light" buttons stacked
                                        ></b-form-radio-group>
                                    
                                        
                                   </div>
                                   <div class="text-left mt-2 w-100 p-2"
                                   :class="{'bg-warning' : stocks[modalResource].weight != null,'bg-white' : stocks[modalResource].weight == null}"
                                   style="border-radius:0.25em;border:1px solid #eee;"
                                      @click="openModalWeight(modalResource)"
                                   >
                                       <div class="row pl-3 pr-2" >
                                        <div class="col-3  text-truncate p-0 text-center" >
                                             <div style="position: absolute; top: 63%; left: 50%; transform: translate(-50%, -50%);"
                                          
                                             >
                                                  <b-icon v-if="stocks[modalResource].weight == null" icon="cpu-fill" class="h2" style="color:#DDDDDD;" ></b-icon>
                                                  <b-icon v-else icon="cpu-fill"  varian="dark" class="h2" ></b-icon>
     
                                            </div>
                                        </div>
                                        <div class="col-9 p-0 pl-2">
                                             <label for="order_frequency m-0 p-0" class="text-dark font-weight-bold" style="margin-bottom:0px;">พิกัดน้ำหนัก</label>
                                             <div class="text-dark" >
                                                  <template v-if="stocks[modalResource].weight != null">
                                                       {{numberFormat(stocks[modalResource].weight)}} 
                                                       <span class="font-weight-bold">KG</span>
                                                  </template>
                                                  <template v-else>
                                                       <span class="text-muted">ไม่มีข้อมูล</span>
                                                  </template>
                                             </div>
                                        </div>
                                       
                                       </div>
                                   </div>
                                   
     
                                   
                                   
                                    
                              </div>
                              <div class="col-6 p-2 pt-3 pb-2 text-center" >
                                   <label for="notify" class="d-flex align-items-center text-fade p-1 mr-2 m-0"  :class="{'text-dark font-weight-bold' : stocks[modalResource].notify}">
                                                            <input id="notify" v-model="stocks[modalResource].notify" :true-value="1" :false-value="0" v-on:change="save(modalResource)"
                                                            type="checkbox" class="m-1 largerCheckbox mr-2">
                                                            <b-icon  icon="bell-fill" class="h3" style="cursor:pointer;margin:0;padding:0;margin-right:8px;"></b-icon>
                                                            เตือนใกล้หมด</label>
                                   <label for="is_bsts" class="d-flex align-items-center text-fade p-1 mr-2 m-0" :class="{'text-dark font-weight-bold' : stocks[modalResource].is_bsts}">
                                                            <input id="is_bsts" v-model="stocks[modalResource].is_bsts" :true-value="1" :false-value="0" v-on:change="save(modalResource)"
                                                            type="checkbox" class="m-1 largerCheckbox mr-2" >
                                                            <b-icon  icon="house-door-fill" class="h3 "  style="cursor:pointer;margin:0;padding:0;margin-right:8px;"></b-icon>
                                                                 สินค้าไปรับเอง</label>
                                                            <label for="is_watch" class="d-flex align-items-center text-fade p-1 mr-2 m-0" :class="{'text-dark font-weight-bold' : stocks[modalResource].is_watch}">
                                                            <input id="is_watch" v-model="stocks[modalResource].is_watch" :true-value="1" :false-value="0" v-on:change="save(modalResource)"
                                                            type="checkbox" class="m-1 largerCheckbox mr-2"> 
                                                            <b-icon  icon="lightning-fill" class="h3"  style="cursor:pointer;margin:0;padding:0;margin-right:8px;"></b-icon>สินค้าซ้อ
                                                            </label>
                                                           
                                                            <label for="hide" class="d-flex align-items-center text-fade p-1 mr-2 m-0"  :class="{'text-dark font-weight-bold' : stocks[modalResource].hide}">
                                                            <input id="hide" v-model="stocks[modalResource].hide" :true-value="1" :false-value="0" v-on:change="save(modalResource)"
                                                            type="checkbox" class="m-1 largerCheckbox mr-2">
                                                            <b-icon  icon="dash-circle-fill" class="h3" style="cursor:pointer;margin:0;padding:0;margin-right:8px;"></b-icon>
                                                            ซ่อนสินค้า</label>
                                                             
     
                                                           
                              </div>
                               <div class="col-12 p-2 " style="background:#f9f9f9">
                                        <h6 class="pl-1 m-0 pl-2 font-weight-bold">ตารางนับสินค้า</h6>
                                   <!-- การนับสินค้า -->
                                   <div class="d-flex" style="height:40px;">
                                        <div style="border:1px solid #eee;border-radius:0.25em;"
                                        class="m-1 text-center flex-fill d-flex flex-column align-item-center justify-content-center" 
                                        :class="{'bg-warning font-weight-bold border-warning' : stocks[modalResource].count_day==1}"
                                        @click=" stocks[modalResource].count_day=(stocks[modalResource].count_day == 1) ? null : 1; save(modalResource)"
                                        >จ</div>
                                        <div style="border:1px solid #eee;border-radius:0.25em;"
                                        class="m-1 text-center flex-fill d-flex flex-column align-item-center justify-content-center" 
                                        :class="{'bg-warning font-weight-bold border-warning' : stocks[modalResource].count_day==2}"
                                        @click=" stocks[modalResource].count_day=(stocks[modalResource].count_day == 2) ? null : 2; save(modalResource)"
                                        >อ</div>
                                        <div style="border:1px solid #eee;border-radius:0.25em;"
                                        class="m-1 text-center flex-fill d-flex flex-column align-item-center justify-content-center" 
                                        :class="{'bg-warning font-weight-bold border-warning' : stocks[modalResource].count_day==3}"
                                        @click=" stocks[modalResource].count_day=(stocks[modalResource].count_day == 3) ? null : 3; save(modalResource)"
                                        >พ</div>
                                        <div style="border:1px solid #eee;border-radius:0.25em;"
                                        class="m-1 text-center flex-fill d-flex flex-column align-item-center justify-content-center" 
                                        :class="{'bg-warning font-weight-bold border-warning' : stocks[modalResource].count_day==4}"
                                        @click=" stocks[modalResource].count_day=(stocks[modalResource].count_day == 4) ? null : 4; save(modalResource)"
                                        >พฤ</div>
                                        <div style="border:1px solid #eee;border-radius:0.25em;"
                                        class="m-1 text-center flex-fill d-flex flex-column align-item-center justify-content-center" 
                                        :class="{'bg-warning font-weight-bold border-warning' : stocks[modalResource].count_day==5}"
                                        @click=" stocks[modalResource].count_day=(stocks[modalResource].count_day == 5) ? null : 5; save(modalResource)"
                                        >ศ</div>
                                        <div style="border:1px solid #eee;border-radius:0.25em;"
                                        class="m-1 text-center flex-fill d-flex flex-column align-item-center justify-content-center" 
                                        :class="{'bg-warning font-weight-bold border-warning' : stocks[modalResource].count_day==6}"
                                        @click=" stocks[modalResource].count_day=(stocks[modalResource].count_day == 6) ? null : 6; save(modalResource)"
                                        >ส</div>
                                       
                                        <div class="m-1 text-center flex-fill d-flex flex-column align-item-center justify-content-center" style="width:50px; position:relative;">
                                        <div class="w-100 text-center font-weight-bold" 
                                        style="position:absolute;left:-3px;top:-28px;font-size:0.9em;"
                                        @click="createSameGroup(modalResource);openModal('modal_select_group_1',modalResource)"
                                        >
                                             <b-badge variant="warning">เลือกกลุ่มสินค้า</b-badge>
                                        </div>
                                             
                                             <input class="text-center font-weight-bold pt-2" type="number" pattern="[0-9]*" inputmode="numeric" style="border:none;font-size:1.1em;" :placeholder="(stocks[modalResource].count_code==null || stocks[modalResource].count_code=='') ? 'ไม่มี': ''"
                                             v-model="stocks[modalResource].count_code" 
                                             @click="$event.target.select()"
                                             @blur="save(modalResource)"
                                             />
                                        </div>
                                   </div>
                                   </div>
                              
                                  
                              
                              </div>
                              </b-tab>
                         </b-tabs>
                         </div>
                    </div>
                    </template>
                    <!-- Modal Fotter -->
                    <template #modal-footer>
                    <div class="row w-100">
                         <!-- ปุ่มนับสต็อก -->
                         <div class="col-6 p-2">
                              <b-button block 
                              @click.prevent="barcodeStateTrigger=null;
                              uniqueCodeStateTrigger=null;
                              addBarcodeTrigger=false;
                              openModalCal(modalResource)" 
                              :variant=" !is_so_ready(modalResource) ? 'danger' : is_so_ready(modalResource) ? 'success' : 'light' " >
                                   <div style="font-size:1.4em;font-weight:bold;" :style="(stocks[modalResource].STAT!= null)?'line-height: 5px;':''">
                                   <b-icon icon="box-seam" 
                                   style="position:relative;margin:0;padding:0;top:-4px !important;padding-right:5px;"></b-icon>
                                   นับสต็อก 
                                   <div class="text-light text-center w-100 font-weight-normal" style="font-size:0.5em;">
                                        {{(stocks[modalResource].STAT != null) ? !is_so_ready(modalResource) ? "จัดสินค้าวันนี้ยังไม่เสร็จ" : "จัดสินค้าเสร็จแล้ว" : ""}}
                                   </div>
                                   </div>
                              </b-button>
                         </div>
                         <!-- ปุ่มปิด -->
                         <div class="col-6 p-2">
                              <template v-if="!count_stock_tricker">
                              <b-button block @click="barcodeStateTrigger=null;uniqueCodeStateTrigger=null;addBarcodeTrigger=false;orderTrigger=false;closeModal('modal_count_1')"
                              variant="warning">
                              <span class="font-weight-bold" style="font-size:1.4em;"> ปิด</span> </b-button>
                              </template>
                         </div>
                    </div>
                    </template>
               </b-modal>
               <!-- Modal Count Stock 2 -->
               <b-modal  id="modal_count_2" 
                    ref="modal_count_2"
                    modal-class="peter-modal" 
                    body-class="pt-0 pb-0" 
                    header-class="p-0 m-0 pl-1 pr-1" 
                    footer-class="p-0 m-0 pl-3 pr-3 " 
                    no-close-on-backdrop centered no-fade>
                    <template #modal-header>
                         <div class="w-100 text-truncate " style="padding-left:1px;">
                              <b-badge variant="info" class="pl-2 pr-2 mr-1 pt-1 pb-1"><span style="font-size:1.3em;margin-left:1px;">{{stocks[modalResource].STKCOD}}</span></b-badge> &nbsp;&nbsp;{{stocks[modalResource].STKDES}}
                         </div>
                    </template>
                    <template #default>
                    <div class="row pl-0 pr-0">
                         <!-- Product Infomations -->
                         <div class="col-12 p-1">
                              <div class="card" :class="stocks[modalResource].BACKGROUND_CLASS" style="border:1px solid black;">
                              <div class="card-body pt-2 pb-3 px-3 " style="position:relative">
                              <div class="row">
                                   <div class="col-12">
                                   <div class="row">
                                        <div class="col-8">
                                        <h4 class="card-title text-truncate mb-0 font-weight-bold pt-1" style="color:#2B2301">
                                        {{ stocks[modalResource].STKDES }}
                                        </h4>
                                        <h6 class="text-truncate" style="color:#888;font-size:0.9em;">
                                        {{ (stocks[modalResource].SUPNAM_SUPPLIER != null) ? stocks[modalResource].SUPNAM_SUPPLIER : "ไม่มีข้อมูล" }}
                                        </h6>
                                        </div>
                                        <div class="col-4 pl-0 pr-3 d-flex flex-column justify-content-center">
     
                                        <template v-if="!addBarcodeTrigger && (stocks[modalResource].barcode != null || stocks[modalResource].unique_code != null )">
                                        <div v-if="stocks[modalResource].barcode != null" @click="addBarcodeTrigger = true">
                                        <VueBarcode :value="stocks[modalResource].barcode" format="CODE128" background="none"></VueBarcode>
                                        </div>
                                        <div v-else-if="stocks[modalResource].barcode == null && stocks[modalResource].unique_code != null" @click="addBarcodeTrigger = true">
                                        <VueBarcode :value="stocks[modalResource].unique_code" format="CODE128" background="none" height="20"></VueBarcode>
                                        </div>
                                        </template>
     
                                        <template v-if="addBarcodeTrigger || (stocks[modalResource].barcode == null && stocks[modalResource].unique_code == null)">
                                        <div v-if="barcodeStateTrigger == null && uniqueCodeStateTrigger == null" class="p-0 text-right h3 m-0">
                                             <b-icon class="text-dark mr-1" icon="file-spreadsheet" @click.prevent="addBarcodeTrigger=true;addUniqueCode(modalResource);"></b-icon>
                                             <b-icon class="text-dark mr-0" icon="upc-scan" :class="{'mr-2' : stocks[modalResource].barcode != null || stocks[modalResource].unique_code != null}" @click="addBarcodeTrigger=true;addBarcode(modalResource)"></b-icon>
                                             <b-icon v-show="(stocks[modalResource].barcode != null || stocks[modalResource].unique_code != null)" class="text-dark" icon="x-circle-fill" @click="addBarcodeTrigger=false"></b-icon>
                                        </div>
                                        <div v-else-if="barcodeStateTrigger != null && uniqueCodeStateTrigger == null" class="p-0 text-right h2 m-0"
                                        @click.prevent="barcodeStateTrigger=null;addBarcodeTrigger=false;countScan=0" style="position:relative">
                                        <span class="p-0 m-0 pr-1 text-danger"
                                             style="position:relative;top:-6px;font-size:0.6em;line-height:1px;">
                                             {{(countScan==0) ? 'สแกน ' : 'ครั้งที่ '+countScan+'/3'}}
                                        </span>
                                        <b-icon icon="upc-scan" animation="throb" variant="danger" scale="1"></b-icon>
                                        </div>
                                        <div v-else-if="barcodeStateTrigger == null && uniqueCodeStateTrigger != null" class="p-0 text-right h2 m-0" style="position:relative">
                                        <input v-model="stocks[modalResource].unique_code" type="number" pattern="[0-9]*" inputmode="numeric" 
                                        style="width:100%;padding-top:4px;padding-bottom:4px;padding-left:4px;padding-right:4px;font-size:0.4em;text-align:left;font-weight:bold;" 
                                        @focus="$event.target.select()" @blur="save(modalResource);uniqueCodeStateTrigger=null;addBarcodeTrigger=false;" autofocus/>
                                        </div>
     
                                        </template>
                                        
     
                                        
                                        
                                        </div>
                                   </div>
     
                                   <div class="row">
                                        <div class="col-6 d-flex flex-row mb-1 p-0 pl-3">
                                        <div class="p-1  pl-2 pr-2 text-center text-light bg-danger mr-0 font-weight-bold"
                                        style="min-width:35px;border-radius: 0.25rem;font-size:1em;">
                                        <span style="position:relative;padding:0;margin:0">1 {{stocks[modalResource].PQUCOD}}</span>
                                        </div>
                                        <div class="p-1  text-center text-dark mr-0 font-weight-bold"
                                        style="border-radius: 0.25rem;font-size:1em;">
                                        X
                                        </div>
                                        <div class="p-1 pl-2 pr-2 text-center text-dark mr-0 font-weight-bold"
                                        style="background:#FFBF00; min-width:35px;border-radius: 0.25rem;font-size:1em;">
                                        {{stocks[modalResource].CFACTOR}} {{stocks[modalResource].QUCOD}}
                                        </div>
     
     
                                        </div>
                                        <div class="col-6 mb-1 d-flex flex-column justify-content-center p-0 pl-3 pr-2">
                                        <div class="pl-1 pr-0 w-100">
                                        <div class="row p-0 text-center mr-2 "
                                             style="border:1px solid black;border-radius: 0.25rem;position:relative;">
                                             <div style="padding-left:8px;position:absolute;z-index:990">ระดับสต็อก {{stocks[modalResource].PERCENT}}%
                                             </div>
                                             <div class="pl-2 text-left" style="border-radius: 0.25rem;"
                                             :class="stocks[modalResource].PERCENT_CLASS">&nbsp;</div>
                                        </div>
                                        </div>
                                        </div>
                                   </div>
                                   </div>
                                   <div class="col-12 mt-2">
                                   <div class="row">
                                        <div class="col-6 p-0 pr-1 pl-3 text-center">
                                        <div class="bg-danger p-0 pt-1 d-flex flex-column" style="border-radius: 0.25rem;">
                                        <div>หน่วยใหญ่ ({{stocks[modalResource].PQUCOD}})</div>
                                        <div class="w-100 pb-3 text-center align-self-center font-weight-bold" 
                                        :class="{ 
                                             'text-secondary' : stocks[modalResource].counted_at === null, 
                                             'text-light': (stocks[modalResource].counted_at != null && (stocks[modalResource].counted_at != new Date().toJSON().slice(0, 10))) || ((stocks[modalResource].STAT != null && stocks[modalResource].COUNT_STAT != null) && (stocks[modalResource].STAT != stocks[modalResource].COUNT_STAT)),
                                             'text-yellow': ((stocks[modalResource].counted_at == new Date().toJSON().slice(0, 10)) && (stocks[modalResource].STAT == stocks[modalResource].COUNT_STAT) && (stocks[modalResource].STAT == stocks[modalResource].COUNT_STAT)) || ((stocks[modalResource].STAT == null) && (stocks[modalResource].counted_at == new Date().toJSON().slice(0, 10)))
                                        }">
                                             <span style="font-size:3em;line-height:50px;">
                                             {{getValueFromAtom((stocks[modalResource].ATOM_LOCBAL + stocks[modalResource].express_DIFF),stocks[modalResource].CFACTOR).BIG}}
                                             </span>
                                        </div>
                                        </div>
                                        </div>
                                        <div class="col-6 p-0 pr-3 pl-1 text-center">
     
                                        <div class="p-0 pt-1 d-flex flex-column" style="border-radius: 0.25rem;background:#FFBF00;">
                                        <div>หน่วยย่อย ({{stocks[modalResource].QUCOD}})</div>
                                        <div class="w-100 pb-3 text-center align-self-center font-weight-bold " :class="{ 
                                             'text-secondary' : stocks[modalResource].counted_at === null, 
                                             'text-light' : (stocks[modalResource].counted_at != null && (stocks[modalResource].counted_at != new Date().toJSON().slice(0, 10))) || ((stocks[modalResource].STAT != null && stocks[modalResource].COUNT_STAT != null) && (stocks[modalResource].STAT != stocks[modalResource].COUNT_STAT)) , 
                                             'text-yellow' : ( (stocks[modalResource].counted_at == new Date().toJSON().slice(0, 10)) && (stocks[modalResource].STAT == stocks[modalResource].COUNT_STAT) && (stocks[modalResource].STAT == stocks[modalResource].COUNT_STAT))  || ((stocks[modalResource].STAT == null) && (stocks[modalResource].counted_at == new Date().toJSON().slice(0, 10)))
                                        }">
                                             <span style="font-size:3em;line-height:50px;">
                                                  {{getValueFromAtom((stocks[modalResource].ATOM_LOCBAL + stocks[modalResource].express_DIFF),stocks[modalResource].CFACTOR).SMALL}}
                                             </span>
     
     
                                        </div>
     
                                        </div>
                                        </div>
                                   </div>
                                   </div>
                              </div>
     
                              </div>
                              </div>
                         </div>
                         <!-- TABS -->
                         <div class="col-12 p-1">
                         <b-tabs content-class="mt-0 border-tab" nav-class="h5" active-nav-item-class="font-weight-bold " fill no-fade >
                              <!-- STOCK History -->
                              <b-tab title="สต็อก" :active="productTab == 0" @click="productTab=0">
                                   <div  style="min-height:167px;">
                                   <div class="row p-3">
                                   <template v-if="!stocks[modalResource].stocksHistoryTrigger">
                                        <div class="col-12 pl-0 pr-0 pt-4 pb-4 text-center d-flex flex-column item-align-center justify-content-center" style="min-height:140px;">
                                        <div>
                                        <!-- <img src="../assets/loading.gif" style="width:100px;"> -->
                                        กำลังโหลดข้อมูล...
                                        </div>
                                        </div>
                                   </template>
                                   <template v-if="stocks[modalResource].stocksHistoryTrigger">
                                   
     
                                   <template v-if="stocks[modalResource].history">
                                   <div class="col-12 ">
                                        <div class="row pl-1 pr-1 pb-1">
                                             <div class="col-12 p-2" style="background:#ADD8E6;border:2px solid #72BCD4">
                                                   <div class="row">
                                                       <div class="col-3 pr-0 font-weight-bold text-nowrap"></div>
                                                       <div class="col-3 pl-0  pr-0 font-weight-bold text-right">สต็อก</div>
                                                       <div class="col-3 pl-0  pr-2 font-weight-bold text-right">เอ็กเพรส</div>
                                                       <div class="col-3 pl-0 pr-2 font-weight-bold text-right">ส่วนต่าง</div>
                                                  </div>
                                                   <div class="row">
                                                       <div class="col-3 pl-3 pr-0 text-nowrap font-weight-bold">สต็อกล่าสุด</div>
                                                       <div class="col-3 pl-0 pr-0  text-right">{{getValueFromAtom((stocks[modalResource].ATOM_LOCBAL + stocks[modalResource].express_DIFF),stocks[modalResource].CFACTOR).CONCAT_NO_PLUS}}</div>
                                                       <div class="col-3 pl-0  pr-2 text-right">
                                                             {{getValueFromAtom((stocks[modalResource].ATOM_LOCBAL),stocks[modalResource].CFACTOR).CONCAT_NO_PLUS}}
                                                       </div>
                                                       <div class="col-3 pl-0 pr-2 text-right">
                                                            <b-badge  :variant="((getValueFromAtom(stocks[modalResource].express_DIFF,stocks[modalResource].CFACTOR).CONCAT).includes('-')) ? 'danger' : ((getValueFromAtom(stocks[modalResource].express_DIFF,stocks[modalResource].CFACTOR).CONCAT).includes('+')) ? 'warning' : 'success'">
                                                                 <div class="w-100 text-truncate" style="font-size:1.3em;position:relative;top:0px;">
                                                                      {{getValueFromAtom(stocks[modalResource].express_DIFF,stocks[modalResource].CFACTOR).CONCAT}}
                                                                 </div>
                                                            </b-badge>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   <div class="row pl-1 pr-1 pb-1">
                                        <div class="col-12 p-2" style="background:#F0E2B6;border:2px solid rgb(246, 190, 0)">
                                              <div class="row">
                                                  <div class="col-3 pr-0 font-weight-bold text-nowrap">ประวัติสต็อก</div> 
                                                  <div class="col-3 pl-0  pr-0 font-weight-bold text-right">สต็อก</div>
                                                  <div class="col-3 pl-0  pr-2 font-weight-bold text-right">นับได้</div>
                                                  <div class="col-3 pl-0 pr-2 font-weight-bold text-right">ส่วนต่าง</div>
                                             </div>
                                             <template v-for="(sh,index) in stocks[modalResource].history">
                                             <div class="row" v-if="sh.counted_at != new Date().toJSON().slice(0, 10)" :class="{'font-weight-bold' : (sh.counted_at == stocks[modalResource].counted_at)}" :key="index">
                                             <div class="col-3 pl-2 pr-0 text-nowrap">
                                                  <!--Running Number -->
                                                  <div class="bg-success p-0 m-0 text-center text-light font-weight-bold" style="border-radius: 3em; min-width: 18px; display: inline-block;line-height:18px;">{{index+1}} </div> 
                                                       <template v-if="sh.is_adjust">
                                                            ปรับสต็อก
                                                       </template>
                                                       <template v-else>
                                                            {{date(sh.counted_at)}} 
                                                       </template>
                                                 
                                             </div>
                                             <template v-if="sh.is_adjust">
                                                  <div class="col-3 pl-0  pr-0 text-right" >
                                                        {{getValueFromAtom(sh.COUNT_ATOM,sh._CFACTOR).CONCAT}}
                                                  </div>
                                                   <div class="col-3 pl-0 pr-2 text-right" >
                                                       {{sh.ADJUST_USER}}
                                                  </div>
                                                   <div class="col-3 pl-0 pr-2 text-right" >
                                                       {{date(sh.counted_at)}}
                                                  </div>
                                             </template>
                                             <template v-if="!sh.is_adjust">
                                                  <div class="col-3 pl-0  pr-0 text-right"  :class="{'font-weight-bold' : index == 0 }">
                                                       <!-- มีกรณีที่ peter_ATOM เป็น NULL เพราะทำ Function นี้เพิ่ม -->
                                                        <template v-if="sh.peter_ATOM == null">
                                                            <span style="color:#999">{{getValueFromAtom(sh.COUNT_ATOM-sh.aj_diff,sh._CFACTOR).CONCAT_NO_PLUS}}</span>
                                                        </template>
                                                        <!-- กรณี Versio ใหม่ปรกติ -->
                                                        <template v-else>
                                                             {{getValueFromAtom(sh.peter_ATOM,sh._CFACTOR).CONCAT_NO_PLUS}}
                                                       </template>
                                                  </div>
                                                  <div class="col-3 pl-0 pr-2 text-right"  :class="{'font-weight-bold' : index == 0 }">
                                                       {{getValueFromAtom(sh.COUNT_ATOM,sh._CFACTOR).CONCAT_NO_PLUS}}
                                                  </div>
                                                  <div class="col-3 pl-0 pr-2 text-right"  :class="{'font-weight-bold' : index == 0 }">
                                                       <template v-if="sh.peter_ATOM == null">
                                                        {{getValueFromAtom((sh.COUNT_ATOM - (sh.COUNT_ATOM-sh.aj_diff) ),sh._CFACTOR).CONCAT}}
                                                         </template>
                                                         <template v-else>
                                                              {{getValueFromAtom((sh.COUNT_ATOM - sh.peter_ATOM ),sh._CFACTOR).CONCAT}}
                                                         </template>
                                                  </div>
                                             </template>
     
     
                                             </div>
                                             </template>
                                        </div>
                                   </div>
     
                                   <!--วันนี้ -->
                                   <div class="row pl-1 pr-1 pb-1" v-if="is_has_today_transaction(modalResource) || is_today_count(modalResource)">
                                        <div class="col-12 p-2 bg-success-x border-success" style="border:2px solid">
                                              <div class="row">
                                                  <div class="col-3 pr-0 font-weight-bold text-nowrap">:: วันนี้ :: </div> 
                                                  <div class="col-3 pl-0  pr-0 font-weight-bold text-right">สต็อก</div>
                                                  <div class="col-3 pl-0  pr-2 font-weight-bold text-right">นับได้</div>
                                                  <div class="col-3 pl-0 pr-2 font-weight-bold text-right">ส่วนต่าง</div>
                                             </div>
                                             <template v-for="(sh,index) in stocks[modalResource].history">
                                             <div class="row" v-if="sh.counted_at == new Date().toJSON().slice(0, 10)" :key="index">
                                             <div class="col-3 pl-2 pr-0 text-nowrap">
                                                  <!--Running Number -->
                                                  <div class="bg-success p-0 m-0 text-center text-light font-weight-bold" style="border-radius: 3em; min-width: 18px; display: inline-block;line-height:18px;">{{index+1}} </div> 
                                                   <template v-if="sh.adjust_ref.length && sh.is_adjust">
                                                       <strong><span class="text-danger">&nbsp; ปรับสต็อก</span></strong>
                                                  </template> 
                                                   <template v-if="sh.ship_in_ref && sh.is_adjust">
                                                       <strong><span class="text-danger">&nbsp; ของเข้า</span></strong>
                                                  </template> 
                                                  <template v-if="!sh.is_adjust"> {{date(sh.counted_at)}}
                                                  </template>
                                             </div>
                                             <template v-if="sh.is_adjust">
                                                  <div class="col-3 pl-0  pr-0 text-right text-danger font-weight-bold"  :class="{'font-weight-bold' : index == 0 }">
                                                        {{getValueFromAtom(sh.COUNT_ATOM,sh._CFACTOR).CONCAT}}
                                                  </div>
                                                   <div class="col-3 pl-0 pr-2 text-right text-danger font-weight-bold" >
                                                     {{sh.ADJUST_USER}}
                                                  </div>
                                                  <div class="col-3 pl-0 pr-2 text-right text-danger font-weight-bold" style="font-size:0.8em;line-height:25px;">
                                                     {{sh.ADJUST_DOCNUM}} 
                                                  </div>
                                             </template>
                                             <template v-if="!sh.is_adjust">
                                                  <div class="col-3 pl-0  pr-0 text-right"  :class="{'font-weight-bold' : index == 0 }">
                                                       <!-- มีกรณีที่ peter_ATOM เป็น NULL เพราะทำ Function นี้เพิ่ม -->
                                                       <template v-if="sh.peter_ATOM == null">
                                                           <span style="color:#999">{{getValueFromAtom(sh.COUNT_ATOM-sh.aj_diff,sh._CFACTOR).CONCAT_NO_PLUS}}</span>
                                                        </template>
                                                        <!-- กรณี Versio ใหม่ปรกติ -->
                                                        <template v-else>
                                                             {{getValueFromAtom(sh.peter_ATOM,sh._CFACTOR).CONCAT_NO_PLUS}}
                                                       </template>
                                                  </div>
                                                  <div class="col-3 pl-0 pr-2 text-right"  :class="{'font-weight-bold' : index == 0 }">
                                                       {{getValueFromAtom(sh.COUNT_ATOM,sh._CFACTOR).CONCAT_NO_PLUS}}
                                                  </div>
                                                  <div class="col-3 pl-0 pr-2 text-right"  :class="{'font-weight-bold' : index == 0 }">
                                                       <template v-if="sh.peter_ATOM == null">
                                                       {{getValueFromAtom(sh.COUNT_ATOM - (sh.COUNT_ATOM-sh.aj_diff),sh._CFACTOR).CONCAT}}
                                                       </template>
                                                       <template v-else>
                                                       {{getValueFromAtom((sh.COUNT_ATOM - sh.peter_ATOM ),sh._CFACTOR).CONCAT}}
                                                       </template>
                                                  </div>
                                             </template>
     
     
                                             </div>
                                             </template>
                                        </div>
                                   </div>
                                   
     
                                   </div>
                                   </template>
     
                                  
     
     
                                   <!--Count Stock Details -->
                                   <div class="col-12 mt-3 noselect">
                                        <div class="d-flex align-items-center justify-content-center">
                                        <div class="row w-100">
     
                                        <div class="col-6 p-0 pr-1 pl-3 text-center"
                                             :class="{ 
                                             // ถ้าไม่มีการนับวันนี้ หรือ นับสินค้าขณะที่วันนี้มีการจัดของ และ นับไปแล้วและมีการจัดของเสร็จแล้ว
                                             'old_count_stock' : !is_today_count(modalResource) || is_count_stat_change(modalResource) || stocks[modalResource].is_overrided
                                             }"
                                        >
                                             <div class="bg-light p-0 pt-1 d-flex flex-column" style="border-radius: 0.25rem;">
                                             <div>หน่วยใหญ่ ({{stocks[modalResource].PQUCOD}})</div>
                                             <div class="w-100 pb-3 text-center align-self-center font-weight-bold text-danger">
                                                  <span style="font-size:3em;line-height:50px;" >
                                                  {{
                                                       (stocks[modalResource].history == null) ? '-' : 
                                                       (getValueFromAtom(get_last_count_stock_history(modalResource).COUNT_ATOM,stocks[modalResource].CFACTOR).BIG == null) ? '-' :  
                                                       getValueFromAtom(get_last_count_stock_history(modalResource).COUNT_ATOM,stocks[modalResource].CFACTOR).BIG
                                                  }}
                                                  </span>
                                             </div>
                                             </div>
                                        </div>
                                        <div class="col-6 p-0 pr-3 pl-1  text-center"
                                             :class="{ 
                                             // ถ้าไม่มีการนับวันนี้ หรือ นับสินค้าขณะที่วันนี้มีการจัดของ และ นับไปแล้วและมีการจัดของเสร็จแล้ว
                                             'old_count_stock' : !is_today_count(modalResource) || is_count_stat_change(modalResource) || stocks[modalResource].is_overrided
                                             }">
                                             <div class="p-0 pt-1 d-flex flex-column bg-light" style="border-radius: 0.25rem;">
                                             <div>หน่วยย่อย ({{stocks[modalResource].QUCOD}})</div>
                                             <div class="w-100 pb-3 text-center align-self-center font-weight-bold"
                                                  style="color:#FFBF00">
     
                                             <span style="font-size:3em;line-height:50px;" >
                                                  {{(stocks[modalResource].history == null) ? '-' : (getValueFromAtom(((stocks[modalResource].history[stocks[modalResource].history.length-1].COUNT_ATOM)),stocks[modalResource].CFACTOR).SMALL == null) ? '-' :  getValueFromAtom(((stocks[modalResource].history[stocks[modalResource].history.length-1].COUNT_ATOM)),stocks[modalResource].CFACTOR).SMALL}}
                                                  </span>
         
                                             </div>
                                             </div>
                                        </div>
                                        <div class="col-12 mt-2 text-center">
                                             <template v-if="stocks[modalResource].counted_at != null">
                                             <span
                                                  :class="{ 'text-success font-weight-bold' : (stocks[modalResource].counted_at == new Date().toJSON().slice(0, 10)) && (stocks[modalResource].STAT == stocks[modalResource].COUNT_STAT)}">นับสินค้าล่าสุด 
                                                  {{dayAgo(stocks[modalResource].counted_at)}} {{date(stocks[modalResource].counted_at)}}</span>
                                                  <template v-if="stocks[modalResource].counted_at == new Date().toJSON().slice(0, 10)">
                                                  <span  v-if="!delCountTrigger" class="bg-danger text-light ml-2 pl-2 pr-2" style="cursor:pointer;border-radius:0.25em" @click="delCountTrigger=true" >ลบ</span>
                                                  <span  v-if="delCountTrigger" class="bg-secondary text-light ml-2 pl-2 pr-2" style="cursor:pointer;border-radius:0.25em" @click="delCountTrigger=false" >ยกเลิก</span>
                                                  </template>
                                                  <span v-show="delCountTrigger" @click.prevent="del_count_stock(modalResource);delCountTrigger = false;" class="bg-warning text-light ml-2 pl-2 pr-2" style="cursor:pointer;border-radius:0.25em">ยืนยัน</span>
                                             </template>
                                        </div>
                                        </div>
                                        </div>
                                   </div>
                                   </template>
                                   </div>
                                   </div>
                              </b-tab>
                              <!-- PRODUCT Details -->
                              <b-tab title="ข้อมูล" :active="productTab == 1" @click="productTab=1">
                              <!--Product Destails-->
                              <div class="p-1" style="min-height:167px;">
                                   <div class="row p-2">
     
                                   <div class="col-4">กลุ่มสินค้า</div>
                                   <div class="col-4 pl-0 text-right">
                                        <template v-if="stocks[modalResource].SUPNAM_SUPPLIER != 'ไม่มีข้อมูลจากประวัติ'">
                                        <b-badge variant="secondary" class="w-100" @click="openGroupModal('modal_group_1','SUPNAM_SUPPLIER',stocks[modalResource].SUPNAM_SUPPLIER)">
                                             <div class="w-100 text-truncate" style="font-size:1.2em;position:relative;top:0px;">
                                                  {{stocks[modalResource].SUPNAM_SUPPLIER}}
                                             </div>
                                        </b-badge>
                                        </template><template v-else><span style="color:#ccc;">ไม่มีข้อมูล</span></template>
                                   </div>
                                   <div class="col-4 pl-0 text-right">
                                   
                                   <template v-if="stocks[modalResource].count_code">
                                   <b-badge variant="warning" class="w-100" @click="openGroupModal('modal_group_1','count_code',stocks[modalResource].count_code)">
                                        <span style="font-size:1.3em;position:relative;top:0px;">กลุ่ม#{{stocks[modalResource].count_code}}</span>
                                   </b-badge>
                                   </template><template v-else><span style="color:#ccc;">ยังไม่มีกลุ่ม</span></template>
     
                                   </div>
     
                                   <div class="col-4 font-weight-bold">มูลค่าสินค้า</div>
                                   <div class="col-4 pl-0 text-right">{{numberFormat(stocks[modalResource].LOCVAL)}}</div>
                                   <div class="col-4 pl-0 text-right text-success font-weight-bold">
                                        <span >MG.
                                        {{numberFormat(stocks[modalResource].SELLPR1 - processDiscount(stocks[modalResource].LPURPR,stocks[modalResource].LPDISC))}}
                                        </span>
                                   </div>
                                   <div class="col-4 pr-0">ราคาล่าสุด 
                                        <span v-if="stocks[modalResource].LPDISC " class="position-absolute translate-middle badge rounded-pill bg-danger text-light" style="font-size:0.5em;right:0;top:6px;padding-top:2px;padding-bototm:2px;">
                                            {{stocks[modalResource].LPDISC}}{{(stocks[modalResource].LPDISC.includes("%")) ? '' : '%'}}
                                        </span>
                                   </div>
                                   <div class="col-4 pl-0 text-right text-danger font-weight-bold">
                                        <span > ซื้อ
                                        {{numberFormat(processDiscount((stocks[modalResource].LPURPR != null) ? stocks[modalResource].LPURPR : 0,stocks[modalResource].LPDISC))}}</span>
                                   </div>
                                   <div class="col-4 pl-0 text-right text-primary font-weight-bold">
                                       <span >ขาย
                                        {{numberFormat(stocks[modalResource].SELLPR1)}} </span>
                                   </div>
                                   <div class="col-4">ปริมาณขาย</div>
                                   <div class="col-4 font-weight-bold text-right">{{stocks[modalResource].AVG_SELL}} {{stocks[modalResource].PQUCOD}}</div>
                                   <div class="col-4 text-right font-weight-bold">
                                        ต่อ{{((stocks[modalResource].order_frequency == 30) || (stocks[modalResource].order_frequency == null)) ? "เดือน" : (stocks[modalResource].order_frequency == 7) ? "สัปดาห์" : (stocks[modalResource].order_frequency == -1) ? "เดือน (ซ้อ)" : "เดือน" }}
                                   </div>
                                   <div class="col-4">ขายล่าสุด</div>
                                   <div class="col-4 text-right">
                                        <template v-if="stocks[modalResource].TRNQTY_OUT">
                                        {{stocks[modalResource].TRNQTY_OUT}} {{stocks[modalResource].PQUCOD}}
                                        </template> <template v-else><span style="color:#ccc;">ไม่มีข้อมูล</span></template>
                                   </div>
                                   <div class="col-4 text-right"> 
                                        <span :style="(dayAgo(stocks[modalResource].LSK) == 'ไม่มีข้อมูล') ?'color:#ccc' : ''">{{ dayAgo(stocks[modalResource].LSK) }}</span>
                                       </div>
     
                                   <div class="col-4 font-weight-bold pr-0">ของเข้าล่าสุด</div>
                                   <div class="col-4 text-right">
                                        <template v-if="stocks[modalResource].TRNQTY_IN">
                                        <b-badge @click="(stocks[modalResource].ordered_at == null) ? stocks[modalResource].make_order=stocks[modalResource].TRNQTY_IN : ''"><span style="font-size:1.2em;position:relative;top:0px;">{{stocks[modalResource].TRNQTY_IN}}
                                             {{stocks[modalResource].PQUCOD}}</span></b-badge>
                                        </template>
                                        <template v-else><span style="color:#ccc;">ไม่มีข้อมูล</span></template>
                                   </div>
                                   <div class="col-4 text-right"><span :style="(dayAgo(stocks[modalResource].LLK) == 'ไม่มีข้อมูล') ?'color:#ccc' : ''">{{ dayAgo(stocks[modalResource].LLK) }}</span></div>
                                  
                                   <div v-if="stocks[modalResource].makeOrderTrigger" class="col-12 p-3 mt-1 text-center" >
                                        กำลังบันทึกข้อมูล...
                                   </div>
                                   <div v-if="!stocks[modalResource].makeOrderTrigger" class="col-12 pl-3 pr-3  mt-3" >
                                        <div class="row">
                                              
                                             <div class="col-6 p-0 pr-3 pl-4 text-center">
                                                  <div class="p-0 pt-1 w-100" 
                                                       style="height:80px;position:relative;border:1px solid #ddd;border-radius: 0.25rem;"
                                                       :class="{' bg-success-x' : ((stocks[modalResource].ordered_at != null) && (stocks[modalResource].ordered_at == new Date().toJSON().slice(0, 10)) && !stocks[modalResource].is_po), 'bg-gray' : stocks[modalResource].is_po}">
                                                       <div style="position:absolute;left:0;right:0;">
                                                            #ออเดอร์ <strong>({{stocks[modalResource].PQUCOD}})</strong>
                                                       </div>
                                                       <div class="w-100 pb-0 text-center align-self-center font-weight-bold">
                                                            <!-- Input สำหรับสั่งออเดอร์ -->
                                                            <b-form-input placeholder="0" type="number" pattern="[0-9]*" inputmode="numeric"
                                                            style="background:transparent;height:100%;width:100%;padding:0;margin-top:12px;font-size:2.5em;text-align:center;font-weight:bold;border:none !important;"
                                                            v-if="(stocks[modalResource].ordered_at == null || (stocks[modalResource].ordered_at != new Date().toJSON().slice(0, 10))) && !stocks[modalResource].is_po" 
                                                            v-model="stocks[modalResource].make_order" 
                                                            @focus="$event.target.select()" ></b-form-input>
     
                                                            <!-- กรณีสั่งออเดอร์แล้วหรือมี PO -->
                                                            <div class="text-success font-weight-bold"
                                                            style="background:transparent;height:100%;width:100%;padding:0;margin-top:12px;font-size:2.5em;text-align:center;font-weight:bold;border:none !important;"
                                                            v-if="(stocks[modalResource].ordered_at != null && (stocks[modalResource].ordered_at == new Date().toJSON().slice(0, 10))) || stocks[modalResource].is_po">
                                                                 {{(stocks[modalResource].is_po) ? getValueFromAtom(stocks[modalResource].PO_ORDQTY,stocks[modalResource].CFACTOR).BIG : stocks[modalResource].make_order}}
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
     
                                             <div class="col-6 pr-4 pl-1 d-flex flex-column align-item-center justify-content-center text-center">
                                             
                                             <template v-if="!stocks[modalResource].is_po">
                                                  <div class="p-0 m-0 h4 font-weight-bold h-100 d-flex flex-column align-item-center justify-content-center" style="border-radius:0.25em;" 
                                                  :class="{'bg-success text-light' : stocks[modalResource].make_order != null && stocks[modalResource].make_order != 0, 'bg-light text-fade' : stocks[modalResource].make_order == null || stocks[modalResource].make_order == 0}" 
                                                  v-if="stocks[modalResource].ordered_at == null || (stocks[modalResource].ordered_at != new Date().toJSON().slice(0, 10))"  
                                                  @click="(stocks[modalResource].make_order != null && stocks[modalResource].make_order != 0) ? save_make_order(modalResource) : ''">
                                                       <span>สั่งสินค้า </span>
                                                  </div>
                                             </template>
                                              <template v-if="stocks[modalResource].is_po">
                                                    <div class="p-0 bg-light m-0 h4 h-100 d-flex flex-column align-item-center justify-content-center text-secondary" style="border-radius:0.25em;">
                                                       <span v-for="(po,i) in stocks[modalResource].PONUM" class="pt-1 text-left pl-4 pr-0" style="font-size:0.7em;line-height:13px;" :key="i">
                                                           
                                                            <strong>{{po.PONUM}} </strong><br/><span style="font-size:0.7em;">{{ dayAgo(po.PODAT) }} [{{ getValueFromAtom(po.QTY,stocks[modalResource].CFACTOR).BIG }} {{stocks[modalResource].PQUCOD}}]</span>
                                                       </span>
                                                       
                                                  </div>
                                             </template>
     
                                         <div v-if="stocks[modalResource].ordered_at != null && (stocks[modalResource].ordered_at == new Date().toJSON().slice(0, 10))" class="p-0 m-0 h4  bg-danger text-light font-weight-bold h-100 d-flex flex-column align-item-center justify-content-center" style="border-radius:0.25em;border:1px solid #ddd;" 
                                         @click="del_make_order(modalResource)">
                                              <span>
                                              <b-icon icon="trash" style="position:relative;margin:0;padding:0;padding-right:5px;"> </b-icon>ยกเลิก </span>
                                         </div>
                                             </div>
                                       
                                   </div>
                                   </div>
                                   <!-- <div class="col-12 pl-3 pr-3 pt-1 pb-0">
                                        <div class="w-100 text-center p-2 bg-success text-light h5 font-weight-bold" style="border:1px solid #eee; border-radius:0.25em;" @click="save_make_order(modalResource)">สั่งสินค้า</div>
                                   </div> -->
                                   
                                   </div>
                              </div>
                              </b-tab>
                              <!-- PRODUCT Details -->
                              <b-tab title="ลูกค้า" :active="productTab == 3" @click="productTab=3">
                              <!--Product Destails-->
                              <div class="p-3" style="min-height:167px;">
                                         
                                        
                                         
                                   
                                    <div v-if="stocks[modalResource].AVG_SELL <= 0" class="d-flex flex-column align-item-center justify-content-center " style="min-height:167px;">
                                        <div class="text-center h-100" >ไม่มีข้อมูล</div>
                                   </div>
                                  
                                   <template v-if="stocks[modalResource].AVG_SELL > 0">
                                       
                                   <div v-if="stocks[modalResource].AVG_SELL > 0" class="w-100 text-center mb-1">
                                        <h5  class="text-center font-weight-bold mb-2 pb-0">
                                        คิดจากยอดขาย {{stocks[modalResource].AVG_SELL}} {{stocks[modalResource].PQUCOD}} <u> {{(stocks[modalResource].order_frequency==7) ? 'ต่อสัปดาห์' : 'ต่อเดือน'}}</u>
                                   </h5>
                                        <div class="row">
                                              <div v-for="(ct,cti) in stocks[modalResource].CUSTYPES" class="col" style="line-height:20px;" :key="cti">
                                                   <div class="font-weight-bold" 
                                                   :class="{'text-danger' : ct.SORT==1, 'text-primary' : ct.SORT==2, 'text-warning' : ct.SORT==3,'text-secondary' : ct.SORT==4}">{{ct.TYPES}}</div>
                                                   <div style="font-size:1.1em;">{{Math.floor((ct.ATOM/stocks[modalResource].TOTAL_ATOM)*100)}}%</div>
                                              </div>
                                        </div>
                                        <div class="bg-danger p-0 m-0 text-center text-light font-weight-bold" style="border-radius: 3em; min-width: 11px; display: inline-block;line-height:11px;">&nbsp;</div> <span style="font-size:0.9em;">ลูกค้าประจำ</span> &nbsp;
                                        <div class="bg-success p-0 m-0 text-center text-light font-weight-bold" style="border-radius: 3em; min-width: 11px; display: inline-block;line-height:11px;">&nbsp;</div> <span style="font-size:0.9em;">ลูกค้า��ั่วไป</span> &nbsp;
                                        <div class="bg-warning p-0 m-0 text-center text-light font-weight-bold" style="border-radius: 3em; min-width: 11px; display: inline-block;line-height:11px;">&nbsp;</div> <span style="font-size:0.9em;">ลูกค้าขาจร </span>
                                   </div>
                                   <div v-for="(cus,i) in stocks[modalResource].CUSTOMERS" :key="i"
                                   class="d-flex justify-content-between w-100 "
                                   >
                                        <template v-if="i<11">
                                        <div class="flex-fill text-truncate" style="width:80%;">
                                             <div v-if="((i+1)<11)" class="bg-success p-0 m-0 text-center text-light font-weight-bold" style="border-radius: 3em; min-width: 18px; display: inline-block;line-height:18px;" :class="{'bg-danger' : (cus.FREQ.length>=5),'bg-warning' : (cus.FREQ.length<=2)}">{{(i+1)}}</div>
                                             <div v-else style="display: inline-block;width:18px;">&nbsp;</div>
                                             <span class="pl-1" :class="{'text-danger' : cus.TYPE==1, 'text-primary' : cus.TYPE==2,'text-warning' : cus.TYPE==3,'text-dark' : cus.TYPE==4,'text-dark' : i==10}">{{cus.CUSNAM}}</span>
                                        </div>
                                        <div class="d-flex" style="width:130px;">
                                        <div class="text-right" style="width:60px;">
                                             {{
                                                  (getValueFromAtom((cus.ATOM/180)*stocks[modalResource].order_frequency,stocks[modalResource].CFACTOR).BIG > 0) ? getValueFromAtom((cus.ATOM/180)*stocks[modalResource].order_frequency,stocks[modalResource].CFACTOR).BIG : "~1"
                                             }}
                                              {{stocks[modalResource].PQUCOD}}
                                        </div>
                                        <div class="text-right" style="width:50px;">
                                             {{(parseInt((getValueFromAtom((cus.ATOM/180)*stocks[modalResource].order_frequency,stocks[modalResource].CFACTOR).BIG)/(stocks[modalResource].AVG_SELL)*100)>0) ? parseInt((getValueFromAtom((cus.ATOM/180)*stocks[modalResource].order_frequency,stocks[modalResource].CFACTOR).BIG)/(stocks[modalResource].AVG_SELL)*100) : '~1'}}%
                                              
                                        </div>
                                        </div>
                                        </template>
                                   </div>
                                   
                                   </template>
                                </div>
                                   
                              </b-tab>
                              <!-- PRODUCT Config -->
                              <b-tab title="ตั้งค่า" :active="productTab == 2" @click="productTab=2">
                              <div class="row pl-3 pr-3" style="min-height:167px;">
                                  
                              <div class="col-6 pl-3 pt-3 pr-1 text-center" >
                                   <!-- สั่งทุกเดือน / สัปดาห์ -->
                                   <div>
                                        <b-form-radio-group 
                                             v-model="stocks[modalResource].order_frequency"
                                             v-on:change="save(modalResource)"
                                             :options="order_frequency_options" class="peter-radio" value-field="item" text-field="name" disabled-field="notEnabled" button-variant="light" buttons stacked
                                        ></b-form-radio-group>
                                   </div>
                                   
                                    
                              </div>
                              <div class="col-6 p-2 pt-3 pb-2 text-center" >
                                   <label for="notify" class="d-flex align-items-center text-fade p-1 mr-2 m-0"  :class="{'text-dark font-weight-bold' : stocks[modalResource].notify}">
                                                            <input id="notify" v-model="stocks[modalResource].notify" :true-value="1" :false-value="0" v-on:change="save(modalResource)"
                                                            type="checkbox" class="m-1 largerCheckbox mr-2">
                                                            <b-icon  icon="bell-fill" class="h3" style="cursor:pointer;margin:0;padding:0;margin-right:8px;"></b-icon>
                                                            เตือนใกล้หมด</label>
                                   <label for="is_bsts" class="d-flex align-items-center text-fade p-1 mr-2 m-0" :class="{'text-dark font-weight-bold' : stocks[modalResource].is_bsts}">
                                                            <input id="is_bsts" v-model="stocks[modalResource].is_bsts" :true-value="1" :false-value="0" v-on:change="save(modalResource)"
                                                            type="checkbox" class="m-1 largerCheckbox mr-2" >
                                                            <b-icon  icon="house-door-fill" class="h3 "  style="cursor:pointer;margin:0;padding:0;margin-right:8px;"></b-icon>
                                                                 สินค้าไปรับเอง</label>
                                                            <label for="is_watch" class="d-flex align-items-center text-fade p-1 mr-2 m-0" :class="{'text-dark font-weight-bold' : stocks[modalResource].is_watch}">
                                                            <input id="is_watch" v-model="stocks[modalResource].is_watch" :true-value="1" :false-value="0" v-on:change="save(modalResource)"
                                                            type="checkbox" class="m-1 largerCheckbox mr-2"> 
                                                            <b-icon  icon="lightning-fill" class="h3"  style="cursor:pointer;margin:0;padding:0;margin-right:8px;"></b-icon>สินค้าซ้อ
                                                            </label>
                                                           
                                                            <label for="hide" class="d-flex align-items-center text-fade p-1 mr-2 m-0"  :class="{'text-dark font-weight-bold' : stocks[modalResource].hide}">
                                                            <input id="hide" v-model="stocks[modalResource].hide" :true-value="1" :false-value="0" v-on:change="save(modalResource)"
                                                            type="checkbox" class="m-1 largerCheckbox mr-2">
                                                            <b-icon  icon="dash-circle-fill" class="h3" style="cursor:pointer;margin:0;padding:0;margin-right:8px;"></b-icon>
                                                            ซ่อนสินค้า</label>
                                                             
     
                                                           
                              </div>
                               <div class="col-12 p-2 " style="background:#f9f9f9">
                                        <h6 class="pl-1 m-0 pl-2 font-weight-bold">ตารางนับสินค้า</h6>
                                   <!-- การนับสินค้า -->
                                   <div class="d-flex" style="height:40px;">
                                        <div style="border:1px solid #eee;border-radius:0.25em;"
                                        class="m-1 text-center flex-fill d-flex flex-column align-item-center justify-content-center" 
                                        :class="{'bg-warning font-weight-bold border-warning' : stocks[modalResource].count_day==1}"
                                        @click=" stocks[modalResource].count_day=(stocks[modalResource].count_day == 1) ? null : 1; save(modalResource)"
                                        >จ</div>
                                        <div style="border:1px solid #eee;border-radius:0.25em;"
                                        class="m-1 text-center flex-fill d-flex flex-column align-item-center justify-content-center" 
                                        :class="{'bg-warning font-weight-bold border-warning' : stocks[modalResource].count_day==2}"
                                        @click=" stocks[modalResource].count_day=(stocks[modalResource].count_day == 2) ? null : 2; save(modalResource)"
                                        >อ</div>
                                        <div style="border:1px solid #eee;border-radius:0.25em;"
                                        class="m-1 text-center flex-fill d-flex flex-column align-item-center justify-content-center" 
                                        :class="{'bg-warning font-weight-bold border-warning' : stocks[modalResource].count_day==3}"
                                        @click=" stocks[modalResource].count_day=(stocks[modalResource].count_day == 3) ? null : 3; save(modalResource)"
                                        >พ</div>
                                        <div style="border:1px solid #eee;border-radius:0.25em;"
                                        class="m-1 text-center flex-fill d-flex flex-column align-item-center justify-content-center" 
                                        :class="{'bg-warning font-weight-bold border-warning' : stocks[modalResource].count_day==4}"
                                        @click=" stocks[modalResource].count_day=(stocks[modalResource].count_day == 4) ? null : 4; save(modalResource)"
                                        >พฤ</div>
                                        <div style="border:1px solid #eee;border-radius:0.25em;"
                                        class="m-1 text-center flex-fill d-flex flex-column align-item-center justify-content-center" 
                                        :class="{'bg-warning font-weight-bold border-warning' : stocks[modalResource].count_day==5}"
                                        @click=" stocks[modalResource].count_day=(stocks[modalResource].count_day == 5) ? null : 5; save(modalResource)"
                                        >ศ</div>
                                        <div style="border:1px solid #eee;border-radius:0.25em;"
                                        class="m-1 text-center flex-fill d-flex flex-column align-item-center justify-content-center" 
                                        :class="{'bg-warning font-weight-bold border-warning' : stocks[modalResource].count_day==6}"
                                        @click=" stocks[modalResource].count_day=(stocks[modalResource].count_day == 6) ? null : 6; save(modalResource)"
                                        >ส</div>
                                       
                                        <div class="m-1 text-center flex-fill d-flex flex-column align-item-center justify-content-center" style="width:50px; position:relative;">
                                        <div class="w-100 text-center font-weight-bold" 
                                        style="position:absolute;left:-3px;top:-28px;font-size:0.9em;"
                                        @click="createSameGroup(modalResource);openModal('modal_select_group_1',modalResource)"
                                        >
                                             <b-badge variant="warning">เลือกกลุ่มสินค้า</b-badge>
                                        </div>
                                             
                                             <input class="text-center font-weight-bold pt-2" type="number" pattern="[0-9]*" inputmode="numeric" style="border:none;font-size:1.1em;" :placeholder="(stocks[modalResource].count_code==null || stocks[modalResource].count_code=='') ? 'ไม่มี': ''"
                                             v-model="stocks[modalResource].count_code" 
                                             @click="$event.target.select()"
                                             @blur="save(modalResource)"
                                             />
                                        </div>
                                   </div>
                                   </div>
                              
                                  
                              
                              </div>
                              </b-tab>
                         </b-tabs>
                         </div>
                    </div>
                    </template>
                    <!-- Modal Fotter -->
                    <template #modal-footer>
                    <div class="row w-100">
                         <!-- ปุ่มนับสต็อก -->
                         <div class="col-6 p-2">
                              <b-button block 
                              @click.prevent="barcodeStateTrigger=null;
                              uniqueCodeStateTrigger=null;
                              addBarcodeTrigger=false;
                              openModalCal(modalResource)" 
                              :variant=" !is_so_ready(modalResource) ? 'danger' : is_so_ready(modalResource) ? 'success' : 'light' " >
                                   <div style="font-size:1.4em;font-weight:bold;" :style="(stocks[modalResource].STAT!= null)?'line-height: 5px;':''">
                                   <b-icon icon="box-seam" 
                                   style="position:relative;margin:0;padding:0;top:-4px !important;padding-right:5px;"></b-icon>
                                   นับสต็อก 
                                   <div class="text-light text-center w-100 font-weight-normal" style="font-size:0.5em;">
                                        {{(stocks[modalResource].STAT != null) ? !is_so_ready(modalResource) ? "จัดสินค้าวันนี้ยังไม่เสร็จ" : "จัดสินค้าเสร็จแล้ว" : ""}}
                                   </div>
                                   </div>
                              </b-button>
                         </div>
                         <!-- ปุ่มปิด -->
                         <div class="col-6 p-2">
                              <template v-if="!count_stock_tricker">
                              <b-button block @click="barcodeStateTrigger=null;uniqueCodeStateTrigger=null;addBarcodeTrigger=false;orderTrigger=false;closeModal('modal_count_2')"
                              variant="warning">
                              <span class="font-weight-bold" style="font-size:1.4em;"> ปิด</span> </b-button>
                              </template>
                         </div>
                    </div>
                    </template>
               </b-modal>
               <!-- Modal Group Stock -->
               <b-modal  id="modal_group_1" 
                    ref="modal_group_1"
                    modal-class="peter-modal" 
                    body-class="pt-0 pb-0" 
                    header-class="p-0 m-0 pl-1 pr-1" 
                    footer-class="p-0 m-0 pl-3 pr-3 " 
                    no-close-on-backdrop centered no-fade>
                    
                    <template #modal-header>
                         &nbsp;
                    </template>
                    <template #default>
                    <div class="row d-flex flex-column align-item-center justify-content-center" ref="imgContainer2"></div>
                    <div v-show="imageDisplay" class="row">
                         <div class="col-12 pt-2"  ref="domImg2">
                              <div class="w-100 text-truncate text-center" style="padding-left:1px;">
                                   <img class="d-inline-block mr-2" src="../assets/slogo.png" style="position:relative;height:30px;"> 
                                   <span class="font-weight-bold"  style="font-size:1.2em;">นับสต็อกสินค้าวันที่ {{todayDate()}}</span>
                                   <h6 >กลุ่มสินค้า #{{groupFilter}}</h6>
                              </div>
                              <div class="row pl-0 pr-0">
                                   <!-- Product Infomations -->
                                   <div class="col-12 p-2 " >
                                        <div class="w-100 d-flex justify-content-between p-1" v-for="(item,i) in groupStocks" :key="i" 
                                             style="border:1px solid #ddd;border-bottom:0px solid #ddd;" :style="(i==groupStocks.length-1) ? 'border-bottom:1px solid #ddd;' : '' " :class="item.BACKGROUND_CLASS"
                                             @click="openModalFind('modal_count_2',item.STKCOD)"
                                             >
                                        <div class=" text-truncate pl-1" style="width:80%;"><span style="font-size:1.1em;" :class="{'font-weight-bold' : item.STKCOD == stocks[modalResource].STKCOD}">
                                             <b-icon v-show="item.counted_at == new Date().toJSON().slice(0, 10)" icon="check-circle-fill" style="color:green;margin-right:3px;"></b-icon> 
                                             {{item.STKDES}}</span></div>
                                        <div class="font-weight-bold text-center d-flex flex-column align-item-center justify-content-center" style="width:60px;">
                                             <span class="h5 font-weight-bold m-0 p-0" :class="{'h6' : item.count_expired}">
                                                  {{(!item.count_expired) ? getValueFromAtom((item.ATOM_LOCBAL + item.express_DIFF),item.CFACTOR).BIG : 'ยังไม่นับ'}}
                                             </span>
                                        </div>
                                   </div>
                                   </div>
                                   
                              </div>
                         </div>
                    </div>
                    </template>
                    <!-- Modal Fotter -->
                    <template #modal-footer>
                    <div class="row w-100">
                         <!-- ปุ่มนับสต็อก -->
                         <div class="col-6 p-2">
                             <b-button v-if="imageDisplay" block @click.prevent="makeImg2()"
                              variant="secondary">
                              <span class="font-weight-bold" style="font-size:1.4em;"><b-icon icon="file-image-fill" style="position:relative;color:#ddd;margin:0;padding:0;top:-3px;"></b-icon> สร้างรูป</span> </b-button>
                         </div>
                         <!-- ปุ่มปิด -->
                         <div class="col-6 p-2">
                              <template v-if="!count_stock_tricker">
                              <b-button block @click.prevent="imageDisplay=false;closeModal('modal_group_1');"
                              variant="warning">
                              <span class="font-weight-bold" style="font-size:1.4em;"> ปิด</span> </b-button>
                              </template>
                         </div>
                    </div>
                    </template>
                    
               </b-modal>
                <!-- Modal Select Group  -->
               <b-modal  id="modal_select_group_1" 
                    ref="modal_select_group_1"
                    modal-class="peter-modal" 
                    body-class="pt-0 pb-0" 
                    header-class="p-0 m-0 pl-1 pr-1" 
                    footer-class="p-0 m-0 pl-3 pr-3 " 
                    no-close-on-backdrop centered no-fade>
                    
                    <template #modal-header>
                         <div class="w-100 text-center h5 font-weight-bold">ตัวช่วยเลือกกลุ่มสินค้า</div>
                    </template>
                    <template #default>
                    <div class="row">
                         <div class="col-12 pt-2 text-left" >
                               <div class="w-100 text-truncate " style="padding-left:1px;">
                              <b-badge variant="info" class="pl-2 pr-2 pt-1 pb-1"><span style="font-size:1.3em;margin-left:1px;">{{stocks[modalResource].STKCOD}}</span></b-badge> &nbsp;&nbsp;{{stocks[modalResource].STKDES}}
                         </div>
                              <div class="row pl-0 pr-0 mt-2">
                                   <!-- Product Infomations -->
                                   <div v-if="stocks[modalResource].count_code" class="col-12 pl-3 pr-3 px-0  d-flex justify-content-between">
                                       <div>กลุ่มสินค้าเดิม</div>
                                       <div @click="stocks[modalResource].count_code = stocks[modalResource].count_code; save(modalResource);closeModal('modal_select_group_1')">
                                             <b-badge variant="primary" class="pl-2 pr-2 mr-1 pt-1 pb-1" style="width:60px;"><span style="font-size:1.5em;margin-left:1px;">{{stocks[modalResource].count_code}}</span></b-badge>
                                       </div>
                                   </div>
                                    <div class="col-12 pl-3 pr-3 px-0  mt-1 d-flex justify-content-between">
                                       <div>กลุ่มสินค้าที่ว่างอยู่</div>
                                       <div @click="stocks[modalResource].count_code = suggestGroup;save(modalResource);closeModal('modal_select_group_1')">
                                             <b-badge variant="warning" class="pl-2 pr-2 mr-1 pt-1 pb-1" style="width:60px;"><span style="font-size:1.5em;margin-left:1px;">{{suggestGroup}}</span></b-badge>
                                       </div>
                                   </div>
                                   <div class="col-12 pl-3 pr-3 px-0 mt-2 text-left text-truncate">
                                        <strong>{{stocks[modalResource].SUPNAM_SUPPLIER}}</strong></div>
                                   <div  v-for="(item,i) in sameGroup" :key="i" class="col-12 pl-3 pr-3 px-0  d-flex justify-content-between" 
                                   :class="{'mt-0' : i == 0,'mt-1' : i!=0}">
                                        <div class="d-fill text-truncate">{{item.STKDES}}</div>
                                       <div @click="stocks[modalResource].count_code = item.count_code;save(modalResource);closeModal('modal_select_group_1')">
                                             <b-badge variant="secondary" class="pl-2 pr-2 mr-1 pt-1 pb-1" style="width:60px;"><span style="font-size:1.5em;margin-left:1px;">{{item.count_code}}</span></b-badge>
                                       </div>
                                   </div>
                                   <div v-if="sameGroup.length==0" class="col-12 pl-3 pr-3 px-0">ไม่เคยมีกลุ่มสินค้าของบริษัทฯนี้</div>
                                  
                                  
                                   
                              </div>
                         </div>
                    </div>
                    </template>
                    <!-- Modal Fotter -->
                    <template #modal-footer>
                    <div class="row w-100">
                         <!-- ปุ่มนับสต็อก -->
                         <div class="col-6 p-2">
                            &nbsp;
                         </div>
                         <!-- ปุ่มปิด -->
                         <div class="col-6 p-2">
                              <template v-if="!count_stock_tricker">
                              <b-button block @click.prevent="closeModal('modal_select_group_1');imageDisplay=false;"
                              variant="warning">
                              <span class="font-weight-bold" style="font-size:1.4em;"> ปิด</span> </b-button>
                              </template>
                         </div>
                    </div>
                    </template>
                    
               </b-modal>
               <!-- Modal Calculator -->
               <b-modal id="modal_multi_1" modal-class="peter-modal" body-class="p-0 pt-0 pb-0 mt-0"
               header-class="p-0 m-0 pl-1 pr-1" footer-class="p-0 m-0 pl-3 pr-3 " no-fade no-close-on-backdrop>
               
               <template #modal-header>
                    <div class="row">
                    <div class="col-12">
                         <div class="text-truncate">
                         <b-badge variant="info" class="p-2"><span style="font-size:1.3em;">{{stocks[modalResource].STKCOD}}</span>
                         </b-badge> {{stocks[modalResource].STKDES}}
                         </div>
                    </div>
                    <div class="col-12 mt-1">
                    <div class="pl-3 pr-3 pt-0  ">
                         <div class="row pl-2 pr-2 pt-1 pb-1 mb-1 text-dark"
                         style="border-radius: 0.25rem;border:2px solid #ccc;position:relative;background:#ddd;">
                         
                         <div class="col-6 text-center d-flex flex-column p-1">
                         <div class="h6 m-0 p-0 mb-1 font-weight-bold" @click="legEditTrigger=true">เรียงขาละ</div>
                         <b-form-input v-model="stocks[modalResource].leg" type="number" pattern="[0-9]*" inputmode="numeric"
                              style="height:51px;width:100%;padding-left:0;padding-right:0;font-size:1.9em;text-align:center;font-weight:bold;"
                              @focus="$event.target.select()" v-if="legEditTrigger" v-click-outside="resetLegEdit"></b-form-input>
                         <div v-if="!legEditTrigger" class="d-none d-flex flex-column align-item-center justify-content-center bg-info text-light" style="height:50px;width:100%;font-size:2em;text-align:center;font-weight:bold;border-radius:0.2em;" @click="addPal(stocks[modalResource].leg)">
                              {{(stocks[modalResource].leg) ? stocks[modalResource].leg : "-"}}
                         </div>
                         </div>
                         
                         <div class="col-3 text-center d-flex flex-column p-1" @click="addSmallPal()">
                         <div class="h6 m-0 p-0 mb-1 font-weight-bold">เพิ่มกอง</div>
                         <div class="d-flex flex-column align-item-center justify-content-center " style="background:#ddd;border:1px solid #888;height:50px;width:100%;font-size:2em;text-align:center;font-weight:bold;border-radius:0.2em;">
                              +
                         </div>
                         </div>
                         <div class="col-3 text-center d-flex flex-column p-1" @click="addPal()">
                         <div class="h6 m-0 p-0 mb-1 font-weight-bold">เพิ่มชุบนับ</div>
                         <div class="d-flex flex-column align-item-center justify-content-center bg-success" style="border:1px solid green;height:50px;width:100%;font-size:2em;text-align:center;font-weight:bold;border-radius:0.2em;">
                              +
                         </div>
                         </div>
                    </div>
                    </div>
                    </div>
                    </div>
               </template>
               <template #default>
                    
                    <div class="col-12 pl-1 pr-1 pt-0 pb-0">
                    <div class="pl-3 pr-3 " >
                         <div v-for="(cal,index) in calculator.cal.length" class="row pl-2 pr-2 pb-1 pt-1 bg-light mb-1"
                         style="border-radius: 0.25rem;border:1px solid #ddd;position:relative;" :key="index" >
                         <div class="row pl-3 pr-3 " >
                         <div v-if="(calculator.cal[index].leg * calculator.cal[index].floor) != 0"
                         class="d-flex flex-column justify-content-center align-item-center text-center font-weight-bold"
                         style="position:absolute;font-size:0.9em;right:0px;bottom:0px;padding-left:8px;padding-right:8px;min-width:90px;height:16px;background:#ddd;color:white;">
                         <span>เลทละ {{calculator.cal[index].leg * calculator.cal[index].floor}}</span>
                         </div>
                         <div class="col-3 text-center d-flex flex-column p-1">
                         <div class="h6 m-0 p-0 font-weight-bold">เรียงขาละ</div>
                         <b-form-input v-model="calculator.cal[index].leg" type="number" pattern="[0-9]*" inputmode="numeric"
                              style="height:60px;width:100%;padding-left:0;padding-right:0;font-size:2em;text-align:center;font-weight:bold;"
                              @focus="$event.target.select()"></b-form-input>
                         </div>
                         <div class="col-3 text-center d-flex flex-column p-1">
                         <div class="h6 m-0 p-0 font-weight-bold">จำนวนชั้น</div>
                         <b-form-input v-model="calculator.cal[index].floor" type="number" pattern="[0-9]*" inputmode="numeric"
                              style="height:60px;width:100%;padding-left:0;padding-right:0;font-size:2em;text-align:center;font-weight:bold;"
                              @focus="$event.target.select()"></b-form-input>
                         </div>
                         <div class="col-3 text-center d-flex flex-column p-1">
                         <div class="h6 m-0 p-0 font-weight-bold">#พาเลท</div>
                         <b-form-input v-model="calculator.cal[index].pal" type="number" pattern="[0-9]*" inputmode="numeric"
                              style="height:60px;width:100%;padding-left:0;padding-right:0;font-size:2em;text-align:center;font-weight:bold;"
                              @focus="$event.target.select()"></b-form-input>
                         </div>
                         <div class="col-3 text-center d-flex flex-column p-1" >
                         <div class="h6 m-0 p-0 font-weight-bold">ชุดนับที่ {{index+1}}</div>
                         <div v-if="delPalTrigger == index && calculator.cal.length > 1" class="p-0 bg-danger text-light" style="border-radius: 0.25rem;height:60px;width:100%;padding-left:0;padding-right:0;line-height:60px;vertical-align:middle;font-size:2em;text-align:center;font-weight:bold;" @click="delPal(index)" v-click-outside="resetDelPal">ลบ?</div>
                         <div v-if="delPalTrigger != index || calculator.cal.length == 1" class="p-0" style="border-radius: 0.25rem;height:60px;width:100%;padding-left:0;padding-right:0;line-height:60px;vertical-align:middle;font-size:2em;text-align:center;font-weight:bold;"  @click="delPalTrigger = index"  >
                              {{calculator.cal[index].leg * calculator.cal[index].floor * calculator.cal[index].pal}}
                         </div>
                         </div>
                         </div>
                         </div>
                    </div>
                    </div>
     
                    <div class="col-12 pl-1 pr-1 pt-0 pb-0">
                    <div class="pl-3 pr-3 pt-0  " v-click-outside="resetDelSmallPal">
                         <div  class="row pl-2 pr-2 pb-1 pt-1 bg-light mb-1"
                         style="border-radius: 0.25rem;border:1px solid #ddd;position:relative;" >
     
                         <div v-for="(cal,index) in calculator.cal_.length" class="col-3 text-center d-flex flex-column p-1" :key="index">
                         <div class="h6 m-0 p-0 font-weight-bold" @click="delSmallPalTrigger = index">กองที่ {{index+1}}</div>
                         <div v-if="delSmallPalTrigger == index && calculator.cal_.length > 1" class="bg-danger text-light d-flex flex-column align-item-center justify-content-center" style="border-radius: 0.25rem;height:60px;width:100%;padding-left:0;padding-right:0;font-size:2em;text-align:center;font-weight:bold;"
                              @click="delSmallPal(index)">ลบ?</div>
                         <b-form-input   v-if="delSmallPalTrigger != index || calculator.cal_.length == 1" v-model="calculator.cal_[index].big" type="number" pattern="[0-9]*" inputmode="numeric"
                              style="height:60px;width:100%;padding-left:0;padding-right:0;font-size:2em;text-align:center;font-weight:bold;"
                              @focus="$event.target.select()"></b-form-input>
                         </div>
                         <div class="col-3 text-center d-flex flex-column p-1">
                         <div class="h6 m-0 p-0 font-weight-bold text-warning">หน่วยย่อย</div>
                         <b-form-input  v-model="calculator.cal_[0].small" class="text-warning" type="number" pattern="[0-9]*" inputmode="numeric"
                              style="height:60px;width:100%;padding-left:0;padding-right:0;font-size:2em;text-align:center;font-weight:bold;"
                              @focus="$event.target.select()"></b-form-input>
                         </div>
     
     
                    </div>
                    </div>
                    </div>
     
                    <div v-if="(stocks[modalResource].STAT == 'N')" class="col-12 pl-1 pr-1 pt-0 pb-0">
                    <!-- <h6 class="pl-2 m-0 p-0  font-weight-bold">จำนวนจัดสินค้าวันนี้</h6> -->
                    <div class="pl-3 pr-3 pt-0  " >
                         
                         <div  class="row pl-2 pr-2 pb-1 pt-1"
                         style="background:#F0E2B6;border-radius: 0.25rem;border:2px solid #F6BE00;position:relative;" >
                         <div class="col-6 d-flex flex-column p-1" >
                         <div class="h6 m-0 p-0 font-weight-bold"><u>รายการจัดสินค้าวันนี้</u></div>
                         <div class="d-flex flex-column align-item-center justify-content-center pt-1" 
                         style="border-radius:0.25em;height:40px;width:100%;">
                         
                                   <div class="d-flex align-item-center" style="height:40px;" >
     
                                   <input type="checkbox" id="tdj" v-model="calculator.CHECK_STAT" :true-value="1" :false-value="0" 
                                   class="peter-check-box m-0" 
                                   style="height:35px;width:35px;">
                                   
                                   
                                   <label for="tdj" class="pl-2 m-0" style="line-height:35px;"
                                   >เพิ่มจำนวนนี้ ?</label>
                                   </div>
                         </div>
                         </div>
                         
                         
                         <div class="col-3 text-center d-flex flex-column p-1 " >
                         <div class="h6 m-0 p-0">หน่วยใหญ่</div>
                         <div class="bg-warning d-flex flex-column align-item-center justify-content-center" 
                         style="border-radius:0.25em;height:40px;width:100%;padding-left:0;padding-right:0;font-size:2em;text-align:center;font-weight:bold;">
                              {{getValueFromAtom(stocks[modalResource].ATOM_ORDQTY,stocks[modalResource].CFACTOR).BIG}}
                         </div>
                         </div>
                         <div class="col-3 text-center d-flex flex-column p-1" >
                         <div class="h6 m-0 p-0">#ลูกค้า</div>
                         <div class="bg-danger d-flex flex-column align-item-center justify-content-center text-light" 
                         style="border-radius:0.25em;height:40px;width:100%;padding-left:0;padding-right:0;font-size:2em;text-align:center;font-weight:bold;">
                              {{stocks[modalResource].SOCOUNT}} 
                         </div>
                         </div>
     
                         <div v-show="!soCusDetail" class="col-12 p-1 text-right font-weight-bold" @click="soCusDetail=true;">ดูรายละเอียด</div>
                         <div class="col-12 p-1 pr-2" v-show="soCusDetail">
                              <div class="d-flex justify-content-between w-100 m-0 p-0"  
                              v-for="(c,ci) in stocks[modalResource].SOCUS" :key="ci" style="width:100%;">
                              <div> 
                                   {{ci+1}}. {{c.NAME}} 
                              </div>
                              <div>{{getValueFromAtom(c.ATOM_QTY,stocks[modalResource].CFACTOR).BIG}} {{stocks[modalResource].PQUCOD}}</div>
                              </div>
                         </div>
                         <div v-show="soCusDetail" class="col-12 p-1 text-right font-weight-bold" @click="soCusDetail=false;">ซ่อนรายละเอียด</div>
     
                         </div>
                    </div>
                    </div>
                    
                    <div class="col-12 p-2 noselect">
                    <div class="p-2 d-flex align-items-center justify-content-center ">
                         <div class="row w-100">
                         <div class="col-6 p-0 pr-1 pl-3 text-center">
                         <div class="bg-light p-0 pt-1 d-flex flex-column" style="border-radius: 0.25rem;">
                              <div>หน่วยใหญ่ ({{stocks[modalResource].PQUCOD}})</div>
                              <div class="w-100 pb-3 text-center align-self-center font-weight-bold text-danger">
                              <span style="font-size:3em;line-height:50px;">
                                   {{ 
                                   calculator.cal.reduce(function(result,item) { return result + parseInt(item.leg * item.floor * item.pal) }, 0) +
                                   calculator.cal_.reduce(function(result,item) { return result + parseInt(item.big ) }, 0)
                                   + parseInt(_calPlus(modalResource).BIG)
                                   }}
                              </span>
                              </div>
                         </div>
                         </div>
                         <div class="col-6 p-0 pr-3 pl-1 text-center">
                         <div class="p-0 pt-1 d-flex flex-column bg-light" style="border-radius: 0.25rem;">
                              <div>หน่วยย่อย ({{stocks[modalResource].QUCOD}})</div>
                              <div class="w-100 pb-3 text-center align-self-center font-weight-bold" style="color:#FFBF00">
                              <span style="font-size:3em;line-height:50px;">
                                   {{(parseInt(calculator.cal_[0].small) + _calPlus(modalResource).SMALL)}}
                                   </span>
                              </div>
                         </div>
                         </div>
                         </div>
                    </div>
                    </div>
     
               </template>
               <template #modal-footer>
                    <div class="row w-100 mt-1">
                    <div class="col-6 pl-2 pr-2 pb-0">
                         <b-button block variant="light" @click="calReset()">
                         <div style="font-size:1.4em;font-weight:bold;">
                         <b-icon icon="trash" style="position:relative;margin:0;padding:0;top:-4px !important;padding-right:5px;">
                         </b-icon>
                         ล้างค่า
                         </div>
                         </b-button>
                    </div>
                    <div class="col-6 pl-2 pr-2 pb-0">
                         <b-button block variant="success" 
                         @click.prevent="save_count_stock(modalResource);">
                         <div style="font-size:1.4em;font-weight:bold;">
                         <b-icon icon="check-circle"
                              style="position:relative;margin:0;padding:0;top:-4px !important;padding-right:5px;">
                         </b-icon>
                         ยืนยัน
                         </div>
                         </b-button>
                    </div>
                    <div class="col-12 p-2">
                         <b-button block variant="warning" @click.prevent="calReset();closeModal('modal_multi_1');">
                         <span style="font-size:1.4em;font-weight:bold;">ปิด</span>
                         </b-button>
                    </div>
     
     
     
     
                    </div>
               </template>
               </b-modal>
                <!-- Modal Calculator -->
                <b-modal id="modal_weight" modal-class="peter-modal" body-class="p-0 pt-0 pb-0 mt-0"
               header-class="p-0 m-0 pl-1 pr-1" footer-class="p-0 m-0 pl-3 pr-3 "  no-close-on-backdrop centered no-fade>
               
               <template #modal-header>
                    <div class="row pl-2 pr-2">
                    <div class="col-12">
                         <div class="text-truncate">
                         <b-badge variant="info" class="p-2"><span style="font-size:1.3em;">{{stocks[modalResource].STKCOD}}</span>
                         </b-badge> {{stocks[modalResource].STKDES}}
                         </div>
                    </div>
                    
                   
                    </div>
               </template>
               <template #default>
                    <div class="row pl-2 pr-2">
                        
                         <div class="col-12 mt-1 ">
                    <div class="pl-3 pr-3 pt-0  ">
                         <div class="row pl-1 pr-1 pt-1 pb-1 mb-1 text-dark bg-ddd"
                              style="border-radius: 0.25rem; border: 2px solid #ccc; position: relative;">
                         <div class="col-12 d-flex flex-column p-1 justify-content-center align-items-center">
                              
                              <div class="row w-100 d-flex align-items-center">
                                   <div class="col-6">
                                        <h5 class="m-0">ชั้งครั้งที่ 1 </h5>
                                   </div>
                                   <div class="col-6 text-right">
                                        <input v-model="stocks[modalResource].weight_1" type="number" pattern="[0-9]*" inputmode="numeric" class="form-control w-100 text-right" 
                                        style="font-size:1.1em;font-weight:bold;"
                                        @focus="$event.target.select()" 
                                        />
                                   </div>
                              </div>
     
                              <div class="row w-100 d-flex align-items-center mt-2">
                                   <div class="col-6">
                                        <h5 class="m-0">ชั้งครั้งที่ 2 </h5>
                                   </div>
                                   <div class="col-6 text-right">
                                        <input v-model="stocks[modalResource].weight_2" type="number" pattern="[0-9]*" inputmode="numeric" class="form-control w-100 text-right" 
                                        style="font-size:1.1em;font-weight:bold;"
                                        @focus="$event.target.select()" 
                                        />
                                   </div>
                              </div>
     
                              <div class="row w-100 d-flex align-items-center mt-2">
                                   <div class="col-6">
                                        <h5 class="m-0">ชั้งครั้งที่ 3 </h5>
                                   </div>
                                   <div class="col-6 text-right">
                                        <input v-model="stocks[modalResource].weight_3" type="number" pattern="[0-9]*" inputmode="numeric" class="form-control w-100 text-right" 
                                        style="font-size:1.1em;font-weight:bold;"
                                        @focus="$event.target.select()" 
                                        />
                                   </div>
                              </div>
     
     
                         </div>
                    </div>
                    </div>
                    </div>
                    <div class="col-12 mt-1">
                    <div class="pl-3 pr-3 pt-0  ">
                         <div class="row pl-2 pr-2 pt-1 pb-1 mb-1 text-dark"
                              :class="{
                                  'border-success': avgWeight,
                                  'bg-light-green': avgWeight,
                                  'bg-light-dark': !avgWeight
                              }"
                              style="border-radius: 0.25rem; border: 2px solid #ccc; position: relative;"
                              :style="{ background: stocks[modalResource].weight ? '#C2EF81' : '#eee' }">
                              
                         <div class="col-6 d-flex flex-column p-1 justify-content-center align-items-start">
                              <h5 class="m-0 font-weight-bold">น้ำหนักมาตรฐาน</h5>
                         </div>
     
                         <div class="col-6 d-flex flex-column p-1">
                              <h5 class="m-0 text-right font-weight-bold"> {{avgWeight ? numberFormat(avgWeight)+" KG" : "ใส่ให้ครบ 3 ครั้ง"}} </h5>
                         </div>
                    </div>
                    </div>
                          </div>
                    </div>
                 
     
               </template>
               <template #modal-footer>
                    <div class="row w-100 mt-1">
                    <div class="col-6 pl-2 pr-2 pb-0">
                         <b-button block variant="light" @click="calReset()">
                         <div style="font-size:1.4em;font-weight:bold;">
                         <b-icon icon="trash" style="position:relative;margin:0;padding:0;top:-4px !important;padding-right:5px;">
                         </b-icon>
                         ล้างค่า
                         </div>
                         </b-button>
                    </div>
                    <div class="col-6 pl-2 pr-2 pb-0">
                         <b-button block 
         :variant="isThreeWeight ? 'success' : 'secondary'" 
         :disabled="!isThreeWeight"
         @click.prevent="isThreeWeight && save_weight(modalResource);">
         <div style="font-size:1.4em;font-weight:bold;">
             <b-icon icon="check-circle"
                 style="position:relative;margin:0;padding:0;top:-4px !important;padding-right:5px;">
             </b-icon>
             ยืนยัน
         </div>
     </b-button>
                    </div>
                    <div class="col-12 p-2">
                         <b-button block variant="warning" @click.prevent="closeModal('modal_weight');">
                         <span style="font-size:1.4em;font-weight:bold;">ปิด</span>
                         </b-button>
                    </div>
     
     
     
     
                    </div>
               </template>
               </b-modal>
     
               
               <!-- Modal Calculator Last-->
               <b-modal id="modal_multi_last" 
               modal-class="peter-modal" 
               header-class="p-0 m-0 pl-1 pr-1 d-block" 
               body-class="p-0 pt-0 pb-0 mt-0"
               footer-class="p-0 m-0 pl-3 pr-3 " 
               no-fade no-close-on-backdrop >
               
               <template #modal-header>
                    <div class="row">
                    <div class="col-12">
                         <div class="text-truncate">
                         <b-badge variant="info" class="pl-2 pr-2 pt-1 pb-1">
                              <span style="font-size:1.3em;">{{stocks[modalResource].STKCOD}}</span>
                         </b-badge> {{stocks[modalResource].STKDES}}
                         </div>
                    </div>
                    
                    <div class="col-12 mt-1">
                    <div class="pl-3 pr-3 pt-0  ">
                         <div class="row pl-2 pr-2 pt-1 pb-1 mb-1 text-dark" style="border-radius: 0.25rem;border:2px solid #ccc;position:relative;background:#ddd;">
                         
                         <div class="col-6 text-center d-flex flex-column p-1">
                         <div class="h6 m-0 p-0 mb-1 font-weight-bold" @click="legEditTrigger=true">เรียงขาละ</div>
                         <b-form-input v-model="stocks[modalResource].leg" type="number" pattern="[0-9]*" inputmode="numeric"
                              style="height:51px;width:100%;padding-left:0;padding-right:0;font-size:1.9em;text-align:center;font-weight:bold;"
                              @focus="$event.target.select()" v-if="legEditTrigger" v-click-outside="resetLegEdit"></b-form-input>
                         <div v-if="!legEditTrigger" class="d-none d-flex flex-column align-item-center justify-content-center bg-info text-light" style="height:35px;width:100%;font-size:2em;text-align:center;font-weight:bold;border-radius:0.2em;" @click="addPal(stocks[modalResource].leg,true)">
                              {{(stocks[modalResource].leg) ? stocks[modalResource].leg : "-"}}
                         </div>
                         </div>
                         
                         <div class="col-3 text-center d-flex flex-column p-1" @click="addSmallPal(true)">
                         <div class="h6 m-0 p-0 mb-1 font-weight-bold">เพิ่มกอง</div>
                         <div class="d-flex flex-column align-item-center justify-content-center " style="background:#ddd;border:1px solid #888;height:35px;width:100%;font-size:2em;text-align:center;font-weight:bold;border-radius:0.2em;">
                              +
                         </div>
                         </div>
                         <div class="col-3 text-center d-flex flex-column p-1" @click="addPal(null,true)">
                         <div class="h6 m-0 p-0 mb-1 font-weight-bold">เพิ่มชุบนับ</div>
                         <div class="d-flex flex-column align-item-center justify-content-center bg-success" style="border:1px solid green;height:35px;width:100%;font-size:2em;text-align:center;font-weight:bold;border-radius:0.2em;">
                              +
                         </div>
                         </div>
                    </div>
                    </div>
                    </div>
     
                    
                    </div>
               </template>
               <template #default>
                    
                    
                    <div class="col-12 pl-1 pr-1 pt-0 pb-0">
                    <div class="pl-3 pr-3 " >
                         <div v-for="(cal,index) in calculator_last.cal.length" class="row pl-2 pr-2 pb-1 pt-1 bg-light mb-1"
                         style="border-radius: 0.25rem;border:1px solid #ddd;position:relative;" :key="index" >
                         <div class="row pl-3 pr-3 " >
                         <div v-if="(calculator_last.cal[index].leg * calculator_last.cal[index].floor) != 0"
                         class="d-flex flex-column justify-content-center align-item-center text-center font-weight-bold"
                         style="position:absolute;font-size:0.9em;right:0px;bottom:0px;padding-left:8px;padding-right:8px;min-width:90px;height:16px;background:#ddd;color:white;">
                         <span>เลทละ {{calculator_last.cal[index].leg * calculator_last.cal[index].floor}}</span>
                         </div>
                         <div class="col-3 text-center d-flex flex-column p-1">
                         <div class="h6 m-0 p-0 font-weight-bold">เรียงขาละ</div>
                         <b-form-input v-model="calculator_last.cal[index].leg" type="number" pattern="[0-9]*" inputmode="numeric"
                              style="height:47px;width:100%;padding:0;margin:0;font-size:1.8em;text-align:center;font-weight:bold;"
                              @focus="$event.target.select()"></b-form-input>
                         </div>
                         <div class="col-3 text-center d-flex flex-column p-1">
                         <div class="h6 m-0 p-0 font-weight-bold">จำนวนชั้น</div>
                         <b-form-input v-model="calculator_last.cal[index].floor" type="number" pattern="[0-9]*" inputmode="numeric"
                                style="height:47px;width:100%;padding:0;margin:0;font-size:1.8em;text-align:center;font-weight:bold;"
                              @focus="$event.target.select()"></b-form-input>
                         </div>
                         <div class="col-3 text-center d-flex flex-column p-1">
                         <div class="h6 m-0 p-0 font-weight-bold">#พาเลท</div>
                         <b-form-input v-model="calculator_last.cal[index].pal" type="number" pattern="[0-9]*" inputmode="numeric"
                                style="height:47px;width:100%;padding:0;margin:0;font-size:1.8em;text-align:center;font-weight:bold;"
                              @focus="$event.target.select()"></b-form-input>
                         </div>
                         <div class="col-3 text-center d-flex flex-column p-1" >
                         <div class="h6 m-0 p-0 font-weight-bold">ชุดนับที่ {{index+1}}</div>
                         <div v-if="delPalTrigger == index && calculator_last.cal.length > 1" class="p-0 bg-danger text-light" style="border-radius: 0.25rem;height:47px;width:100%;padding:0;line-height:47px;vertical-align:middle;font-size:1.8em;text-align:center;font-weight:bold;" @click="delPal(index,true)" v-click-outside="resetDelPal">ลบ?</div>
                         <div v-if="delPalTrigger != index || calculator_last.cal.length == 1" class="p-0" style="border-radius: 0.25rem;height:47px;width:100%;padding-left:0;padding-right:0;line-height:47px;vertical-align:middle;font-size:1.8em;text-align:center;font-weight:bold;"  @click="delPalTrigger = index"  >
                              {{calculator_last.cal[index].leg * calculator_last.cal[index].floor * calculator_last.cal[index].pal}}
                         </div>
                         </div>
                         </div>
                         </div>
                    </div>
                    </div>
     
                    <div class="col-12 pl-1 pr-1 pt-0 pb-0">
                    <div class="pl-3 pr-3 pt-0  " v-click-outside="resetDelSmallPal">
                         <div  class="row pl-2 pr-2 pb-1 pt-1 bg-light mb-1"
                         style="border-radius: 0.25rem;border:1px solid #ddd;position:relative;" >
     
                         <div v-for="(cal,index) in calculator_last.cal_.length" class="col-3 text-center d-flex flex-column p-1" :key="index">
                         <div class="h6 m-0 p-0 font-weight-bold" @click="delSmallPalTrigger = index">กองที่ {{index+1}}</div>
                         <div v-if="delSmallPalTrigger == index && calculator_last.cal_.length > 1" class="bg-danger text-light d-flex flex-column align-item-center justify-content-center" style="border-radius: 0.25rem;height:47px;width:100%;padding:0;font-size:1.8em;text-align:center;font-weight:bold;"
                              @click="delSmallPal(index,true)">ลบ?</div>
                         <b-form-input   v-if="delSmallPalTrigger != index || calculator_last.cal_.length == 1" v-model="calculator_last.cal_[index].big" type="number" pattern="[0-9]*" inputmode="numeric"
                              style="height:47px;width:100%;padding:0;font-size:1.8em;text-align:center;font-weight:bold;"
                              @focus="$event.target.select()"></b-form-input>
                         </div>
                         <div class="col-3 text-center d-flex flex-column p-1">
                         <div class="h6 m-0 p-0 font-weight-bold text-warning">หน่วยย่อย</div>
                         <b-form-input  v-model="calculator_last.cal_[0].small" class="text-warning" type="number" pattern="[0-9]*" inputmode="numeric"
                              style="height:47px;width:100%;padding-left:0;padding:0;font-size:1.8em;text-align:center;font-weight:bold;"
                              @focus="$event.target.select()"></b-form-input>
                         </div>
     
     
                    </div>
                    </div>
                    </div>
     
                    <div class="col-12 pl-1 pr-1 pt-0 pb-0 mb-1">
                         <div class="card">
     
                                        <div class="card-body pt-4 pb-2 px-3">
                                        <div class="row">
                                             <div class="col-4 pl-1 pr-0">
                                                  <div class="bg-danger p-0 d-flex flex-column" style="height:30px;border-radius: 0.25rem;">
                                                  <div class="w-100 text-center align-self-center font-weight-bold text-dark" style="position:relative" :class="{'text-light' : stocks[modalResource].counted_at!=null, 'text-secondary' : stocks[modalResource].counted_at===null}">
                                                       <div class="text-center w-100" style="font-size:0.9em;position:absolute;margin:auto;top:-19px;">นับเช้า</div>
                                                  <span  style="font-size:1.3em;line-height:30px;">
                                                             <template v-if="stocks[modalResource].counted_at == new Date().toJSON().slice(0, 10)">
                                                                 <span class="font-weight-bold" style="color:yellow !important;">{{ getValueFromAtom(stocks[modalResource].COUNT_ATOM,stocks[modalResource].CFACTOR).CONCAT_NO_PLUS }}</span>
                                                             </template>
                                                             <template v-else><span style="color:white!important;">-</span></template>
                                                  </span>
                                             </div></div></div>
                                             <div class="col-4 pl-1 pr-0">
                                                  <div class="bg-success p-0 d-flex flex-column" style="height:30px;border-radius: 0.25rem;"
                                                  @click="openModal('modal_sell_detail',modalResource)">
                                                  <div class="w-100 text-center align-self-center font-weight-bold text-dark" :class="{'text-light' : stocks[modalResource].counted_at!=null, 'text-secondary' : stocks[modalResource].counted_at===null}">
                                                        <div class="text-center w-100" style="font-size:0.9em;position:absolute;margin:auto;top:-19px;">ขายวันนี้</div>
                                                  <span style="font-size:1.3em;line-height:30px;">
                                                            {{ getValueFromAtom(stocks[modalResource].TODAY_SELL_ATOM_QTY + (stocks[modalResource].ATOM_ORDQTY-stocks[modalResource].ATOM_ORDQTY_DONE),stocks[modalResource].CFACTOR).CONCAT_NO_PLUS}} 
                                                  </span>
                                             </div></div></div>
                                             <div class="col-4 pl-1 pr-1">
                                                  <div class="bg-info p-0 d-flex flex-column" style="height:30px;border-radius: 0.25rem;"
                                                  @click="openModal('modal_count_1',modalResource)">
                                                  <div class="w-100 text-center align-self-center font-weight-bold text-dark" :class="{'text-light' : stocks[modalResource].counted_at!=null, 'text-secondary' : stocks[modalResource].counted_at===null}">
                                                       <div class="text-center w-100" style="font-size:0.9em;position:absolute;margin:auto;top:-19px;">ต้องเหลือ</div>
                                                  <span style="font-size:1.3em;line-height:30px;">
                                                       <template v-if="stocks[modalResource].counted_at == new Date().toJSON().slice(0, 10)">
                                                            {{ 
                                                                 getValueFromAtom( (stocks[modalResource].COUNT_ATOM-stocks[modalResource].ATOM_ORDQTY)-stocks[modalResource].TODAY_SOR_QTY,stocks[modalResource].CFACTOR).CONCAT_NO_PLUS}} 
                                                       </template>
                                                       <template v-else><span>-</span></template>
                                                        </span>
                                             </div></div></div>
                                                                                </div>
     
                                        </div>
                         </div>
                    </div>
                    
                    <div class="col-12 p-2 noselect">
                    <div class="p-2 d-flex align-items-center justify-content-center ">
                         <div class="row w-100">
                         <div class="col-6 p-0 pr-1 pl-3 text-center">
                         <div class="bg-light p-0 pt-1 d-flex flex-column" style="border-radius: 0.25rem;">
                              <div>หน่วยใหญ่ ({{stocks[modalResource].PQUCOD}})</div>
                              <div class="w-100 pb-3 text-center align-self-center font-weight-bold text-danger">
                              <span style="font-size:3em;line-height:50px;">
                                   {{ 
                                   calculator_last.cal.reduce(function(result,item) { return result + parseInt(item.leg * item.floor * item.pal) }, 0) +
                                   calculator_last.cal_.reduce(function(result,item) { return result + parseInt(item.big ) }, 0)
                                   + parseInt(_calPlus(modalResource).BIG)
                                   }}
                              </span>
                              </div>
                         </div>
                         </div>
                         <div class="col-6 p-0 pr-3 pl-1 text-center">
                         <div class="p-0 pt-1 d-flex flex-column bg-light" style="border-radius: 0.25rem;">
                              <div>หน่วยย่อย ({{stocks[modalResource].QUCOD}})</div>
                              <div class="w-100 pb-3 text-center align-self-center font-weight-bold" style="color:#FFBF00">
                              <span style="font-size:3em;line-height:50px;">
                                   {{(parseInt(calculator_last.cal_[0].small) + _calPlus(modalResource).SMALL)}}
                                   </span>
                              </div>
                         </div>
                         </div>
                         </div>
                    </div>
                    </div>
     
               </template>
               <template #modal-footer>
                    <div class="row w-100 mt-1">
                    <div class="col-6 pl-2 pr-2 pb-0">
                         <b-button block variant="light" @click="calReset(true)">
                         <div style="font-size:1.4em;font-weight:bold;">
                         <b-icon icon="trash" style="position:relative;margin:0;padding:0;top:-4px !important;padding-right:5px;">
                         </b-icon>
                         ล้างค่า
                         </div>
                         </b-button>
                    </div>
                    <div class="col-6 pl-2 pr-2 pb-0">
                         <b-button block variant="success" 
                         @click.prevent="save_count_stock_last(modalResource);">
                         <div style="font-size:1.4em;font-weight:bold;">
                         <b-icon icon="check-circle"
                              style="position:relative;margin:0;padding:0;top:-4px !important;padding-right:5px;">
                         </b-icon>
                         ยืนยัน
                         </div>
                         </b-button>
                    </div>
                    <div class="col-12 p-2">
                         <b-button block variant="warning" @click.prevent="calReset();closeModal('modal_multi_last');">
                         <span style="font-size:1.4em;font-weight:bold;">ปิด</span>
                         </b-button>
                    </div>
     
     
     
     
                    </div>
               </template>
               </b-modal>
                <!-- Modal Sell Details -->
               <b-modal  id="modal_sell_detail" 
                    ref="modal_sell_detail"
                    modal-class="peter-modal" 
                    body-class="pt-0 pb-0" 
                    header-class="p-0 m-0 pl-1 pr-1" 
                    footer-class="p-0 m-0 pl-3 pr-3 " 
                    no-close-on-backdrop centered no-fade>
                    
                    <template #modal-header>
                         <div class="w-100 text-center h5 font-weight-bold mb-0">สินค้าออกจากคลังวันนี้ ({{stocks[modalResource].PQUCOD}})</div>
                    </template>
                    <template #default>
                    <div class="row">
                         <div class="col-12 pt-0 text-left" >
                               <div class="w-100 text-truncate " style="padding-left:1px;">
                              <b-badge variant="info" class="pl-1 pr-1 pt-1 pb-1 mr-2">
                                   <span style="font-size:1.2em;margin-left:1px;">{{stocks[modalResource].STKCOD}}</span></b-badge> 
                                   <span class="font-weight-bold" style="font-size:1.1em;">{{stocks[modalResource].STKDES}}</span>
                              </div>
                              <div class="w-100 text-truncate mt-2" style="padding-left:1px;">
                                   <span class="font-weight-bold" style="font-size:1em;">รายชื่อลูกค้าตาม SO</span>
                              </div>
                              <div class="row pl-2 pr-3 mt-0">
                                   <div v-for="(so,so_index) in stocks[modalResource].SOCUS" class="col-12 pl-3 pr-3 px-0  d-flex justify-content-between" :key="so_index">
                                        <span class="text-truncate"> <div class="bg-success p-0 m-0 text-center text-light font-weight-bold" style="border-radius: 3em; min-width: 18px; display: inline-block;line-height:18px;" >{{(so_index+1)}}</div> {{so.NAME}}</span>
                                        <span class="text-right" style="width:70px;">{{ getValueFromAtom(so.ATOM_QTY,stocks[modalResource].CFACTOR).CONCAT_NO_PLUS }} {{stocks[modalResource].PQUCOD}}</span>
                                   </div>
                              </div>
                              
                              <div class="w-100 text-truncate mt-2" style="padding-left:1px;">
                                   <span class="font-weight-bold" style="font-size:1.1em;">สรุปจำนวน</span>
                              </div>
                              <div class="row pl-0 pr-0 mt-1">
                                   <!-- Product Infomations -->
                                   <div class="col-12 pl-3 pr-3 px-0  d-flex justify-content-between">
                                       <div style="padding-top:1px;">จัดสินค้าตาม SO</div>
                                       <div>
                                             <b-badge  :variant="(stocks[modalResource].ATOM_ORDQTY) ? 'warning' : 'light'" class="mr-1 text-dark" style="width:60px;padding:3px;"><span style="font-size:1.5em;margin-left:1px;">{{getValueFromAtom(stocks[modalResource].ATOM_ORDQTY,stocks[modalResource].CFACTOR).CONCAT_NO_PLUS}}</span></b-badge>
                                       </div>
                                   </div>
                                    <div class="col-12 pl-3 pr-3 px-0  mt-0 d-flex justify-content-between">
                                       <div style="padding-top:1px;">ขายสด</div>
                                       <div @click="stocks[modalResource].count_code = suggestGroup;save(modalResource);closeModal('modal_select_group_1')">
                                             <b-badge  :variant="(stocks[modalResource].TODAY_CASH_SELL) ? 'warning' : 'light'" class="mr-1 text-dark" style="width:60px;padding:3px;"><span style="font-size:1.5em;margin-left:1px;">{{getValueFromAtom(stocks[modalResource].TODAY_CASH_SELL,stocks[modalResource].CFACTOR).CONCAT_NO_PLUS}}</span></b-badge>
                                       </div>
                                   </div>
                                   <div class="col-12 pl-3 pr-3 px-0 mt-0 d-flex justify-content-between">
                                       <div style="padding-top:1px;">เบิกสินค้าไปหน้าร้าน</div>
                                       <div @click="stocks[modalResource].count_code = suggestGroup;save(modalResource);closeModal('modal_select_group_1')">
                                             <b-badge  :variant="(stocks[modalResource].TODAY_SOR_QTY) ? 'warning' : 'light'" class="mr-1 text-dark" style="width:60px;padding:3px;"><span style="font-size:1.5em;margin-left:1px;">{{getValueFromAtom(stocks[modalResource].TODAY_SOR_QTY,stocks[modalResource].CFACTOR).CONCAT_NO_PLUS}}</span></b-badge>
                                       </div>
                                   </div>
                                   <div class="col-12 pl-3 pr-3 px-0  mt-0 d-flex justify-content-between" >
                                       <div style="padding-top:1px;font-size:1.1em;"><strong>รวมสินค้าออกจากคลังวันนี้</strong></div>
                                       <div @click="stocks[modalResource].count_code = suggestGroup;save(modalResource);closeModal('modal_select_group_1')">
                                            <b-badge  :variant="(stocks[modalResource].TODAY_SOR_QTY) ? 'success' : 'success'" class="mr-1 text-dark" style="width:60px;padding:3px;"><span style="font-size:1.5em;margin-left:1px;">{{ getValueFromAtom(stocks[modalResource].TODAY_SELL_ATOM_QTY + (stocks[modalResource].ATOM_ORDQTY-stocks[modalResource].ATOM_ORDQTY_DONE),stocks[modalResource].CFACTOR).CONCAT_NO_PLUS}} </span></b-badge>
                                       </div>
                                   </div>
                              </div>
                              
                         </div>
                    </div>
                    </template>
                    <!-- Modal Fotter -->
                    <template #modal-footer>
                    <div class="row w-100">
                         <!-- ปุ่มนับสต็อก -->
                         <div class="col-6 p-2">
                            &nbsp;
                         </div>
                         <!-- ปุ่มปิด -->
                         <div class="col-6 p-2">
                              <template v-if="!count_stock_tricker">
                              <b-button block @click.prevent="closeModal('modal_sell_detail');imageDisplay=false;"
                              variant="warning">
                              <span class="font-weight-bold" style="font-size:1.4em;"> ปิด</span> </b-button>
                              </template>
                         </div>
                    </div>
                    </template>
                    
               </b-modal>
               
               
               <!-- Main CONTENT-->
               <div class="row" >
     
                    <!-- Print Icon-->
                    <div v-if="reportNum!=null" style="width:60px;height:60px;position:fixed;z-index:999;bottom:30px; left:30px;border-radius:1em;line-height:60px;vertical-align:middle;font-size:2em;border:1px solid black;" class="bg-warning text-dark text-center" @click="generateReport(reportNum)">
                         <b-icon icon="file-earmark-text-fill" style="position:relative;margin:0;padding:0;"></b-icon>
                    </div>
     
                    <!-- CONTENT-->
                    <div class="col-12 ">
                         <dataset
                              v-slot="{ ds }"
                              :ds-data="stocks"
                              :ds-sortby="sortBy"
                              :ds-filter-fields="{ 
                                   STKDES: filters.refreshFilter, 
                                   is_watch: filters.is_watchFilter, 
                                   is_bsts: filters.is_bstsFilter, 
                                   WARNING: filters.percentFilter, 
                                   notify: filters.notiFilter, 
                                   counted_at: filters.countFilter, 
                                   user : filters.notCountFilter,
                                   NEVER_SELL : filters.neverSellFilter,
                                   ORDERED: filters.makeOrderFilter,
                                   hide:filters.hideFilter,
                                   TODAY_COUNT: filters.todaycountFilter,
                                   is_po: filters.ispoFilter,
                                   count_expired: filters.countexpiredFilter,
                                   count_day: filters.countdayFilter,
                                   GROUP_COUNT: filters.groupcountFilter,
                                   is_so: filters.soCountFilter
                              }"
                              :ds-search-in="['STKDES','STKGRP','STKCOD','unique_code','SUPNAM_SUPPLIER', 'SUPNAM']"
                              :ds-sort-as="{ LOCBAL:sortAsLOCBAL, PERCENT:sortAsLOCBAL, LLK:sortAsDate, LSK:sortAsDate, LSS:sortAsDate,LASTEST_PODAT:sortAsDate,count_code:sortStringAsNumber }"
                         >
                             
                              <!-- Main Tabs -->
                              <div class="col-12 pt-3 text-center ">
                              <div class="row pl-1 pr-1">
                              <div class="col-3 btn p-0 h-100 ">
                                   <h6 class="m-0 p-2 main_button" :class="{'active' : tab_state == 3}"
                                        @click.prevent="compactView=(tab_state != 3)?true:(compactView)?false:true;
                                        tab_state=3;imageState=false;weekdayState=false; 
                                        reportNum=null;productTab=0;groupType=false;
                                        applyFilters({hideFilter:0,percentFilter:1,notiFilter:1});
                                        refreshEntries(100);
                                        customClick([8,12,10],['asc','asc','desc']);"
                                   >ใกล้หมด
                                        <span class="position-absolute  start-50 translate-middle badge rounded-pill bg-danger text-light" style="right:0;top:-5px;">
                                             {{lessStock_count}}
                                        </span>
                                   </h6>
                              </div>
                              <div class="col-3 btn p-0 h-100 "  >
                                   <h6 class="m-0 p-2 main_button"  :class="{'active' : tab_state == 1 }"
                                    @click.prevent="compactView=false;
                                    tab_state=1;imageState=false;weekdayState=false; 
                                    reportNum=null;productTab=0;groupType='count_code';
                                    refreshEntries(100);
                                    applyFilters({hideFilter:0,makeOrderFilter:1});
                                    customClick([8,9]);"
                                    >สั่งวันนี้ 
                                        <span v-if="makeOrderFilter_count >=1 " class="position-absolute start-50 translate-middle badge rounded-pill bg-warning text-light" style="right:0;top:-5px;">
                                             {{makeOrderFilter_count}}
                                        </span>
                              </h6>
                              </div> 
                              <div class="col-3 btn p-0 h-100"  >
                                   <h6 class="m-0 p-2 main_button" :class="{'active' : tab_state == 2}"
                                   @click.prevent="compactView=(tab_state != 2)?false:(compactView)?false:true;
                                    tab_state=2;imageState=false;weekdayState=false; 
                                    reportNum=null;productTab=0;groupType=false;
                                    refreshEntries(200);
                                    applyFilters({hideFilter:0,countFilter:new Date().toJSON().slice(0, 10)});
                                    customClick([15]);"
                                   >นับวันนี้ 
                                        <span v-if="countFilter_count >=1 " class="position-absolute start-100 translate-middle badge rounded-pill bg-warning text-light" style="right:0;top:-5px;">
                                             {{countFilter_count}}
                                        </span>
                                   </h6>
                              </div> 
                              <div class="col-3 btn p-0 h-100">
                                   <h6 class="m-0 p-2 main_button" :class="{'active' : tab_state == 4 }"
                                    @click.prevent="compactView=false;
                                    tab_state=4;imageState=false;weekdayState=false; 
                                    reportNum=null;productTab=0;groupType='count_code';
                                    refreshEntries(100);
                                    applyFilters({hideFilter:0,ispoFilter:1});
                                    customClick([14]);"
                                   >รอสินค้า 
                                   <span v-if="isPo_count >=1 " class="position-absolute start-50 translate-middle badge rounded-pill bg-warning text-light" style="right:0;top:-5px;">
                                             {{isPo_count}}
                                   </span>
                              </h6>
                              </div> 
     
                              
                              
                              
     
                              </div>
                              </div>
     
                              <!-- All Products -->
                              <div class="col-12 p-1 pb-0  text-center ">
                                   <div class="row">
                                        <div class="col-6" style="padding-right:1px;" >
                                              <h6 class="w-100 btn main_button mb-0" :class="{'active' : tab_state == 0}" style="font-size:1.2em;"
                                                  @click="tab_state=0;imageState=false;weekdayState=false;
                                                  reportNum=1;compactView=false;
                                                  applyFilters({hideFilter:0});
                                                  customClick([1],['asc']);
                                                  refreshEntries(100);"
                                                  >
                                             สินค้าทั้งหมด 
                                             </h6>
                                        </div>
                                        <div class="col-6 "  style="padding-left:1px;"> 
                                             <h6 class="w-100 btn main_button mb-0" :class="{'active' : tab_state == 7}" style="font-size:1.2em;"
                                                  @click="tab_state=7;imageState=false;weekdayState=false; 
                                                  reportNum=3;compactView=false;groupType=false;
                                                  applyFilters({soCountFilter:true});
                                                  customClick([17],['desc']);
                                                  refreshEntries(100);"
                                             >
                                             จัดวันนี้ 
                                             </h6>
                                        </div>
                                   </div>
                                  
                                   
                              </div>
     
                              
     
                              <!-- Search Bar -->
                              <div class="col-12 pl-2 pr-2 pt-1 sticky-top">
                              <div class="row pl-1 pr-1 mb-1 ">
                              <div class="col-10 pl-2 pr-0">
                              <template v-if="searchSwitchMode">
                              <dataset-search ds-search-placeholder="รหัสบาโค๊ด..." :wait="500"  ref="search_input" class="search-input" style="border-radius:0;height:50px;font-size:1.3em;border:1px solide #ddd;" type="number" pattern="[0-9]*" inputmode="numeric"/>
                              </template>
                              <template v-else>
                              <dataset-search ds-search-placeholder="ชื่อสินค้า..."  :wait="500" ref="search_input" class="search-input" style="border-radius:0;height:50px;font-size:1.3em;border:1px solide #ddd;" />
                              </template>
                              </div>
                              <div class="col-2 pl-0 pr-2 text-center">
                              <div class="h-100 w-100 bg-primary text-center text-light d-flex flex-column align-item-center justify-content-center">
                                   <div class="w-100"> 
                              <b-icon v-if="searchSwitchMode" class="h4 m-0" icon="textarea-t" aria-hidden="true" @click.prevent="searchSwitchMode=false"></b-icon>
                              <b-icon v-if="!searchSwitchMode" class="h4 m-0" icon="file-spreadsheet" aria-hidden="true" @click.prevent="searchSwitchMode=true"></b-icon>
                                   </div>
                              </div>
                              </div>
                              </div>
                              </div>
     
                              <!-- SubMenu MainButton -->
                              <div class="col-12 mt-2">
                              <div v-show="tab_state==0" class="row pl-1 pr-1">
                              <div class="col-3 btn p-0 h-100">
                                   <h6 class="m-0 p-2" style="border:1px solid #ddd;border-radius:0.25em;" 
                                   :class="{'btn-danger font-weight-bold border-danger' : filters.countdayFilter}"
                                   @click="compactView=true;
                                   tab_state=0;subMenuSeq=0;imageState=false;weekdayState=true; 
                                   reportNum=null;groupType='count_code';
                                   refreshEntries(200);
                                   applyFilters({hideFilter:0,countdayFilter:new Date().getDay()});
                                   customClick([15]);"
                                   >ต้องนับ
                                        <span v-if="mushCount_count >= 1 " class="position-absolute  start-50 translate-middle badge rounded-pill bg-danger text-light" style="right:0;top:-5px;">
                                             {{mushCount_count}}
                                        </span>
                                   </h6>
                              </div>
     
                              <div class="col-3 btn p-0 h-100">
                                   <h6 class="m-0 p-2" style="border:1px solid #ddd;border-radius:0.25em;" 
                                   :class="{'btn-danger font-weight-bold border-danger' : (filters.is_bstsFilter)}"
                                   @click="compactView=(subMenuSeq != 1)?true:(compactView)?false:true;
                                   tab_state=0;subMenuSeq=1;imageState=false;weekdayState=false; 
                                   reportNum=2;groupType='count_code';
                                   refreshEntries(100);
                                   applyFilters({hideFilter:0,is_bstsFilter:1});
                                   customClick([10],['asc']);"
                                   >รับเอง
                                   </h6>
                              </div>
     
                              <div class="col-3 btn p-0 h-100">
                                   <h6 class="m-0 p-2" style="border:1px solid #ddd;border-radius:0.25em;" 
                                   :class="{'btn-danger font-weight-bold border-danger' : (filters.is_watchFilter)}"
                                   @click="compactView=(subMenuSeq != 2)?true:(compactView)?false:true;
                                   tab_state=0;subMenuSeq=2;imageState=false;weekdayState=false; 
                                   reportNum=null;groupType='count_code';
                                   refreshEntries(100);
                                   applyFilters({hideFilter:0,is_watchFilter:1});
                                   customClick([10],['asc']);"
                                   >สินค้าซ้อ 
                                   </h6>
                              </div>
     
                              <div class="col-3 btn p-0 h-100">
                                   <h6 class="m-0 p-2" style="border:1px solid #ddd;border-radius:0.25em;" 
                                   :class="{'btn-danger font-weight-bold border-danger' : filters.notCountFilter==null}"
                                   @click="compactView=(subMenuSeq != 3)?true:(compactView)?false:true;
                                   tab_state=0;subMenuSeq=3;imageState=false;weekdayState=false; 
                                   reportNum=null;groupType='count_code';
                                   refreshEntries(100);
                                   applyFilters({hideFilter:'',notCountFilter:null});
                                   customClick([11],['desc']);"
                                   >ยังไม่นับ
                                   <span v-if="notCountFilter_count >= 1" class="position-absolute start-50 translate-middle badge rounded-pill bg-danger text-light"  style="right:0;top:-5px;">
                                             {{notCountFilter_count}}
                                   </span>
                                   </h6>
                              </div>
     
                              </div>
                              </div> 
                              
                              <!-- SubMenu Today Count -->
                              <div class="col-12 mt-2">
                              <div v-if="tab_state==2" class="row pl-1 pr-1">
                              <div class="col-6 btn p-0 h-100">
                                   <h6 class="m-0 p-2" style="border:1px solid #ddd;border-radius:0.25em;" 
                                   :class="{'btn-danger font-weight-bold border-danger' : filters.countFilter!='' && filters.groupcountFilter=='' }"
                                   @click.prevent="compactView=(tab_state != 2)?true:(compactView)?false:true;
                                    tab_state=2;imageState=false;weekdayState=false; 
                                    reportNum=null;productTab=0;groupType='count_code';
                                    refreshEntries(100);
                                    applyFilters({hideFilter:0,countFilter:new Date().toJSON().slice(0, 10)});
                                    customClick([15]);"
                                   >ทั้งหมด
                                        <span v-if="countFilter_count >= 1 " class="position-absolute  start-50 translate-middle badge rounded-pill bg-warning text-light" style="right:0;top:-5px;">
                                             {{countFilter_count}}
                                        </span>
                                   </h6>
                              </div>
                              <div class="col-6 btn p-0 h-100">
                                   <h6 class="m-0 p-2" style="border:1px solid #ddd;border-radius:0.25em;" 
                                   :class="{'btn-danger font-weight-bold border-danger' : (filters.groupcountFilter)}"
                                  @click.prevent="compactView=true;
                                    tab_state=2;imageState=false;weekdayState=false;
                                    reportNum=null;productTab=0;groupType='count_code';
                                    refreshEntries(200);
                                    applyFilters({hideFilter:0, groupcountFilter:1});
                                    customClick([15]);"
                                   > แสดงชุดกลุ่มสินค้า
                                   </h6>
                              </div>
     
                             
     
                              </div>
                              </div>
                              
                              <!-- SubMenu WeekDay -->
                              <div class="col-12 mt-0">
                              <div v-show="weekdayState" class="row pl-1 pr-1 mb-2">
                                   <div class="col-2 btn p-0 h-100">
                                        <h6 class="m-0 p-2" style="border:1px solid #eee;border-radius:0.25em;" 
                                        :class="{'btn-danger font-weight-bold border-danger' : (filters.countdayFilter==1),'btn-warning font-weight-bold border-warning' : new Date().getDay() == 1}"
                                        @click.prevent="compactView=(filters.countdayFilter != 1)?true:(compactView)?false:true;
                                        tab_state=0;imageState=false;weekdayState=true; 
                                        reportNum=null;productTab=0;groupType='count_code';
                                        refreshEntries(200);
                                        applyFilters({hideFilter:0, countdayFilter:1});
                                        customClick([15]);"
                                        >จ
                                        
                                         <span style="right:0;top:-5px;"
                                        class="position-absolute start-50 translate-middle badge rounded-pill text-light bg-gray font-weight-bold" 
                                        v-if="filters.countdayFilter==1" 
                                        >  {{ds.dsRows.length}}
                                             </span>
                                        </h6>
                                   </div>
     
                              <div class="col-2 btn p-0 h-100">
                                   <h6 class="m-0 p-2" style="border:1px solid #eee;border-radius:0.25em;" 
                                        :class="{'btn-danger font-weight-bold border-danger' : (filters.countdayFilter==2),'btn-warning font-weight-bold border-warning' : new Date().getDay() == 2}"
                                       @click.prevent="compactView=(filters.countdayFilter != 2)?true:(compactView)?false:true;
                                        tab_state=0;imageState=false;weekdayState=true; 
                                        reportNum=null;productTab=0;groupType='count_code';
                                        refreshEntries(200);
                                        applyFilters({hideFilter:0, countdayFilter:2});
                                        customClick([15]);">อ
                                         <span style="right:0;top:-5px;"
                                        class="position-absolute start-50 translate-middle badge rounded-pill text-light bg-gray font-weight-bold" 
                                        v-if="filters.countdayFilter==2" 
                                        >  {{ds.dsRows.length}}
                                             </span>
                                        </h6>
                              </div>
     
                              <div class="col-2 btn p-0 h-100">
                                   <h6 class="m-0 p-2" style="border:1px solid #eee;border-radius:0.25em;" 
                                        :class="{'btn-danger font-weight-bold border-danger' : (filters.countdayFilter==3),'btn-warning font-weight-bold border-warning' : new Date().getDay() == 3}"
                                        @click.prevent="compactView=(filters.countdayFilter != 3)?true:(compactView)?false:true;
                                        tab_state=0;imageState=false;weekdayState=true; 
                                        reportNum=null;productTab=0;groupType='count_code';
                                        refreshEntries(200);
                                        applyFilters({hideFilter:0, countdayFilter:3});
                                        customClick([15]);">พ
                                        
                                        <span style="right:0;top:-5px;"
                                        class="position-absolute start-50 translate-middle badge rounded-pill text-light bg-gray font-weight-bold" 
                                        v-if="filters.countdayFilter==3" 
                                        >  {{ds.dsRows.length}}
                                             </span>
                                        </h6>
                              </div>
     
                              <div class="col-2 btn p-0 h-100">
                                   <h6 class="m-0 p-2" style="border:1px solid #eee;border-radius:0.25em;" 
                                        :class="{'btn-danger font-weight-bold border-danger' : (filters.countdayFilter==4),'btn-warning font-weight-bold border-warning' : new Date().getDay() == 4}"
                                      @click.prevent="compactView=(filters.countdayFilter != 4)?true:(compactView)?false:true;
                                        tab_state=0;imageState=false;weekdayState=true; 
                                        reportNum=null;productTab=0;groupType='count_code';
                                        refreshEntries(200);
                                        applyFilters({hideFilter:0, countdayFilter:4});
                                        customClick([15]);">พฤ
                                        
                                         <span style="right:0;top:-5px;"
                                        class="position-absolute start-50 translate-middle badge rounded-pill text-light bg-gray font-weight-bold" 
                                        v-if="filters.countdayFilter==4" 
                                        >  {{ds.dsRows.length}}
                                             </span>
                                        </h6>
                              </div>
     
                              <div class="col-2 btn p-0 h-100">
                                  <h6 class="m-0 p-2" style="border:1px solid #eee;border-radius:0.25em;" 
                                        :class="{'btn-danger font-weight-bold border-danger' : (filters.countdayFilter==5),'btn-warning font-weight-bold border-warning' : new Date().getDay() == 5}"
                                         @click.prevent="compactView=(filters.countdayFilter != 5)?true:(compactView)?false:true;
                                        tab_state=0;imageState=false;weekdayState=true; 
                                        reportNum=null;productTab=0;groupType='count_code';
                                        refreshEntries(200);
                                        applyFilters({hideFilter:0, countdayFilter:5});
                                        customClick([15]);">ศ
                                        
                                         <span style="right:0;top:-5px;"
                                        class="position-absolute start-50 translate-middle badge rounded-pill text-light bg-gray font-weight-bold" 
                                        v-if="filters.countdayFilter==5" 
                                        >  {{ds.dsRows.length}}
                                             </span>
                                        </h6>
                              </div>
     
                              <div class="col-2 btn p-0 h-100">
                                   <h6 class="m-0 p-2" style="border:1px solid #eee;border-radius:0.25em;" 
                                        :class="{'btn-danger font-weight-bold border-danger' : (filters.countdayFilter==6),'btn-warning font-weight-bold border-warning' : new Date().getDay() == 6}"
                                          @click.prevent="compactView=(filters.countdayFilter != 6)?true:(compactView)?false:true;
                                        tab_state=0;imageState=false;weekdayState=true; 
                                        reportNum=null;productTab=0;groupType='count_code';
                                        refreshEntries(200);
                                        applyFilters({hideFilter:0, countdayFilter:6});
                                        customClick([15]);">ส
                                        
                                         <span style="right:0;top:-5px;"
                                        class="position-absolute start-50 translate-middle badge rounded-pill text-light bg-gray font-weight-bold" 
                                        v-if="filters.countdayFilter==6" 
                                        >  {{ds.dsRows.length}}
                                             </span>
                                        </h6>
                              </div>
     
                              
     
                              </div>
                              </div>
     
                              <!-- SubMenu Today Delivery -->
                              <div class="col-12 mt-2"  v-if="tab_state==7">
                              <div class="row pl-1 pr-1">
                              <div class="col-6 btn p-0 h-100">
                                   <h6 class="m-0 p-2" style="border:1px solid #ddd;border-radius:0.25em;" 
                                   :class="{'btn-danger font-weight-bold border-danger' : tab_state==7 && subMenu_today_delivery_details==false }"
                                    @click="tab_state=7;imageState=false;weekdayState=false;
                                                  reportNum=3;tableView=false;groupType=false;subMenu_today_delivery_details=false;
                                                  applyFilters({soCountFilter:true});
                                                  customClick([16],['desc']);
                                                  refreshEntries(100);"
                                   >ตรวจนับ
                                        <span v-if="countFilter_count >= 1 " class="position-absolute  start-50 translate-middle badge rounded-pill bg-warning text-light" style="right:0;top:-5px;">
                                             {{countFilter_count}}
                                        </span>
                                   </h6>
                              </div>
                              <div class="col-6 btn p-0 h-100">
                                   <h6 class="m-0 p-2" style="border:1px solid #ddd;border-radius:0.25em;" 
                                   :class="{'btn-danger font-weight-bold border-danger' : tab_state==7 && subMenu_today_delivery_details==true}"
                                   @click="tab_state=7;imageState=false;weekdayState=false;
                                                  reportNum=3;tableView=true;groupType=false;subMenu_today_delivery_details=true;
                                                  applyFilters({soCountFilter:true});
                                                  customClick([16],['desc']);
                                                  refreshEntries(100);"
                                   > รายละเอียด
                                   </h6>
                              </div>
     
                             
     
                              </div>
                              </div>
     
                              <!-- Search Result Indicator -->
                              <div class="col-12 mt-1 text-right pl-1 pr-1" style="font-style:italic">
                                  ผลการค้นหา {{ds.dsResultsNumber}} รายการ <span v-if="ds.dsPagecount >1">: หน้า {{ds.dsPage}}/{{ds.dsPagecount}}</span>
                              </div>
     
                              <!-- Card Display -->
                              <div v-if="!tableView" class="col-12 p-0"   :class="{'pl-4 pr-4 pb-4 pt-3 bg-light' : imageDisplay}" >
                                   <!-- Normal View -->
                                   <template v-if="!compactView">
     
                                   <dataset-item v-if="!imageDisplay" class="form-row mb-3">
                                   <template  #default="{ row, rowIndex }">
                                   <!-- New Today Count Stock View-->
                                   <div v-if="tab_state==7" class="col-md-3 pl-2 pr-2 pb-2">               
                                   <div class="card" 
                                        :class="(row.counted_at == new Date().toJSON().slice(0, 10)) ? row.BACKGROUND_CLASS : row.BACKGROUND_CLASS+ ' grayscale'" 
                                        :style="(row.counted_at == new Date().toJSON().slice(0, 10) && row.counted_at_last == new Date().toJSON().slice(0, 10)) && 'background:green !important;position:relative;'"
                                        >
                                       <div class="last_count_result text-light text-center font-weight-bold pl-2 pr-2" style="font-size:1.2em;position:absolute;top:5px;right:5px;border-radius: 2rem;min-width:30px;height:30px;line-height:31px;vertical-align:middle;z-index:999;" 
                                       v-show="row.counted_at == new Date().toJSON().slice(0, 10) && row.counted_at_last == new Date().toJSON().slice(0, 10)"
                                       :class="{'bg-success' : (row.COUNT_ATOM_LAST - (row.COUNT_ATOM-row.ATOM_ORDQTY) == 0), 'bg-danger' : (row.COUNT_ATOM_LAST - (row.COUNT_ATOM-row.ATOM_ORDQTY) < 0),'bg-warning' : (row.COUNT_ATOM_LAST - (row.COUNT_ATOM-row.ATOM_ORDQTY) > 0)}"
                                       >
                                            {{ (row.COUNT_ATOM_LAST - (row.COUNT_ATOM-row.ATOM_ORDQTY) == 0) ? 'ตรง' : getValueFromAtom(row.COUNT_ATOM_LAST - (row.COUNT_ATOM-row.ATOM_ORDQTY),row.CFACTOR).CONCAT }}
     
                                       </div>
                                        <div class="card-body pt-2 pb-2 px-3">
                                        <div class="row" :class="(row.counted_at != new Date().toJSON().slice(0, 10)) && 'grayscale'">
                                             <div class="col-12 pb-2 mb-3 pl-2 pr-2">
                                                  <h5 class="pl-0 card-title text-truncate mb-0 font-weight-bold pt-0" style="color:#2B2301">
                                                  <b-icon v-if="row.order_frequency == 7" icon="star-fill" class="h5" style="color:orange;margin:0;padding:0;"></b-icon>
                                                       {{ row.STKDES }}
                                                  </h5>
                                                  <h6 class="text-truncate pb-0 mb-0" style="color:#888;font-size:0.9em;"  :style="(row.counted_at == new Date().toJSON().slice(0, 10) && row.counted_at_last == new Date().toJSON().slice(0, 10)) && 'color:#111 !important'">
                                                      มูลค่า {{numberFormat(row.TOTAL_SO_TRNVAL)}} บาท 
                                                  </h6>
                                             </div>
                                          
                                             <div class="col-3 pl-1 pr-0">
                                                  <div class="p-0 d-flex flex-column" style="height:30px;border-radius: 0.25rem;"
                                                  :class="{
                                                       'bg-danger' : (row.counted_at_last != new Date().toJSON().slice(0, 10)) || (row.COUNT_ATOM_LAST - (row.COUNT_ATOM-row.ATOM_ORDQTY) != 0),
                                                       'bg-success' : (row.COUNT_ATOM_LAST - (row.COUNT_ATOM-row.ATOM_ORDQTY) == 0)
                                                  }"
                                                  @click="openModal('modal_count_1',rowIndex)">
                                                  <div class="w-100 text-center align-self-center font-weight-bold text-dark" style="position:relative" 
                                                  :class="{'text-light' : row.counted_at!=null, 'text-secondary' : row.counted_at===null}"
                                                  >
                                                       <div class="text-center w-100" style="font-size:0.9em;position:absolute;margin:auto;top:-19px;">นับเช้า</div>
                                                  <span  style="font-size:1.3em;line-height:30px;">
                                                             <template v-if="row.counted_at == new Date().toJSON().slice(0, 10)">
                                                                 <span class="font-weight-bold" 
                                                                 :class="{
                                                                      'text-warning' : (row.counted_at_last != new Date().toJSON().slice(0, 10)) || (row.COUNT_ATOM_LAST - (row.COUNT_ATOM-row.ATOM_ORDQTY) != 0),
                                                                      'text-dark' : (row.COUNT_ATOM_LAST - (row.COUNT_ATOM-row.ATOM_ORDQTY) == 0)
                                                                 }"
                                                             >{{ getValueFromAtom(row.COUNT_ATOM,row.CFACTOR).CONCAT_NO_PLUS }}</span>
                                                             </template>
                                                             <template v-else><span style="color:white!important;">-</span></template>
                                                  </span>
                                             </div></div></div>
                                             <div class="col-3 pl-1 pr-0">
                                                  <div class="bg-success p-0 d-flex flex-column" style="height:30px;border-radius: 0.25rem;"
                                                  @click="openModal('modal_sell_detail',rowIndex)">
                                                  <div class="w-100 text-center align-self-center font-weight-bold text-dark" :class="{'text-light' : row.counted_at!=null, 'text-secondary' : row.counted_at===null}">
                                                        <div class="text-center w-100" style="font-size:0.9em;position:absolute;margin:auto;top:-19px;">ขายวันนี้</div>
                                                  <span style="font-size:1.3em;line-height:30px;">
                                                            {{ getValueFromAtom(row.TODAY_SELL_ATOM_QTY + (row.ATOM_ORDQTY-row.ATOM_ORDQTY_DONE),row.CFACTOR).CONCAT_NO_PLUS}} 
                                                  </span>
                                             </div></div></div>
     
                                             <div class="col-3 pl-1 pr-0" v-show="row.counted_at == new Date().toJSON().slice(0, 10)">
                                                  <div class="p-0 d-flex flex-column" style="height:30px;border-radius: 0.25rem;"
                                                  :class="{
                                                       'bg-info' : (row.counted_at_last != new Date().toJSON().slice(0, 10)) || (row.COUNT_ATOM_LAST - (row.COUNT_ATOM-row.ATOM_ORDQTY) != 0),
                                                       'bg-success' : (row.COUNT_ATOM_LAST - (row.COUNT_ATOM-row.ATOM_ORDQTY) == 0)
                                                  }"
                                                  >
                                                  <div class="w-100 text-center align-self-center font-weight-bold text-dark" :class="{'text-light' : row.counted_at!=null, 'text-secondary' : row.counted_at===null}">
                                                       <div class="text-center w-100" style="font-size:0.9em;position:absolute;margin:auto;top:-19px;"
                                                       >ต้องเหลือ</div>
                                                  <span style="font-size:1.3em;line-height:30px;">
                                                       <template v-if="row.counted_at == new Date().toJSON().slice(0, 10)">
                                                            {{ getValueFromAtom((row.COUNT_ATOM-row.ATOM_ORDQTY)-row.TODAY_SOR_QTY,row.CFACTOR).CONCAT_NO_PLUS}} 
                                                       </template>
                                                       <template v-else><span>-</span></template>
                                                        </span>
                                             </div></div></div>
                                           
                                             <div class="col-3 pl-1 pr-1 " v-show="row.counted_at == new Date().toJSON().slice(0, 10)">
                                                  <div class="p-0 d-flex flex-column" style="height:30px;border-radius: 0.25rem;"
                                                  @click="(row.counted_at == new Date().toJSON().slice(0, 10)) && openModalCalLast(rowIndex)"
                                                  :class="{
                                                       'bg-warning' : (row.counted_at_last != new Date().toJSON().slice(0, 10)) || (row.COUNT_ATOM_LAST - (row.COUNT_ATOM-row.ATOM_ORDQTY) != 0),
                                                       'bg-success' : (row.COUNT_ATOM_LAST - (row.COUNT_ATOM-row.ATOM_ORDQTY) == 0)
                                                  }"
                                                  >
                                                  <div class="w-100 text-center align-self-center font-weight-bold " :class="{'text-dark' : row.counted_at!=null, 'text-dark' : row.counted_at===null}">
                                                        
                                                        <div class="text-center w-100" style="font-size:0.9em;position:absolute;margin:auto;top:-19px;">นับเย็น</div>
                                                       <template v-if="row.counted_at_last == new Date().toJSON().slice(0, 10)">
                                                            <span class="font-weight-bold" style="font-size:1.3em;line-height:30px;"
                                                            :class="{
                                                                      'text-light' : (row.counted_at_last != new Date().toJSON().slice(0, 10)) || (row.COUNT_ATOM_LAST - (row.COUNT_ATOM-row.ATOM_ORDQTY) != 0),
                                                                      'text-dark' : (row.COUNT_ATOM_LAST - (row.COUNT_ATOM-row.ATOM_ORDQTY) == 0)
                                                                 }"
                                                            >{{ getValueFromAtom(row.COUNT_ATOM_LAST,row.CFACTOR).CONCAT_NO_PLUS }}</span>
                                                            </template>
                                                       <template v-else><span>-</span></template>
                                                                                     
                                                                                     </div>
     
                                                                                     </div>
                                                                                     </div>
                                                                                     
                                                                                  
                                                                                     
                                                                                </div>
     
                                        </div>
                                        </div>
                                   </div>
                                   <!-- Card ImageState Object  -->
                                   <div v-if="tab_state !=2 && tab_state !=7 && imageState && !imageDisplay" class="col-md-3 pl-2 pr-2 pb-2">
                                                                 <!-- Card -->
                                                                 <div class="card " :class="row.BACKGROUND_CLASS" style="border:1px solid black;">
                                                                      <div class="card-body pt-1 pb-1 px-3">
                                                                      <div class="row">
                                                                           <div class="col-12 pb-0 pr-2">
                                                                           <h5 class="card-title text-truncate mb-0 font-weight-bold pt-1" style="color:#2B2301">
                                                                           {{ row.STKDES }} 
                                                                           </h5>
                                                                           <h6 class="text-truncate pb-0 mb-0" style="color:#888;font-size:0.9em;">
                                                                           {{ (row.SUPNAM_SUPPLIER != null) ? row.SUPNAM_SUPPLIER : "ไม่มีข้อมูล" }}
                                                                           </h6>
                                                                           </div>
                                                                           
                                                                           <div class="col-12">
                                                                           <div class="row">
                                                                                <!-- ราคาและ Icon -->
                                                                                <div class="col-6 mt-2 mb-0 pr-1 pt-1">
                                                                                     
                                                                                     <div  class="p-1 m-0 text-center  h5 font-weight-bold bg-danger" style="border-radius: 0.25rem;" @click="openModal('modal_count_1',rowIndex)" 
                                                                                     :class="{
                                                                                          'text-light' : (row.counted_at != null),
                                                                                          'text-secondary' : row.counted_at == null}">
                                                                                          {{ row.BIG_LOCBAL + getValueFromAtom(row.express_DIFF,row.CFACTOR).BIG}} {{row.PQUCOD}} 
                                                                                </div>
                                                                                     
                                                                                </div>
                                                                                
                                                                                <div v-if="row.ordered_at == new Date().toJSON().slice(0, 10) || row.is_po"
                                                                                               class="col-6 mt-2 mb-0 pr-3 pl-1 pt-1">
                                                                                               
                                                                                               <div class="p-1 m-0 text-center text-dark h5 font-weight-bold "
                                                                                               :class="{' bg-orange' : !row.is_po, 'bg-gray' : row.is_po}"
                                                                                                    style="border-radius: 0.25rem;">
                                                                                                    {{(row.is_po) ? getValueFromAtom(row.PO_ORDQTY,row.CFACTOR).BIG : row.make_order}}
                                                                                                    {{row.PQUCOD}}
                                                                                               </div>
                                                                                          </div>
     
     
     
     
                                                                                <div class="col-12">
                                                                                     <div class="row">
                                                                                <!-- ระดับสต็อก -->
                                                                                <div class="col-6 mb-0 p-0 pb-0 pl-3 pr-0 mt-0 pt-1" style="font-size:0.9em;">
                                                                                     สินค้าคงเหลือ <strong>{{row.PERCENT}}%</strong>
                                                                                </div>    
                                                                                <div class="col-6 mb-0 p-0 pb-0 pl-2 pr-3 mt-0 text-left pt-1" style="font-size:0.9em;">
                                                                           ขาย{{(row.order_frequency == 30) ? "เดือน" : (row.order_frequency == 7) ? "สัปดาห์" : "เดือน" }}ละ
                                                                                          : <strong>{{row.PERCENT}}</strong> {{row.PQUCOD}}
                                                                                </div>    
     
                                                                                     </div>
                                                                                </div>
                                                                           
                                                                           </div>
                                                                           </div>
                                                                           
                                                                      </div>
     
                                                                      </div>
                                                                 </div>
                                                                 
                                   </div>
                                   <!-- Card Normal Object  -->
                                   <div v-if="tab_state !=2  && tab_state !=7 && !imageState && !imageDisplay" class="col-md-3 pl-2 pr-2 pb-2">
                                                                 <!-- Card -->
                                                                 <div v-if="!imageState" class="card " :class="row.BACKGROUND_CLASS" :style="(row.is_po) ? 'background:#aaa!important' : 'border:1px solid black;' ">
                                                                      <div class="card-body pt-2 pb-2 px-3 ">
                                                                      <div class="row">
                                                                           <div class="col-12 pb-1 pr-2">
                                                                           <h5 class="card-title text-truncate mb-0 font-weight-bold pt-1" style="color:#2B2301"
                                                                           :title="`Index: ${rowIndex}`">
                                                                           <b-icon v-if="row.order_frequency == 7" icon="star-fill" class="h5" style="color:orange;margin:0;padding:0;"></b-icon>
                                                                           {{ row.STKDES }} 
                                                                           </h5>
                                                                           <h6 class="text-truncate pb-0 mb-0" style="color:#888;font-size:0.9em;">
                                                                           {{ (row.SUPNAM_SUPPLIER != null) ? row.SUPNAM_SUPPLIER : "ไม่มีข้อมูล" }} 
                                                                           </h6>
                                                                           </div>
                                                                           
                                                                           <div class="col-8">
                                                                           
                                                                           <div class="row">
                                                                                <!-- ราคาและ Icon -->
                                                                                <div class="col-7 mt-1 mb-0 pr-0">
                                                                                     <div  v-if="row.ordered_at == new Date().toJSON().slice(0, 10)" class="p-1 m-0 text-center text-danger m-0 h4 font-weight-bold"
                                                                                     style="border-radius: 0.25rem;">
                                                                                          ออเดอร์
                                                                                     </div>
                                                                                     <div  v-if="row.ordered_at == null || (row.ordered_at != new Date().toJSON().slice(0, 10))" class="p-0 text-left text-dark" style="position:relative;">
                                                                                     <b-icon icon="bell-fill" class="h4" :class="{'fadex' : !row.notify}"
                                                                                          style="cursor:pointer;margin:0;padding:0;padding-top:2px;margin-right:2px;" @click="stocks[rowIndex].notify = (stocks[rowIndex].notify) ? 0 :1; save(rowIndex)"></b-icon>
                                                                                     <b-icon icon="house-door-fill" class="h4" :class="{'fadex' : !row.is_bsts}"
                                                                                          style="cursor:pointer;margin:0;padding:0;margin-right:2px;" @click="stocks[rowIndex].is_bsts = (stocks[rowIndex].is_bsts) ? 0 :1; save(rowIndex)"></b-icon>
                                                                                     <b-icon icon="lightning-fill" class="h4" :class="{'fadex' : !row.is_watch}"
                                                                                          style="cursor:pointer;margin:0;padding:0;margin-right:2px;" @click="stocks[rowIndex].is_watch = (stocks[rowIndex].is_watch) ? 0 :1; save(rowIndex)"></b-icon>
                                                                                     <div v-if="stocks[rowIndex].count_day != null" class="d-inline-block text-center font-weight-bold bg-dark" style="position:relative;top:-3px;width:30px;border-radius:0.25em;color:#eee">{{dayText[stocks[rowIndex].count_day]}}</div>
                                                                                     
                                                                                     </div>
                                                                                </div>
     
                                                                                <div v-if="(row.barcode != null || row.unique_code != null) && (row.ordered_at != new Date().toJSON().slice(0, 10)) && !row.is_po" class="col-5 pl-2 pr-2 pt-1 d-flex flex-column justify-content-center">
                                                                                     <div v-if="row.barcode != null" >
                                                                                     <VueBarcode :value="row.barcode" format="CODE128" background="none" height="40"></VueBarcode>
                                                                                     </div>
                                                                                     <div v-else-if="row.barcode == null && row.unique_code != null" >
                                                                                     <VueBarcode :value="row.unique_code" format="CODE128" background="none" height="20" ></VueBarcode>
                                                                                     </div>
                                                                                </div>
     
                                                                                <div v-if="(row.ordered_at == new Date().toJSON().slice(0, 10)) && !row.is_po" class="col-5 p-1 pr-2 m-0">
                                                                                     <div  class="p-1 m-0 text-center text-dark h5 font-weight-bold bg-orange" style="border:1px solid orange; border-radius: 0.25rem;">
                                                                                               {{row.make_order}} {{row.PQUCOD}}
                                                                                     </div>
                                                                                </div> 
     
                                                                                <div v-if="row.is_po" class="col-5 p-1 pr-2 m-0">
                                                                                     <div  class="p-1 m-0 text-center text-light h6 font-weight-bold bg-secondary" style="border-radius: 0.25rem;font-size:0.9em;">
                                                                                               {{dayAgo(row.LASTEST_PODAT)}} 
                                                                                     </div>
                                                                                </div> 
                                                                                     
                                                                                     
                                                                                <!--  <div class="col-6 pl-0 pr-0 pt-1 mt-1">
                                                                                     <b-icon v-if="row.notify" icon="bell-fill" class="h4"
                                                                                          style="cursor:pointer;margin:0;padding:0;padding-top:2px;margin-right:3px;"></b-icon>
                                                                                     <b-icon v-if="row.is_bsts" icon="house-door-fill" class="h4"
                                                                                          style="cursor:pointer;margin:0;padding:0;margin-right:3px;"></b-icon>
                                                                                     <b-icon v-if="row.is_watch" icon="lightning-fill" class="h4"
                                                                                          style="cursor:pointer;margin:0;padding:0;margin-right:3px;"></b-icon>
                                                                                </div> -->
                                                                                
                                                                                <!-- ปริมาณขายเฉลี่ย -->
                                                                                <div class="col-12 pb-0 mb-0 pt-0 mt-1 mb-1">
                                                                                     <div style="line-height:18px;">
                                                                                     <div>
                                                                                          ขายเฉลี่ย{{(row.order_frequency == 30) ? "เดือน" : (row.order_frequency == 7) ? "สัปดาห์" : "เดือน" }}ละ
                                                                                          : <strong>{{row.AVG_SELL}}</strong> {{row.PQUCOD}} 
                                                                                     </div>
                                                                                     </div>
                                                                                </div>
                                                                                <!-- ระดับสต็อก -->
                                                                                <div class="col-12 mb-1 d-flex flex-column justify-content-center p-0 pl-4 pr-0">
                                                                           <div class="pl-1 pr-0 w-100">
                                                                           <div class="row p-0 text-center mr-2 "
                                                                                style="border:1px solid black;border-radius: 0.25rem;position:relative;">
                                                                                <div style="padding-left:8px;position:absolute;z-index:990">ระดับสต็อก {{row.PERCENT}}%
                                                                                </div>
                                                                                <div class="pl-2 text-left" style="border-radius: 0.25rem;"
                                                                                :class="row.PERCENT_CLASS">&nbsp;</div>
                                                                           </div>
                                                                           </div>
                                                                                </div>    
                                                                                <!-- วันที่นับสต็อก -->
                                                                                <!-- <div class="col-12 pb-0 mb-0 pt-1">
                                                                                <div style="line-height:18px;">
                                                                                <div>
                                                                                     <b-icon v-show="checkCountStock(rowIndex)" icon="check-circle-fill"  style="color:green;cursor:pointer;margin:0;padding:0;"></b-icon> 
                                                                                     <span v-if="checkCountStock(rowIndex)"> นับสต็อกเมื่อ : {{dayAgo(row.counted_at)}}</span>
                                                                                     </div>
                                                                                </div>
                                                                                </div> -->
                                                                           </div>
                                                                           </div>
                                                                           <div class="col-4 pl-2 pr-2 " style="width:100px;">
                                                                           
                                                                           <div class="bg-danger p-0 d-flex flex-column" style="height:90px;border-radius: 0.25rem;"
                                                                           @click="openModal('modal_count_1',rowIndex)">
                                                                           <div class="w-100 text-center align-self-center font-weight-bold " :class="{'text-light' : row.counted_at!=null, 'text-secondary' : row.counted_at===null}">
                                                                                <template v-if="row.counted_at != new Date().toJSON().slice(0, 10)" >
                                                                                <span v-if="row.BIG_LOCBAL > 999"
                                                                                style="font-size:2em;line-height:90px;">{{ row.BIG_LOCBAL + getValueFromAtom(row.express_DIFF,row.CFACTOR).BIG}} </span>
                                                                                <span v-else style="font-size:3em;line-height:90px;">{{ row.BIG_LOCBAL + getValueFromAtom(row.express_DIFF,row.CFACTOR).BIG}} </span>
                                                                                </template>
                                                                                <template v-if="row.counted_at == new Date().toJSON().slice(0, 10)" >
                                                                                <span v-if="row.BIG_LOCBAL > 999"
                                                                                style="font-size:2em;line-height:90px;color:yellow !important;">{{ row.BIG_LOCBAL + getValueFromAtom(row.express_DIFF,row.CFACTOR).BIG }} </span>
                                                                                <span v-else style="font-size:3em;line-height:90px;color:yellow !important;">{{ row.BIG_LOCBAL + getValueFromAtom(row.express_DIFF,row.CFACTOR).BIG}} </span>
                                                                                </template>
                                                                           
                                                                           </div>
     
                                                                           </div>
                                                                           </div>
                                                                           
                                                                      </div>
     
                                                                      </div>
                                                                 </div>
                                                                 <div v-if="imageState" class="card " :class="row.BACKGROUND_CLASS" style="border:1px solid black;">
                                                                      <div class="card-body pt-1 pb-1 px-3">
                                                                      <div class="row">
                                                                           <div class="col-12 pb-0 pr-2">
                                                                           <h5 class="card-title text-truncate mb-0 font-weight-bold pt-1" style="color:#2B2301">
                                                                           {{ row.STKDES }}
                                                                           </h5>
                                                                           </div>
                                                                           
                                                                           <div class="col-12">
                                                                           <div class="row">
                                                                                <!-- ราคาและ Icon -->
                                                                                <div class="col-6 mt-2 mb-0 pr-1 pt-1">
                                                                                     <span class="text-danger" style="position:absolute;font-size:0.7em; line-height:0px;top:-1px;padding-left:1px;">สินค้าคงเหลือ: </span>
                                                                                     <div  class="p-1 m-0 text-center  h5 font-weight-bold bg-danger" style="border-radius: 0.25rem;" @click="openModal('modal_count_1',rowIndex)" 
                                                                                     :class="{
                                                                                          'text-light' : (row.counted_at != null),
                                                                                          'text-secondary' : row.counted_at == null}">
                                                                                          {{ row.BIG_LOCBAL + getValueFromAtom(row.express_DIFF,row.CFACTOR).BIG}} {{row.PQUCOD}} 
                                                                                </div>
                                                                                     
                                                                                </div>
                                                                                
                                                                                <div v-if="row.ordered_at == new Date().toJSON().slice(0, 10)" class="col-6 mt-2 mb-0 pr-3 pl-1 pt-1">
                                                                                     <span class="text-success" style="position:absolute;font-size:0.7em; line-height:0px;top:-1px;padding-left:1px;">จำนวนออเดอร์:</span>
                                                                                <div  class="p-1 m-0 text-center text-dark h5 font-weight-bold bg-success" style="border-radius: 0.25rem;">
                                                                                          {{row.make_order}} {{row.PQUCOD}}
                                                                                </div>
                                                                                     </div>
                                                                                <div class="col-12">
                                                                                     <div class="row">
                                                                                <!-- ระดับสต็อก -->
                                                                                <div class="col-6 mb-0 p-0 pb-0 pl-3 pr-0 mt-0 pt-1" style="font-size:0.9em;">
                                                                                     สินค้าคงเหลือ <strong>{{row.PERCENT}}%</strong>
                                                                                </div>    
                                                                                <div class="col-6 mb-0 p-0 pb-0 pl-2 pr-3 mt-0 text-left pt-1" style="font-size:0.9em;">
                                                                           ขาย{{(row.order_frequency == 30) ? "เดือน" : (row.order_frequency == 7) ? "สัปดาห์" : "เดือน" }}ละ
                                                                                          : <strong>{{row.AVG_SELL}}</strong> {{row.PQUCOD}}
                                                                                </div>    
     
                                                                                     </div>
                                                                                </div>
                                                                           
                                                                           </div>
                                                                           </div>
                                                                           
                                                                      </div>
     
                                                                      </div>
                                                                 </div>
                                                                 
                                   </div>
                                   <!-- Card CheckStock Object -->
                                   <div v-if="tab_state==2" class="col-12 pl-2 pr-2" @click="openModal('modal_count_1', rowIndex)" style="margin-bottom:2px;">
                                                                 
                                   <div class="pr-1 pl-2 pt-1 pb-1 d-flex justify-content-between" style="border:1px solid;border-radius:0.25em;" 
                                                                           :class="{'mb-3' : (!groupType) ? false : (!stocks[ds.dsRows[index+1]]) ? false : (stocks[ds.dsRows[index+1]][groupType] != stocks[ds.dsRows[index]][groupType]) ? true : false, 'fadex2' : row.GROUP_COUNT && !row.TODAY_COUNT && tab_state==2,
                                                                           [row.BACKGROUND_CLASS] : true }"
                                                                           >
                                                                           <div class="flex-fill text-truncate pt" >
                                                                                <b-icon v-show="row.counted_at == new Date().toJSON().slice(0, 10)" icon="check-circle-fill" style="color:green;margin-right:3px;"></b-icon>
                                                                                <span style="font-size:1.1em;" :class="{'font-weight-bold' : rowIndex == modalResource}">{{row.STKDES}}</span>
                                                                           </div>
                                                                           <div class="d-flex align-item-end  justify-content-end" style="width:130px;">
                                                                           <div class="text-center font-weight-bold mr-1" style="width:60px;border-radius:0.25em;font-size:1.1em;" :class="{'bg-warning text-dark' : ((row.COUNT_ATOM-row.peter_ATOM) > 0), 'bg-success text-light ' : ((row.COUNT_ATOM-row.peter_ATOM) == 0), 'bg-danger text-light ' : ((row.COUNT_ATOM-row.peter_ATOM) < 0)}">
                                                                                {{ ((row.COUNT_ATOM-row.peter_ATOM) > 0) ? 'เกิน' : ((row.COUNT_ATOM-row.peter_ATOM) == 0) ? 'ตรง' : 'ขาด' }}
                                                                           </div>
                                                                           <div class="bg-light text-center  font-weight-bold " style="width:60px;border-radius:0.25em;font-size:1.1em;" :class="{'text-warning' : ((row.COUNT_ATOM-row.peter_ATOM) > 0), 'text-success' : ((row.COUNT_ATOM-row.peter_ATOM) == 0), 'text-danger ' : ((row.COUNT_ATOM-row.peter_ATOM) < 0)}">
                                                                                {{getValueFromAtom((row.COUNT_ATOM-row.peter_ATOM ),row.CFACTOR).CONCAT}}
                                                                           </div>
                                                                           </div>
                                   </div>
                                                                      
                                                                      
                                   </div>
     
                                   
     
                                                            
                                   </template>
     
                                        
                                             <template #noDataFound>
                                                  <div class="col-md-12 pt-2">
                                                  <p class="text-center">ไม่มีรายการสินค้า</p>
                                                  </div>
                                             </template>
                                   </dataset-item>
                                   </template>
                                   <!-- Compact View -->
                                   <template v-if="compactView">
                                   <dataset-item v-if="!imageDisplay" class="form-row mb-3">
                                   <template  #default="{ row,rowIndex,index }">
                                   <div class="col-12 pl-2" @click="openModal('modal_count_1', rowIndex)" style="margin-bottom:2px;">
                                                                 
                                        <div class="w-100 pr-2 pl-2 pt-1 pb-1 d-flex justify-content-between"  
                                             style="border:1px solid;border-radius:0.25em;" 
                                             :class="{'mb-3' : (!groupType) ? false : (!stocks[ds.dsRows[index+1]]) ? false : (stocks[ds.dsRows[index+1]][groupType] != stocks[ds.dsRows[index]][groupType]) ? true : false, 'fadex2' : row.GROUP_COUNT && !row.TODAY_COUNT && tab_state==2,[row.BACKGROUND_CLASS] : true }"
                                        >
                                             <div class="flex-fill text-truncate" style="width:80%;" >
                                                  <b-icon  icon="check-circle-fill" style="color:green;margin-right:3px;" 
                                                  v-show="row.counted_at == new Date().toJSON().slice(0, 10)"></b-icon>
                                                   <span style="font-size:1.1em;" :class="{'font-weight-bold' : rowIndex == modalResource}">
                                                       {{row.STKDES}} 
                                                  </span>
                                             </div>
                                             
                                             <div style="width:60px;border-radius:0.25em;font-size:1.1em;"
                                                  class="bg-danger text-center  font-weight-bold " 
                                                  :class="[''+getTextClass(row)]">
                                                       {{getValueFromAtom((row.ATOM_LOCBAL + row.express_DIFF),row.CFACTOR).BIG}}
                                             </div>
     
                                        </div>
                                                                 
                                                                 
                                                            </div>
                                                  </template>
                                                  <template #noDataFound>
                                                       <div class="col-md-12 pt-2">
                                                       <p class="text-center">ไม่มีรายการสินค้า</p>
                                                       </div>
                                                  </template>
                                             </dataset-item>
                                   </template>
                              </div>
     
                              <!-- Table Display -->
                              <div v-if="tableView" class="col-12 p-0">
                                   <!-- Table  Content -->
                                   <div class="row">
                                   <div class="col-md-12">
                                        <div class="table-responsive">
                                        <table class="table table-striped d-md-table table-bordered">
                                             <thead>
                                             <tr>
                                             <th scope="col" class="text-center" style="width:60px;">#</th>
                                             <th v-for="(th, index) in cols" :key="th.field" :class="['sort', th.sort]" :style="th.style"
                                                  class="text-center" @click="click($event, index)">
                                                  {{ th.name }} <i class="gg-select float-right"></i>
                                             </th>
                                             </tr>
                                             </thead>
                                             <dataset-item tag="tbody">
                                             <template #default="{ row, rowIndex }">
                                             <tr :class="{'d-none' : tricker_sss && row.PEOPLE == 'B00007'}">
                                                  <th scope="row" class="text-center">{{ rowIndex + 1 }}</th>
                                                  <td class="text-center">{{ row.STKCOD }}</td>
                                                  <td>
                                                  <div class="d-flex justify-content-between">
     
                                                       <div class="align-self-center" style="line-height:15px;border:none;">
                                                       {{ row.STKDES }} <br>
                                                       <span style="font-size:0.8em;color:#999">{{ row.SUPNAM_SUPPLIER }}</span>
                                                       </div>
                                                       <div class="d-flex" style="font-size:1.4em;">
                                             
                                                       <b-icon icon="bell-fill" :class="{'fadex' : !row.notify}"
                                                       style="cursor:pointer;margin:0;padding:0;margin-right:5px;" @click="stocks[rowIndex].notify = (stocks[rowIndex].notify) ? 0 :1"></b-icon>
                                                       <b-icon icon="house-door-fill" :class="{'fadex' : !row.is_bsts}"
                                                       style="cursor:pointer;margin:0;padding:0;margin-right:5px;" @click="stocks[rowIndex].is_bsts =(stocks[rowIndex].is_bsts) ? 0 :1"></b-icon>
                                                       <b-icon icon="lightning-fill" :class="{'fadex' : !row.is_watch}"
                                                       style="cursor:pointer;margin:0;padding:0;margin-right:5px;" @click="stocks[rowIndex].is_watch = (stocks[rowIndex].is_watch) ? 0 :1"></b-icon>
                                                       <div class="p-0 text-center font-weight-bold"
                                                       style="cursor:pointer;background:#ddd;color:#888;line-height:25px;font-size:0.7em;display:inline-block;width:35px;height:25px;border:1px solid #aaa;margin-right:5px;"
                                                       :class="{'text-danger' : (row.order_frequency == 7), 'text-warning' :  (row.order_frequency == -1)}" 
                                                       @click="stocks[rowIndex].order_frequency = (stocks[rowIndex].order_frequency == 30 ) ? 7 : (stocks[rowIndex].order_frequency == 7 ) ? -1 : 30">
                                                       {{ (row.order_frequency == -1) ? "ซ้อ" : row.order_frequency }}
                                                       </div>
     
                                                       </div>
                                                  </div>
                                                  
     
                                                  </td>
                                                  <td class="text-center p-0 pl-2 pr-2">
                                                  <div class="row pl-3 pr-3 pt-1">
                                                       <template v-if="(row.AVG_SELL< 0)">
                                                       <div class="col-12 pt-2 text-center text-warning">สินค้าเบิกซ้อ</div>
                                                       <div class="col-12 p-0 m-0"
                                                       style="font-size:0.8em;color:#aaa;text-align:left !important;padding-top:2px !important;">
                                                       ขายต่อ{{(row.order_frequency == 30) ? "เดือน" : (row.order_frequency == 7) ? "สัปดาห์" : "เดือน" }}
                                                       : {{row.AVG_SELL}} {{row.CQUCOD}}</div>
                                                       </template>
                                                       <template v-else-if="row.SEL_PER_MONTHX != null">
                                                       <div class="pr-0 text-left" :class="row.PERCENT_CLASS">{{row.PERCENT}}%
                                                       </div>
                                                       <div class="col-12 p-0 m-0"
                                                       style="font-size:0.8em;color:#aaa;text-align:left !important;padding-top:2px !important;">
                                                       ขายต่อ{{(row.order_frequency == 30) ? "เดือน" : (row.order_frequency == 7) ? "สัปดาห์" : "เดือน" }}
                                                       : {{row.AVG_SELL}} {{row.CQUCOD}}</div>
                                                       </template>
     
                                                       <template v-else>
                                                       <div class="col-12 pt-2 text-center" style="color:#aaa;font-style:italic">ไม่มีข้อมูล
                                                       </div>
                                                       </template>
                                                  </div>
     
                                                  </td>
                                                  <td class="text-center">{{ row.LOCBAL }}</td>
                                                  <td class="text-center">
                                                  <div v-if="row.LLK != null" class="d-flex flex-column" style="line-height:0px;">
                                                       <div style="padding-top:6px;">{{ dayAgo(row.LLK) }} </div>
                                                       <div class="pt-3" style="font-size:0.7em;color:#999">{{ date(row.LLK) }} [
                                                       {{row.TRNQTY_IN}} ]
                                                       </div>
                                                  </div>
                                                  <div v-else><span style="font-size:0.9em;color:#aaa;font-style:italic">ไม่มีข้อมูล</span>
                                                  </div>
                                                  </td>
                                                  <td class="text-center">
                                                  <div v-if="row.LSK != null" class="d-flex flex-column" style="line-height:0px;">
                                                       <div style="padding-top:6px;">{{ dayAgo(row.LSK) }} </div>
                                                       <div class="pt-3" style="font-size:0.7em;color:#999">{{ date(row.LSK) }} [
                                                       {{row.TRNQTY_OUT}}
                                                       ]</div>
                                                  </div>
                                                  <div v-else><span style="font-size:0.9em;color:#aaa;font-style:italic">ไม่มีข้อมูล</span>
                                                  </div>
                                                  </td>
                                                  <td class="text-center">
                                                  <div v-if="row.LSS != null" class="d-flex flex-column" style="line-height:0px;">
                                                       <div style="padding-top:6px;">{{ dayAgo(row.LSS) }} </div>
                                                       <div class="pt-3" style="font-size:0.7em;color:#999">{{ date(row.LSS) }} [
                                                       {{row.TRNQTY_SSS}}
                                                       ]</div>
                                                  </div>
                                                  <div v-else><span style="font-size:0.9em;color:#aaa;font-style:italic">ไม่มีข้อมูล</span>
                                                  </div>
                                                  </td>
                                             </tr>
                                             </template>
                                             </dataset-item>
                                        </table>
                                        </div>
                                   </div>
                                   </div>
                              </div>
                              
                              <!-- Panel -->
                              <div v-show="false" class="row mb-2" :data-page-count="ds.dsPagecount">
                                        <div class="col-md-6 mb-2 mb-md-0">
                                             <dataset-show ref="entries_input"
                                             :ds-show-entries="entries" 
                                             :ds-show-entries-lovs="[{ value: 5, text: 5 }, { value: 10, text: 10 }, { value: 30, text: 30 }, { value: 50, text: 50 }, { value: 100, text: 100 },{ value: 200, text: 200 }]"
                                             />
                                        </div>
                                        <div class="col-md-6">
                                             <dataset-search ds-search-placeholder="Search..." />
                                        </div>
                              </div>
                              <!-- Pagination -->
                              <div class="d-flex flex-md-row flex-column justify-content-between align-items-center mb-4 mt-0">
                                   <dataset-info v-show="false" class="mb-2 mb-md-0" /><dataset-pager />
                              </div>
                              
                         </dataset>
                    </div>
               </div>
     
          </div>
     
     </div>
     </template>
     
     
     <script>
     import moment from 'moment'
     import Dataset from 'vue-dataset/dist/es/Dataset.js'
     import DatasetItem from 'vue-dataset/dist/es/DatasetItem.js'
     import DatasetInfo from 'vue-dataset/dist/es/DatasetInfo.js'
     import DatasetPager from 'vue-dataset/dist/es/DatasetPager.js'
     import DatasetSearch from 'vue-dataset/dist/es/DatasetSearch.js'
     import DatasetShow from 'vue-dataset/dist/es/DatasetShow.js'
     import StockService from "../StockService"
     import VueBarcode from 'vue-barcode'
     import vClickOutside from 'v-click-outside'
     //import _ from 'lodash';
     import domtoimage from 'dom-to-image';
     import VueHtml2pdf from 'vue-html2pdf';
     
     export default {
          name: 'PeterStockManagement',
          sockets: {
               async connect() {
                    this.onLine = true;
                    //this.$socket.client.emit('checkLoading',true); 
                    console.log('Socket connected')
               },
               disconnect(){   
                    //this.onLine = false;
                    //this.stocks = localStorage.stocks
                    console.log('Socket disconnect')
               }
          },
          directives: { clickOutside: vClickOutside.directive },
          components: { Dataset,DatasetItem,DatasetInfo,DatasetPager,DatasetSearch,DatasetShow,VueBarcode,VueHtml2pdf },
          data: function () {
               return {
                    maxPerPage:5,
                    currentPage:1,
                    soCusDetail:false,
                    suggestGroup:null,
                    sameGroup:null,
                    groupFilter:null,
                    groupField:null,
                    groupType:'count_code',
                    dayText: {1:"จ",2:"อ",3:"พ",4:"พฤ",5:"ศ",6:"ส"},
                    today: new Date().toJSON().slice(0, 10),
                    tableView:false,
                    compactView:false,
                    subMenu_today_delivery_details:false,
                    genPDF:false,
                    reportNum : 1,
                    heaSor:0,
                    openImageModal:false,
                    imageDisplay:false,
                    entries : 50,
                    stream : null,
                    weekdayState:false,
                    imageState : false,
                    readyState : false,
                    makeOrderTrigger:false,
                    orderTrigger:false,
                    productTab:0,
                    fileName: "กรุณารอซักครู่...",
                    stocksHistoryTrigger:true,
                    stocksHistory:null,
                    scTimer: 0,
                    scY: 0,
                    onLine : false,  //false
                    loadingClass : 0,
                    mainLoading : true,
                    searchSwitchMode: false,                // เปิด Keyboard เป็น Text หรือ Numberic
                    modalResource: null,                        // ไว้สำหรับรับค่าว่าจะเปิด Modal จาก rowIndex ไหน 
                    countScan: 0,                                    // นับว่า Scan ไปกี่ครั้งแล้ว (ตอนเพิ่มบาร์โค๊ด)
                    addBarcodeTrigger: false,               // Trigger ว่าเข้าโหมดจัดการ Barcode หรือไม่
                    barcodeStateTrigger: null,               // ไว้สำหรับ Trigger ว่าเมื่อ ScanBarcode แล้วเป็นการ Save หรือเปิด Modal
                    uniqueCodeStateTrigger: null,        //  ไว้สำหรับ Trigger ว่าเมื่อ UniqueCode แล้วเป็นการ Save หรือเปิด Modal
                    currentBarcode: null,                       // รับค่า Barcode ที่ยิงตอนใส่ Barccode ให้กับสินค้า
                    barCode_key: null,                           // สำหรับ Test
                    delCountTrigger:false,
                    delPalTrigger : null,
                    legEditTrigger : false,
                    delSmallPalTrigger : null,
                    calculator: { 'cal': [{'leg': 0, 'floor': 0, 'pal': 0}], 'cal_': [{'big': 0, 'small': 0}],'CHECK_STAT' : 0 },
                    calculator_last: { 'cal': [{'leg': 0, 'floor': 0, 'pal': 0}], 'cal_': [{'big': 0, 'small': 0}],'CHECK_STAT' : 0 },
                    subMenuSeq:null,
                    filters :{ 
                         hideFilter : '',
                         countexpiredFilter:'',
                         countdayFilter:'',
                         ispoFilter:'',
                         todaycountFilter:'',
                         makeOrderFilter:'',
                         neverSellFilter:'',
                         refreshFilter: '',
                         is_watchFilter: '',
                         is_bstsFilter: '',
                         percentFilter: '',
                         notiFilter: '',
                         countFilter: '',
                         notCountFilter: '',
                         groupcountFilter:'',
                         soCountFilter:'',
                    },
                    order_frequency_options: [
                         { item: 7, name: 'สั่งทุกสัปดาห์' },
                         { item: 30, name: 'สั่งทุกเดือน' }
                    ],
                    count_stock_tricker: false,              // สำหรับ Tricker การนับสินค้า
                    tab_state : 0,
                    tab_tricker: 2,                                    // สำหรับเปลี่ยน Tab
                    tricker_sss: false,
                    products_config: null,
                    products_count_stocks: null,
                    products_count_history: null,
                    stocks: [],
                    error: "",
                    toset: "desc",
                    num: 0,
                    cols: [{
                         name: 'หมวด',
                         field: 'STKGRP',
                         sort: '',
                         style: {
                         width: '100px',
                         display:'none'
                         }
                    },
                    {
                         name: 'รหัสสินค้า',
                         field: 'STKCOD',
                         sort: '',
                         style: {
                         width: '120px'
                         }
                    },
                    {
                         name: 'ชื่อสินค้า',
                         field: 'STKDES',
                         sort: ''
                    },
                    {
                         name: 'ระดับสต็อค',
                         field: 'PERCENT',
                         sort: '',
                         style: {
                         width: '180px'
                         },
                    },
                    {
                         name: 'สต็อก',
                         field: 'LOCBAL',
                         sort: '',
                         style: {
                         width: '140px'
                         }
                    },
                    {
                         name: 'ของเข้าล่าสุด',
                         field: 'LLK',
                         sort: '',
                         style: {
                         width: '140px'
                         },
                    },
                    {
                         name: 'ขายล่าสุด',
                         field: 'LSK',
                         sort: '',
                         style: {
                         width: '140px'
                         }
                    },
                    {
                         name: 'ซ้อเบิกล่าสุด',
                         field: 'LSS',
                         sort: '',
                         style: {
                         width: '140px'
                         }
                    },
                    {
                         name: 'สั่ง',
                         field: 'ORDERED',
                         sort: '',
                         style: {
                         display:'none',
                         width: '140px'
                         }
                    },
                    {
                         name: 'SUPNAM',
                         field: 'SUPNAM',
                         sort: '',
                         style: {
                         display:'none',
                         width: '140px'
                         }
                    },
                    {
                         name: 'SUPPLIER10',
                         field: 'SUPPLIER',
                         sort: '',
                         style: {
                         display:'none',
                         width: '140px'
                         }
                    },
                    {
                         name: 'ขายต่อวัน',
                         field: 'SELL_PER_DAY_BIG',
                         sort: '',
                         style: {
                         display:'none',
                         width: '140px'
                         }
                    },
                    {
                         name: 'is_po',
                         field: 'is_po',
                         sort: '',
                         style: {
                         display:'none',
                         width: '140px'
                         }
                    }, 
                    {
                         name: 'WARNING',
                         field: 'WARNING',
                         sort: '',
                         style: {
                         display:'none',
                         width: '140px'
                         }
                    },
                    {
                         name: 'LASTEST_PODAT',
                         field: 'LASTEST_PODAT',
                         sort: '',
                         style: {
                         display:'none',
                         width: '140px'
                         }
                    },
                     {
                         name: 'count_code',
                         field: 'count_code',
                         sort: '',
                         style: {
                         display:'none',
                         width: '140px'
                         }
                    },
                     {
                         name: 'total_so_price',
                         field: 'TOTAL_SO_PRICE',
                         sort: '',
                         style: {
                         display:'none',
                         width: '140px'
                         }
                    },
                    {
                         name: 'total_so_tranval',
                         field: 'TOTAL_SO_TRNVAL',
                         sort: '',
                         style: {
                         display:'none',
                         width: '140px'
                         }
                    }
                    ],
                    calWidth:0,
                    showScrollTop:false,
               }
          },
          async created() {
               
               this.loadingClass = 12;
               this.fileName = "โหลดโปรแกรม"
     
               let redis_check = await StockService.refreshStocks();
               if(redis_check){
                    this.onLine = true; // หลอกไว้เผื่อ DBF Server ช้า
                    this.stocks = redis_check;
                    this.mainLoading = false;
               }else this.$socket.client.emit('loadingClassCheck') 
               
     
               moment.locale('th');
               this.$barcodeScanner.init(this.onBarcodeScanned)
               document.addEventListener('focusin',this.focusChanged)
               //this.save = _.debounce(this.save,500);
               
          },
          beforeUpdate(){
              // console.log("Process 777")
          },
          updated(){
               this.readyState = true;
               //document.body.requestFullscreen();
              
               //window.document.documentElement.webkitRequestFullScreen();
              console.log("Done 777")
          },
          computed: {
               isThreeWeight() {
                    const stock = this.stocks[this.modalResource];
                    const weights = [stock.weight_1, stock.weight_2, stock.weight_3];
                    return weights.every(weight => weight !== null && weight !== '' && weight !== 0&& weight !== '0');
               },
               avgWeight() {
         const stock = this.stocks[this.modalResource];
         const weights = [stock.weight_1, stock.weight_2, stock.weight_3];
         
         // Check if any weight is null, "", "0", or 0
         if (weights.some(weight => weight === null || weight === '' || weight === '0' || weight === 0)) {
             return false;
         }
         
         const validWeights = weights.filter(weight => weight !== null && weight !== '' && weight !== 0 && weight !== '0');
         const totalWeight = validWeights.reduce((sum, weight) => sum + parseFloat(weight), 0);
         return validWeights.length ? parseFloat(totalWeight / validWeights.length) : 0.0;
     },
               today_sells(){
                    return this.stocks.reduce((total,value)=>{
                         if(value){
                              let today_sell_atom_qty =  (value.TODAY_SELL_ATOM_QTY + (value.ATOM_ORDQTY-value.ATOM_ORDQTY_DONE));
                               total.atom_qty +=  today_sell_atom_qty;
                               total.volumn += today_sell_atom_qty * (value.SELLPR1 / value.CFACTOR);
                               total.cost += today_sell_atom_qty * (value.LAST_BUY_PRICE / value.CFACTOR);
                         }
                         return total;
                    },{atom_qty:0,volumn:0,cost:0})
               },
               random_stocks(){
                     return this.stocks.filter(e=> e.is_so).sort((a,b)=>b.TOTAL_SO_TRNVAL - a.TOTAL_SO_TRNVAL).filter((f,i)=>i<30)
               },
               random_stocks_diff_value(){
                    return this.random_stocks.reduce((total,value)=>{
                                        return total + (value.counted_at_last == new Date().toJSON().slice(0, 10) && value.counted_at == new Date().toJSON().slice(0, 10)) ? ((value.COUNT_ATOM_LAST - (value.COUNT_ATOM-value.ATOM_ORDQTY)) * (value.LSELLPR/value.CFACTOR)) : 0;
                              },0)
               },
               random_stocks_trucks(){
                    let trucks_info = {};
                    this.stocks.filter(e=> e.is_so).map(e=>{ 
                         e.SOCUS.map(h=>{
                              (!trucks_info[h.DLVBY]) && (trucks_info[h.DLVBY] = { cus_name : [h.NAME], total_value:0 });
                              (!trucks_info[h.DLVBY].cus_name.includes(h.NAME)) && trucks_info[h.DLVBY].cus_name.push(h.NAME)
                               trucks_info[h.DLVBY].total_value+= h.TRNVAL 
                         })
                    })
                    //trucks_info.sort((a,b)=>(Object.keys(b)[0].toLowerCase() > Object.keys(a)[0].toLowerCase()));
                    // Maximum แค่ 4 คัน
                    let newObj = Object.fromEntries(Object.entries(trucks_info).filter((a,b)=>b<4));
                    return newObj
               },
               groupStocks(){
                    return (this.groupField && this.groupFilter) ? this.stocks.filter(item=>item[this.groupField]==this.groupFilter && item.hide==0) : null;
               },
                // สินค้าใกล้หมด ที่ยังไม่เปิด PO เรียงตามปริมาณขาย มากไปน้อย
               stocks_warning(){
                    return this.stocks.filter(item => item.WARNING == 1 && item.is_po == 0 && item.notify == 1 && item.hide==0).sort(function(a, b){return b.SELL_PER_DAY_BIG - a.SELL_PER_DAY_BIG});
               },
                // สินค้าที่นับวันนี้
               stocks_today_counted(){
                    return this.stocks.filter(item => item.counted_at ==  new Date().toJSON().slice(0, 10));
               },
               // สินค้าที่นับวันนี้
               stocks_today_in(){
                    return  this.stocks.filter(item => (item.LLK)&&(moment(item.LLK).format("YYYY-MM-DD") ==  new Date().toJSON().slice(0, 10)));
               },
               // สินค้าที่นับวันนี้
               stocks_today_in_isPo(){
                    return  this.stocks.filter(item => (item.LLK)&&(moment(item.LLK).format("YYYY-MM-DD") ==  new Date().toJSON().slice(0, 10))&&(item.is_po==1));
               },
               // สินค้าใกล้หมดที่นับวันนี้
               stocks_warning_today_counted(){
                    return this.stocks.filter(item => item.WARNING == 1 && item.counted_at ==  new Date().toJSON().slice(0, 10));
               },
               // สินค้าxxx
               stocks_schedule_count(){
                    return this.stocks.filter(e => (e.count_day == new Date().getDay()) && e.counted_at == this.today).length
               },
                // สินค้าที่นับวันนี้ และไม่ตรง เรียงจากขาดมากไปน้อย
               stocks_today_counted_peterDiff(){
                    let result = this.stocks.filter(item => item.counted_at ==  new Date().toJSON().slice(0, 10) && item.peter_DIFF != 0);
                    result.sort(function(a, b){return a.peter_DIFF - b.peter_DIFF});
                    return result
               },
               // สินค้าที่เปิด PO วันนี้
                stocks_today_po(){
                    return this.stocks.filter(item => item.LASTEST_PODAT == new Date().toJSON().slice(0, 10));
               },
               // สินค้าใกล้หมดที่เปิด PO วันนี้ 
               stocks_warning_today_po(){
                    return this.stocks.filter(item => item.WARNING == 1 && item.LASTEST_PODAT == new Date().toJSON().slice(0, 10));
               },
               // สินค้าค้างรับทั้งหมด รวม PO วันนี้ เรียงตาม LASTEST_PODAT ที่นานที่สุด  
               stocks_is_po(){
                    let result = this.stocks.filter(item => item.is_po == 1); 
                    result.sort(function(a, b){
                         let aa = new Date(a.LASTEST_PODAT).getTime();
                         let bb = new Date(b.LASTEST_PODAT).getTime();
                         return aa - bb
                    });
                    return result
               },
               // สินค้าทั้งหมดไม่นับรวมที่ ซ่อน (hide == 0) 
               stocks_no_hide(){
                    return this.stocks.filter((e) => e.hide == 0);
               },
     
               
               lessStock_count(){
                    return this.stocks.filter((e) => e.WARNING == 1 && e.notify==1 && e.hide==0 && e.is_po==0 && e.counted_at != new Date().toJSON().slice(0, 10)).length
               },
               mushCount_count(){
                    return this.stocks.filter(e => (e.count_day == new Date().getDay()) && e.counted_at != this.today).length
               },
               makeOrderFilter_count(){
                    return this.stocks.filter((e) => e.ordered_at == new Date().toJSON().slice(0, 10) ).length
               },
               isPo_count(){
                    return this.stocks.filter((e) => e.is_po ==1 ).length
               },
               countFilter_count(){
                    return this.stocks.filter((e) => e.counted_at == new Date().toJSON().slice(0, 10) ).length
               },
               notCountFilter_count(){
                    return this.stocks.filter((e) => e.user == null ).length
               },
               sortBy() {
                    return this.cols.reduce((acc, o) => {
                         if(o.sort) o.sort === 'asc' ? acc.push(o.field) : acc.push('-' + o.field)
                         return acc
                    }, [])
               }
          },
         mounted() {
     
               // รับว่า LoadingClass เป็นเท่าไหร่แล้ว
               this.$socket.client.on('loadingClass', (res)=>{ this.loadingClass = res });
               // รับว่าโหลด File ไหนอยู่
               this.$socket.client.on('fileName',(res)=>{ this.fileName = res });
               // Refresh
               this.$socket.client.on('refresh', async (res)=>{ 
                    if(res){
                         this.loadingClass = 12;
                         this.fileName = "โหลดโปรแกรม"
                         try { this.stocks = await StockService.refreshStocks(); this.mainLoading = false;} catch (err) { console.log("Refresh Error",err) }
                    }else this.mainLoading = true;
               });
               // Refresh Soft
               this.$socket.client.on('refreshSoft', async (result)=>{ 
                    console.log("on refreshSoft");
                    (result) && Object.assign(this.stocks[result.index], result.data) && (this.mainLoading = false)
               });
         },
          methods: {
               start_item(){
                    let start = (this.currentPage === 1) ? 0 : ((this.currentPage-1)*this.maxPerPage);
                    return  start
               },
               end_item(){
                    let end = (this.currentPage === 1) ? this.maxPerPage-1 : (this.currentPage*this.maxPerPage) - 1;
                     return  end
               },
               // Check if counted_at == today date
               is_today_count(i){
                    return (this.stocks[i].counted_at == new Date().toJSON().slice(0, 10)) ? true : false;
               },
               // ถ้ามีการเป็นแปลง  STAT หลังจากการนับสินค้า
               is_count_stat_change(i){
                    return ((this.stocks[i].STAT != null && this.stocks[i].COUNT_STAT != null) && (this.stocks[i].STAT != this.stocks[i].COUNT_STAT)) ? true : false;
               },
               get_last_count_stock_history(i){
                    let is_today_count = this.stocks[i].history?.filter(e=>e.is_adjust==false)
                    return is_today_count[is_today_count.length-1]
               },
               is_has_today_transaction(i){
                    // เช็คว่ามี Transcation วันนี้หรือไม่
                    let is_has_today_transaction = this.stocks[i].history?.find(e=>e.counted_at == new Date().toJSON().slice(0, 10))
                    return (!is_has_today_transaction) ? false : true;
               },
               is_so_ready(i){
                    return (this.stocks[i].STAT == 'N') ? false : (this.stocks[i].STAT == 'M') ? true : true;
               },
               createSameGroup(index){
                    this.sameGroup =  this.stocks.filter(item => item.SUPNAM_SUPPLIER==this.stocks[index].SUPNAM_SUPPLIER && item.hide==0 && item.count_code && item.STKCOD != this.stocks[index].STKCOD);
                    let count_code_used = this.stocks.filter(item => item.count_code).map(item=>parseInt(item.count_code));
                    count_code_used = [...new Set(count_code_used)];
                    let max_used = Math.max(...count_code_used) +1
                    let array_ = [];
                    for(let i=1;i<=max_used;i++){ array_.push(i) }
                    let result = array_.filter(item=>!count_code_used.includes(item))
                    this.suggestGroup = Math.min(...result)
               },
               applyFilters(arg=''){
                    let keyArg = Object.keys(arg);
                    Object.keys(this.filters).map((key) => {
                         if(keyArg.includes(key)){this.filters[key] = arg[key]; }
                         else this.filters[key] = '';
                    });
                    this.clearText();
               },
               
                // สินค้าประเภทน้ำมัน
               stocks_by_STKGRP(STKGRP_){
                    let arr_GRP = ["230A","110A","220D","230C","220B","210B","220C"];
                    // หาสินค้าประเภทน้ำมัน
                    let oil2 = this.stocks.filter(e=>e.STKGRP == STKGRP_ && e.hide == 0 && e.is_bsts == 1 && e.notify == 1)
                    // หาสินค้าที่อยู่บริษัทเดียวกับน้ำมัน
     
                    let also_oil2 = this.stocks.filter(item=>(oil2.find(e=>((e.SUPNAM_SUPPLIER == item.SUPNAM_SUPPLIER) && item.is_bsts == 1 && item.notify==1 && (e.SUPNAM_SUPPLIER != "ไม่มีข้อมูลจากประวัติ") && !arr_GRP.includes(item.STKGRP) ) )))
                    let oil = [...new Set([...oil2,...also_oil2])];
                    let supplier = {};
                         oil.map((item)=>{
                                    if(item.SUPNAM_SUPPLIER == null){
                                         item.SUPNAM_SUPPLIER = (item.SUPNAM != null) ? item.SUPNAM : "ไม่มีข้อมูล"; 
                                    }
                                        (!supplier[item.SUPNAM_SUPPLIER]) && (supplier[item.SUPNAM_SUPPLIER] = [])
                                        supplier[item.SUPNAM_SUPPLIER].push(item);
                         }) 
                        
                    let length = [];
                    let i = 1;
                        for(let item in supplier){
                             let a = {"key":item,"length" : supplier[item].length}
                             length.push(a);
                                    
                             supplier[item].sort(function(a, b){return a.STKCOD.localeCompare(b.STKCOD)});
                             supplier[item] = supplier[item].map(item=>{item.seq = i; i++; return item})
                        }
     
                    let value = {}
                         length.map(item=>{
                              value[item.key] = supplier[item.key]
                         })
                    return value;
               },
               genPDFProgress(e){
                    this.genPDF = (e<100) ? true : false;
               },
               generateReport(report=1) {
                 if(report == 1) this.$refs.Report1.generatePdf()
                 if(report == 2) this.$refs.Report2.generatePdf()
                 if(report == 3) this.$refs.Report3.generatePdf()
             },
               async makeImg(){
                    await this.openModal('modal_image')
                    this.imageDisplay = true;
                    let img = new Image();
                    let dom = this.$refs.domImg;
                    await domtoimage.toPng(dom,{bgcolor: "white"}).then(function (dataUrl) {
                         img.src = dataUrl;
                         img.width = "300";
                    })
                    .catch(function (error) {
                         console.error('oops, something went wrong!', error);
                    });
                    this.imageDisplay = false;
                    
                    this.$refs.imgContainer.appendChild(img);
              },
              async makeImg2(){
                    this.imageDisplay = true;
                     let img = new Image();
                    let dom = this.$refs.domImg2;
                    let scale = 2;
                    await domtoimage.toJpeg(dom,{
                         bgcolor: "white",
                         height: dom.offsetHeight * scale,
                         width: dom.offsetWidth * scale,
                         style: {
                              transform: "scale(" + scale + ")",
                              transformOrigin: "top left",
                              width: dom.offsetWidth + "px",
                              height: dom.offsetHeight + "px"
                         }
                    }).then(function (dataUrl) {
                         img.src = dataUrl;
                         img.width = dom.offsetWidth;
                         
                          
                    })
                    .catch(function (error) {
                         console.error('oops, something went wrong!', error);
                    });
                    this.$refs.imgContainer2.appendChild(img);
                    var ua = navigator.userAgent.toLowerCase();
                    var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
                    if(isAndroid) {
                         let link = window.document.createElement('a');
                          link.download = Date.now()+'.jpeg';
                          link.href = img.src;
                          link.click();
                    }
                    
                    this.imageDisplay = false;
                    
              },
              openOrder(){
                   this.orderTrigger = (this.orderTrigger) ? false : true; 
                   setTimeout(()=>{this.$refs.modal_count_1.$refs.modal.scrollTop = this.$refs.modal_count_1.$refs.modal.scrollHeight},50);  
                   this.log(this.$refs.modal_count_1.$refs.modal.scrollTop)
              },
               getValueFromAtom(atom,cfactor){
                    let big  = Math.floor(Math.abs(atom) / cfactor)
                    big =(atom<0) ? big*-1 : big;
                    let small = Math.abs(atom)%cfactor;
                    small =(atom < 0) ? small*-1 : small;
                    let start = (atom > 0) ? "+" : (atom < 0) ? "-" : "";
                    let concat = start+Math.abs(big)+"."+Math.abs(small);
                    return {"BIG":big,"SMALL":small,"CONCAT" : concat, "CONCAT_NO_PLUS" : big+"."+small}
               },
               getThisAtom(i){
                    let big  = Math.floor(Math.abs(this.stocks[i].ATOM_LOCBAL) / this.stocks[i].CFACTOR)
                    big =(this.stocks[i].ATOM_LOCBAL<0) ? big*-1 : big;
                    let small = Math.abs(this.stocks[i].ATOM_LOCBAL)%this.stocks[i].CFACTOR;
                    small =(this.stocks[i].ATOM_LOCBAL < 0) ? small*-1 : small;
                    let start = (this.stocks[i].ATOM_LOCBAL > 0) ? "+" : (this.stocks[i].ATOM_LOCBAL < 0) ? "-" : "";
                    let concat = start+Math.abs(big)+"."+Math.abs(small);
                    return {"BIG":big,"SMALL":small,"CONCAT" : concat, "CONCAT_NO_PLUS" : big+"."+small}
               },
               getCountStockClass(index){
                    let result;
                    if(this.stocks[index].peter_DIFF == 0) result  = "bg-success"
                    else if(this.stocks[index].peter_DIFF < 0) result = "bg-danger"
                    else if(this.stocks[index].peter_DIFF > 0) result = "bg-warning"
                    return result;
               },
               getCountStockResult(index){
                    let result;
                    if(this.stocks[index].peter_DIFF == 0) result = "ตรง"
                    else if(this.stocks[index].peter_DIFF < 0) result = "ขาด"
                    else if(this.stocks[index].peter_DIFF > 0) result = "เกิน" 
                    return result;
               },
               clearText(){
                    this.$refs.search_input.$el.value = "";
                    this.$refs.search_input.$el.dispatchEvent(new Event('input'));
     
                    setTimeout(()=>{
                          // Default To 1 Page
                         let aa = this.$el.getElementsByClassName('pagination')
                         let bb = aa[0].children[1].children[0];
                         bb.dispatchEvent(new Event('click'));
                    },1)
                   
     
               },
               refreshEntries(entries){
                    this.$refs.entries_input.$el.children[1].value = entries
                    this.$refs.entries_input.$el.children[1].dispatchEvent(new Event('change'));
               },
               focusChanged (event) {
                    let el = event.target
                    if(el.classList.contains('search-input')) el.select();
               },
               addUniqueCode(index){
                    // เปลี่ยน State เป็นโหมด Add Barcode รับค่า Index ว่าเป็น Barcode ของสินค้าไหน
                    this.uniqueCodeStateTrigger = index;
               },
               addBarcode(index){
                    // เปลี่ยน State เป็นโหมด Add Barcode รับค่า Index ว่าเป็น Barcode ของสินค้าไหน
                    this.barcodeStateTrigger = index;
               },
               async closeAllModal(){
                    this.$bvModal.hide('modal_select_group_1');
                    this.$bvModal.hide('modal_weight');
                    this.$bvModal.hide('modal_count_1');
                    this.$bvModal.hide('modal_count_2');
                    this.$bvModal.hide('modal_multi_1');
                    this.$bvModal.hide('modal_image');
               },
               async openModalFind(modalName,STKCOD){
                    const bySTKCOD = (element) => element.STKCOD == STKCOD;
                    this.modalResource = this.stocks.findIndex(bySTKCOD);
                    this.$bvModal.show(modalName);
               },
               async openGroupModal(modalName,field,filter){
                    this.imageDisplay = true;
                    this.groupFilter = filter;
                    this.groupField = field;
                    //await this.genGroupStocks(field,filter);
                    await this.$bvModal.show(modalName);
               },
               
               async openModal(modalName,index){
                    this.modalResource = index;
                    this.$bvModal.show(modalName);
               },
               closeModal(modalName){
                    this.$bvModal.hide(modalName);
               },
               async findBarcode(barcode){
                    let result = this.stocks.findIndex(o => o.barcode == barcode);
                    if(result+1){
                         await this.closeAllModal();
                         this.openModal('modal_count_1',result);
                    }
               },
               async onBarcodeScanned(barcode) {
                    this.barCode_key = barcode;
                    if(this.barcodeStateTrigger == null){this.findBarcode(barcode); return}
                    // ตรวจดูว่า Barcode นี้เคยใช้แล้วหรือไม่?
                    // ถ้า Barcode ซ้ำให้ Return
                    if(this.stocks.findIndex(o => o.barcode == barcode) != -1){this.barcodeStateTrigger= null;this.addBarcodeTrigger=false;return}
                    // Scan ครั้งแรก
                    if(this.countScan == 0){this.currentBarcode = barcode; this.countScan++; return}
                    // Scan ครั้งที่สอง
                    if((this.currentBarcode = barcode) && (this.countScan < 3 )){this.countScan++;}
                    // Scan ครั้งที่สาม
                    if((this.currentBarcode = barcode) && (this.countScan == 3 )){
                         // สำหรับใส่ค่าใน Stocks ให้ Update Realtime เพราะ Barcode ไม่ได้ใช้ V-Model
                         this.stocks[this.barcodeStateTrigger].barcode = barcode;
                         await this.save(this.barcodeStateTrigger)
                         this.barcodeStateTrigger = null;
                         this.currentBarcode = null;
                         this.countScan = 0;
                         this.addBarcodeTrigger=false;
                    }
                    // ถ้าภายใน 3 ครั้งที่ Scan Barcode ไม่เหมือนกันให้เริ่มใหม่ 
                    if(this.currentBarcode != barcode){ this.barcodeStateTrigger= null;this.addBarcodeTrigger=false;this.countScan = 0;return }
               },
               // Reset to the last barcode before hitting enter (whatever anything in the input box)
               resetBarcode () {
                    let barcode = this.$barcodeScanner.getPreviousCode()
                    console.log("Reset",barcode)
                    // do something...
               },
               // หาส่วนลดเพื่อไปคำนวนตั้นทุน
               processDiscount(price,disc){
                    let a = disc;
                    a = 0
                    // ถ้าไม่มี Disc หรือ เป็น null ให้ Return Price เลย
                    /* if(!disc) return price;
                    // ถ้ามี Disc ให้เอา % ออกและ Return ราคาหลังหักส่วนลด
                    disc = disc.replace("%", "");
                    if(!disc.includes("+")) return price * ((100-eval(disc))/100);
                    // ถ้ามี Disc และมี + ให้วนลูปลดเป็น Step
                    let a = disc.split("+");
                    for(let n of a){
                         price = price * ((100-eval(n))/100);
                    } */
                    return price+a;
               },
               // ตัวตรวจว่านับสต็อกไปรึยัง?
               checkCountStock(index){
                    return (this.stocks[index]['counted_at'] != null) ? true : false;
               },
               calReset(last=false){
                    if(!last){
                         this.calculator.cal = [{'leg':0,'floor':0,'pal':0}];
                         this.calculator.cal_ = [{'big':0,'small':0}];
                         this.calculator.CHECK_STAT = 0;
                    }else{
                         this.calculator_last.cal = [{'leg':0,'floor':0,'pal':0}];
                         this.calculator_last.cal_ = [{'big':0,'small':0}];
                         this.calculator_last.CHECK_STAT = 0;
                    }
               },
               addSmallPal(last=false){
                    if(!last)
                         this.calculator.cal_.push({'big':0,'small':0});
                    else
                         this.calculator_last.cal_.push({'big':0,'small':0});
               },
               addPal(leg=null,last=false){
                    if(!last){
                         if(leg != null){
                              if(this.calculator.cal.length == 1 && this.calculator.cal[0].leg == 0) this.calculator.cal[0].leg = leg;
                              else this.calculator.cal.push({'leg':(leg==null)?0:leg,'floor':0,'pal':0});
                         }else{
                              this.calculator.cal.push({'leg':(leg==null) ? 0: leg,'floor':0,'pal':0});
                         }
                    }else{
                         if(leg != null){
                              if(this.calculator_last.cal.length == 1 && this.calculator_last.cal[0].leg == 0) this.calculator_last.cal[0].leg = leg;
                              else this.calculator_last.cal.push({'leg':(leg==null)?0:leg,'floor':0,'pal':0});
                         }else{
                              this.calculator_last.cal.push({'leg':(leg==null) ? 0: leg,'floor':0,'pal':0});
                         }
                    }
               },
               delSmallPal(index,last=false){
                    if(!last){
                         if((this.calculator.cal_.length > 1)) this.calculator.cal_.splice(index,1);
                         this.delSmallPalTrigger = null;
                    }else{
                          if((this.calculator_last.cal_.length > 1)) this.calculator_last.cal_.splice(index,1);
                         this.delSmallPalTrigger = null;
                    }
               },
               resetDelPal(){
                    this.delPalTrigger = null
               },
               resetLegEdit(){
                    this.legEditTrigger = false;
               },
               resetDelSmallPal(){
                    this.delSmallPalTrigger = null;
               },
               log(x){
                    console.log(x)
               },
               delPal(index,last=false){
                     if(!last){
                           if(this.calculator.cal.length > 1) this.calculator.cal.splice(index,1);
                         this.delPalTrigger = null;
                     }else{
                           if(this.calculator_last.cal.length > 1) this.calculator_last.cal.splice(index,1);
                         this.delPalTrigger = null;
                     }
                   
               },
               async save_make_order(index){
                    this.makeOrderTrigger = true;
                    this.stocks[index].makeOrderTrigger = true;
                    let params = {
                         "STKCOD": this.stocks[index].STKCOD,
                         "make_order": parseInt(this.stocks[index].make_order),
                         "ordered_at": new Date().toJSON().slice(0, 10)
                    };
                    try {
                         let result = await StockService.saveMakeOrder(index,params);
                         (result) && Object.assign(this.stocks[index], result) && this.$socket.client.emit('refreshSoft',{"index":index,"data":result});
                         this.stocks[index].makeOrderTrigger = false;
                         this.makeOrderTrigger = false;
                    }catch(err){console.log("Save Make Order Error", err)}
               },
               async del_make_order(index){
                    this.makeOrderTrigger = true;
                    this.stocks[index].makeOrderTrigger = true;
                    let params = {
                         "STKCOD": this.stocks[index].STKCOD,
                         "make_order" : 0,
                         "ordered_at": null
                    };
                    try{
                         let result = await StockService.deleteMakeOrder(index,params);
                         (result) && Object.assign(this.stocks[index],result)&&this.$socket.client.emit('refreshSoft',{"index":index,"data":result});
                         this.stocks[index].makeOrderTrigger = false;
                         this.makeOrderTrigger = false;
                    }catch(err){ console.log("Delete _products_count_stock  Error", err) }
               },
               async del_count_stock(index){
                    this.stocks[index].stocksHistoryTrigger = false;
                    this.stocksHistoryTrigger = false;
                    // ต้องส่ง Parameter ไปทั้งหมดเพื่อให้ Function ใน StockService วนลูป File มั้งหมดได้ และทำการ Set Null
                    let params = {
                         "STKCOD": this.stocks[index].STKCOD,
                         "COUNT_STAT": null,
                         "COUNT_BIG_LOCBAL": null,
                         "COUNT_SMALL_LOCBAL": null,
                         "COUNT_ATOM" : null,
                         "make_order" : this.stocks[index].make_order,
                         "object_log": null,
                         "user":  this.stocks[index].user,
                         "counted_at":  this.stocks[index].counted_at,
                         "is_today_adjust" : this.stocks[index].is_today_adjust // Key นี้จะโดนลบเมื่อส่งค่าไป Delete
                    };
     
                    try{ 
                         let result = await StockService.deleteStocksCount(index,params);
                         (result) && Object.assign(this.stocks[index], result) && this.$socket.client.emit('refreshSoft',{"index":index,"data":result});
                         this.stocks[index].stocksHistoryTrigger = true;
                         this.stocksHistoryTrigger = true;
                    }catch(err){console.log(`Delete _products_count_stock  Error: ${err}`)}
               },
               _calPlus(index){
                    let plus = 0;
                    plus = (this.stocks[index].STAT == 'N') ? (this.calculator.CHECK_STAT == 1) ? this.stocks[index].ATOM_ORDQTY : 0 : 0;
                    let big = (plus) ? parseInt(Math.floor(plus/this.stocks[index].CFACTOR)) : 0;
                    let small = (plus) ? parseInt(plus%this.stocks[index].CFACTOR) : 0;
                    return {'BIG':big,'SMALL':small,'ATOM':plus}
               },
      
               async save_count_stock(index) {
                    // ปิด Modal
                    this.closeModal('modal_multi_1');
                    this.productTab = 0;
                    // เพื่อเปิดให้แสดง Indicator กำลังโหลดข้อมูล
                    this.stocks[index].stocksHistoryTrigger = false;
                    this.stocksHistoryTrigger = false;
     
                    // คำนวนค่าจาก Calculator มาใส่
                    let big = this.calculator.cal.reduce(function(result,item) { return result + parseInt(item.leg * item.floor * item.pal) }, 0) + this.calculator.cal_.reduce(function(result,item) { return result + parseInt(item.big ) }, 0) + this._calPlus(index).BIG;
                    this.stocks[index]['COUNT_BIG_LOCBAL'] = parseInt(big);
                    this.stocks[index]['COUNT_SMALL_LOCBAL'] = parseInt(parseInt(this.calculator.cal_[0].small) + this._calPlus(index).SMALL);
                    // ตั้งค่าขา
                    let leg = (this.stocks[index].leg == null || this.stocks[index].leg ==0) ? (this.calculator.cal[0].leg != 0) ? this.calculator.cal[0].leg : 0 : this.stocks[index].leg
                    // สำหรับ _products_count_stocks
                    let params = {
                         "STKCOD": this.stocks[index].STKCOD,
                         "_CFACTOR" : this.stocks[index].CFACTOR,
                         "leg" :  leg,
                         "COUNT_STAT": this.stocks[index].STAT, // ต้องมีเพื่อจัดการเรื่อง สี และ Class
                         "COUNT_BIG_LOCBAL": this.stocks[index].COUNT_BIG_LOCBAL,
                         "COUNT_SMALL_LOCBAL": this.stocks[index].COUNT_SMALL_LOCBAL,
                         "COUNT_ATOM" : (this.stocks[index].COUNT_BIG_LOCBAL * this.stocks[index].CFACTOR) + this.stocks[index].COUNT_SMALL_LOCBAL,
                         "make_order": parseInt(this.stocks[index].make_order),
                         "object_log": JSON.stringify(this.calculator),
                         "user": 1,
                         "counted_at": new Date().toJSON().slice(0, 10)
                    };
                    // สำหรับ _products_count_history
                    let params2 = {
                         "STKCOD": this.stocks[index].STKCOD,
                         "_CFACTOR" : this.stocks[index].CFACTOR,
                         "START_STOCK" : null,
                         "peter_ATOM":  this.stocks[index].ATOM_LOCBAL + this.stocks[index].express_DIFF,
                         "express_ATOM" : (this.stocks[index].BIG_LOCBAL *this.stocks[index].CFACTOR) + this.stocks[index].SMALL_LOCBAL,
                         "COUNT_ATOM" : (this.stocks[index].COUNT_BIG_LOCBAL * this.stocks[index].CFACTOR) + this.stocks[index].COUNT_SMALL_LOCBAL,
                         "counted_at": new Date().toJSON().slice(0, 10),
                         "ship_in_ref" : this.stocks[index].LLK_DOC,
                         "adjust_ref" : (this.stocks[index].ADJUST?.length) ? JSON.stringify(this.stocks[index].ADJUST.map(e=>e.ADJUST_DOCNUM)) : null,
                         "is_today_adjust" : this.stocks[index].is_today_adjust // ใส่ไว้ Check ว่าวันนี้มี Adjust sinvw,j จะลบออกที่หลัง เพราะ ไม่อยู่ใน Field  Database
                    };
                    JSON
                    try {
                         let result = await StockService.saveStocksCount(index,params,params2);
                         (result) && Object.assign(this.stocks[index], result) && this.$socket.client.emit('refreshSoft',{"index":index,"data":result});
                         this.stocks[index].stocksHistoryTrigger = true;
                         this.stocksHistoryTrigger = true;
                    }catch(err){console.log("Save _products_count_stock Error", err)}
               },
               async save(index) {
                    const originalData = { ...this.stocks[index] };
         try {
             // Extract specific parameters from the stocks array using the index
             const params = {
                 STKCOD: this.stocks[index].STKCOD,
                 is_bsts: this.stocks[index].is_bsts,
                 is_watch: this.stocks[index].is_watch,
                 notify: this.stocks[index].notify,
                 hide: this.stocks[index].hide,
                 order_frequency: this.stocks[index].order_frequency,
                 count_code: this.stocks[index].count_code,
                 count_day: this.stocks[index].count_day,
                 barcode: this.stocks[index].barcode // Include barcode
             };
     
             // Optimistically update the local state
             
             Object.assign(this.stocks[index], params);
     
             // Call the service to save the data
             let result = await StockService.saveConfig(index, params);
     
             if (result) {
                 //Object.assign(this.stocks[index], result);
                 this.$socket.client.emit('refreshSoft', { "index": index, "data": result });
             }
         } catch (err) {
             // Revert the changes if there's an error
             Object.assign(this.stocks[index], originalData);
             console.error("Save Error", err);
         }
     },
            
     async save_weight(index) {
         this.closeModal('modal_weight');
         const stock = this.stocks[index];
         const weights = [stock.weight_1, stock.weight_2, stock.weight_3];
         const validWeights = weights
             .filter(weight => weight !== null && weight !== undefined)
             .map(weight => parseFloat(weight)); // Convert to float
     
         const averageWeight = validWeights.reduce((sum, weight) => sum + weight, 0) / validWeights.length;
     
         const params = {
             "STKCOD": this.stocks[index].STKCOD,
             "weight": averageWeight,
             "weight_1": stock.weight_1,
             "weight_2": stock.weight_2,
             "weight_3": stock.weight_3
         };
     
         // Store the original state for potential rollback
         const originalData = { ...this.stocks[index] };
     
         // Optimistically update the UI
         Object.assign(this.stocks[index], params);
     
         // Perform the asynchronous operation
         try {
             let result = await StockService.saveStocksWeight(index, params);
             if (result) {
                 //Object.assign(this.stocks[index], result);
                 this.$socket.client.emit('refreshSoft', { "index": index, "data": result });
             }
         } catch (error) {
             console.error("Error saving weight:", error);
             // Revert the optimistic update if the operation fails
             Object.assign(this.stocks[index], originalData);
         }
     },
               async save_count_stock_last(index) {
                    // ปิด Modal
                    this.closeModal('modal_multi_last');
                    this.productTab = 0;
                    // เพื่อเปิดให้แสดง Indicator กำลังโหลดข้อมูล
                    this.stocks[index].stocksHistoryTrigger = false;
                    this.stocksHistoryTrigger = false;
     
                    // คำนวนค่าจาก Calculator มาใส่
                    let big = this.calculator_last.cal.reduce(function(result,item) { return result + parseInt(item.leg * item.floor * item.pal) }, 0) + this.calculator_last.cal_.reduce(function(result,item) { return result + parseInt(item.big ) }, 0) + this._calPlus(index).BIG;
                    this.stocks[index]['COUNT_BIG_LOCBAL_LAST'] = parseInt(big);
                    this.stocks[index]['COUNT_SMALL_LOCBAL_LAST'] = parseInt(parseInt(this.calculator_last.cal_[0].small) + this._calPlus(index).SMALL);
     
                    // สำหรับ _products_count_stocks
                    let params = {
                         "STKCOD": this.stocks[index].STKCOD,
                         "_CFACTOR_LAST" : this.stocks[index].CFACTOR,
                         "COUNT_BIG_LOCBAL_LAST": this.stocks[index].COUNT_BIG_LOCBAL_LAST,
                         "COUNT_SMALL_LOCBAL_LAST": this.stocks[index].COUNT_SMALL_LOCBAL_LAST,
                         "COUNT_ATOM_LAST" : (this.stocks[index].COUNT_BIG_LOCBAL_LAST * this.stocks[index].CFACTOR) + this.stocks[index].COUNT_SMALL_LOCBAL_LAST,
                         "object_log_last": JSON.stringify(this.calculator_last),
                         "user_last": 1,
                         "counted_at_last": new Date().toJSON().slice(0, 10)
                    };
                    try {
                         let result = await StockService.saveStocksCountLast(index,params);
                         (result) && Object.assign(this.stocks[index], result) && this.$socket.client.emit('refreshSoft',{"index":index,"data":result});
                         this.stocks[index].stocksHistoryTrigger = true;
                         this.stocksHistoryTrigger = true;
                    }catch(err){console.log("Save _products_count_stock Error", err)}
               },
               // คำนวน Class และสี สำหรับ Col-X ของแถบ %
               getPercentClass(row) {
                    let percent = row.PERCENT;
                    let totalCol = 12; // หากเต็ม 100% ให้เป็นกี่ Col-X
                    let start = 100 / totalCol;
                    let colWidth = Math.floor(percent / start);
                    colWidth = (colWidth <= 1) ? 1 : (colWidth >= 12) ? 12 : colWidth;
                    let color = (colWidth <= 3 ) ? "bg-warning" : "bg-success";
                    color = (row.PERCENT == 100) ? "bg-success" : color;
                    let _class = "col-"+colWidth+" "+color;
                    return _class;
               },
               getBackgroundClass(row){
                    let percent = row.PERCENT;
                    let totalCol = 12; // หากเต็ม 100% ให้เป็นกี่ Col-X
                    let start = 100 / totalCol;
                    let colWidth = Math.floor(percent / start);
                    colWidth = (colWidth <= 1) ? 1 : (colWidth >= 12) ? 12 : colWidth;
                    let _class = (colWidth <= 3) ? "bg-warning-x border-warning" : "bg-success-x border-success";
                    _class = (!row.AVG_SEL_PER_DAY_ATOM && (row.ATOM_LOCBAL+row.express_DIFF)>0) ? "bg-danger-x border-danger-x" : _class;
                    _class = (row.is_po) ? "bg-secondary-x" : _class;
                    return _class
               },
               getTextClass(row){
                    let _class = '';
                     if(row.counted_at == null)  _class = 'text-secondary';
                     if(row.counted_at != null)  _class = 'text-light';
                     if(row.counted_at == new Date().toJSON().slice(0, 10)) _class = 'text-warning';
                    return _class;
               },
               // ปริมาณขายเฉลี่ยนต่อ X ปรับไปตาม _products_config 
               getAvgSell(row){
                    return Math.floor(row.AVG_SEL_PER_DAY_ATOM/row.CFACTOR)*row.order_frequency;
               },
               // % สต็อคคงเหลือ ปรับไปตาม _products_config
               getPercent(row){
                    console.time("Get Percent");
                     // PERCENT
                    let result = (Math.floor(((row.ATOM_LOCBAL+row.express_DIFF)/(row.AVG_SEL_PER_DAY_ATOM*row.order_frequency))*100));
                    result = (result) ? result : 0;
                    result = (result === Infinity) ? 0 : result;
                    // กรณีไม่มีข้อมูลประวัติการขาย
                    result = (!row.AVG_SEL_PER_DAY_ATOM) ? 100 : result;
                    console.timeEnd("Get Percent");
                    return result;
               },
               _refresh(){
                    this.refreshFilter = ' '; this.refreshFilter = '';
               },
               customClick(i,sort=['asc']) {
                    let index=0;
                    i.forEach((e) => {
                         const sortEl = this.cols[e];
                         // Reset ทุกอย่างก่อน
                         if(index==0){
                               this.cols.forEach((o) => {
                                   if (o.field !== sortEl.field) o.sort = ''
                              })
                         }
                         sortEl.sort = sort[index]
                         index++;
                    });
               },
               click(event, i, fakeShift = null) {
                    const sortEl = this.cols[i]
                    if (sortEl.field == "PERCENT") this.tricker_sss = true;
                    else this.tricker_sss = false;
                    if (!event.shiftKey && !fakeShift) {
                         this.cols.forEach((o) => {
                              if (o.field !== sortEl.field) o.sort = ''
                         })
                    }
                    if (!sortEl.sort) {
                         this.toset = 'desc'
                         this.toset = (fakeShift != null) ? 'asc' : 'desc'
                    }
                    if (sortEl.sort === 'desc') {
                         this.toset = event.shiftKey || fakeShift ? '' : 'asc'
                    }
                    if (sortEl.sort === 'asc') {
                         this.toset = 'desc'
                    }
                    sortEl.sort = this.toset
               },
               // สำหรับซ่อน records ที่เป็น Null ไว้ท้ายสุด
               sortAsLOCBAL: function (value) {
                    if(value == null)  {
                         if (this.toset == "desc") value = -1;
                         else if (this.toset == "asc") value = 100000;
                    }
                    return parseFloat(value)
               },
               // Sort วันที่
               sortAsDate: function (isoDate) {
                    if (typeof isoDate === 'string' || isoDate instanceof String) isoDate = moment(isoDate).toISOString();
                    return new Date(isoDate).getTime()
               },
               sortStringAsNumber: function(_string){
                    return ( (_string != null) && (_string != '') ) ? parseInt(_string) : 100000;
               },
               // Format วันที่
               date: function (date) {
                    let result;
                    if (!moment(date, "YYYYMMDD").isValid()) result = "ไม่มีข้อมูล"
                    else result = moment(date).format("DD/MM/") + (parseInt(moment(date).format("YY")) + 43);
                    return result;
               },
               todayDate:function(){
                    return moment().format("DD/MM/") + (parseInt(moment().format("YY")) + 43);
               },
               todayDateX:function(){
                    return "วัน"+moment().format("dddd") + "ที่ "+ moment().format("DD MMM ") + (parseInt(moment().format("YY")) + 43);
               },
               // Format Day Ago
               dayAgo: function (date) {
                    let result;
                    if (moment().diff(date, 'days') == 0)
                         result = ":: วันนี้ ::";
                    else if (moment().diff(date, 'days') == 1)
                         result = "เมื่อวาน";
                    else if (moment().diff(date, 'days') >= 2) {
                         result = moment(date, "YYYYMMDD").endOf('day').fromNow();
                    }
                    if (!moment(date, "YYYYMMDD").isValid())
                         result = "ไม่มีข้อมูล"
                    return result;
               },
               numberFormat: function(num){
                    return ( typeof num == 'number') ? num.toLocaleString('en-US', { minimumFractionDigits: 2,maximumFractionDigits:2 }) : 'ผิดพลาด';
               },
               openModalCal(i){
                    if((this.stocks[i].object_log != null) && (this.stocks[i].STAT == this.stocks[i].COUNT_STAT))
                         this.calculator = JSON.parse(this.stocks[i].object_log)
                    if((this.stocks[i].counted_at != new Date().toJSON().slice(0, 10)) || ((this.stocks[i].STAT != this.stocks[i].COUNT_STAT) && (this.stocks[i].STAT != null  && this.stocks[i].COUNT_STAT != null)) || this.stocks[i].is_overrided)
                         this.calReset()
                    this.openModal('modal_multi_1', i);
               },
               openModalWeight(i){
                    this.openModal('modal_weight', i);
               },
               openModalCalLast(i){
                    this.calculator_last = { 'cal': [{'leg': 0, 'floor': 0, 'pal': 0}], 'cal_': [{'big': 0, 'small': 0}],'CHECK_STAT' : 0 };
                    if(this.stocks[i].counted_at_last ==  new Date().toJSON().slice(0, 10))
                         this.calculator_last = JSON.parse(this.stocks[i].object_log_last)
                    this.openModal('modal_multi_last', i);
               }
         },
         beforeDestroy(){
           document.removeEventListener('focusin', this.focusChanged)
         },
         destroyed(){
           this.$barcodeScanner.destroy()
         }
     }
     </script>
     
     <!-- Add "scoped" attribute to limit CSS to this component only -->
     <style lang="scss" >
     
     .bg-light-green{
          background:#C2EF81 !important;
     }
     .bg-light-dark{
          background:#ddd !important;
     }
     
     
     .bg-weight{
          background:#F8F9FA !important;
     }
     .bg-ddd{
          background:#F8F9FA !important;
     }
     .main_button{
          border:1px solid #ddd !important;
          border-radius:0.25em;
     }
     .main_button.active{
          background:#dc3545;
          font-weight:bold !important;
          color:white !important;
          border:1px solid #dc3545 !important;
     }
     .no-padding td{
          padding-top:0px !important;
          padding-bottom:0px !important;
          margin:0px !important;
     }
     .box {
          min-width: 800px;
          max-height : 1200px;
          display : flex;
          flex-direction : column;
          flex-wrap : wrap;
          align-content : flex-start;
          margin : 5px;
     }
     
     
     .form-control:focus {
         -moz-box-shadow:0 0px 0px rgba(0, 0, 0, 0) inset !important;
         box-shadow:0 0px 0px rgba(0, 0, 0, 0) inset !important;
         outline: none !important;
     }
     input:focus::-webkit-input-placeholder {
         opacity: 0;
     }
     .text-yellow{
       color:yellow!important;
     }
     .text-gray{
       color:#555 !important;
     }
     .sticky-top { top: 10px !important}
     .text-not-count{
       color:#888 !important;
     }
     .text-fade{
       color:#ddd;
     }
     .border-tab{
       border-left:1px solid #ddd;
       border-right:1px solid #ddd;
       border-bottom:1px solid #ddd;
       border-bottom-left-radius:0.25em;
       border-bottom-right-radius:0.25em;
     }
     .vue-barcode-element{
       width:100% !important;
       height:100% !important;
     }
     .old_count_stock{
       opacity:0.5;
       filter: grayscale(100%);
     }
     .grayscale{
       opacity:0.8;
       filter: grayscale(100%);
     }
     .edit{
       background:#FFE79F;
       border:2px dashed white !important;
     }
     .cal-modal .modal-content{
       padding:0;
     }
     .peter-card .card-header{
       padding:5px;
       padding-left:15px;
       padding-right:15px;
     }
     
     .peter-modal .modal-header{
       border:0;
     }
     .peter-modal .modal-dialog{
       border:0;
     }
     .peter-modal .modal-content{
       border:0;
     }
     .peter-modal .modal-footer{
       border:0;
     }
     .modal-backdrop{
       background:white !important;
       opacity: 1 !important;
     }
     .disable-dbl-tap-zoom {
       touch-action: manipulation;
     }
     .noselect {
       -webkit-touch-callout: none; /* iOS Safari */
         -webkit-user-select: none; /* Safari */
          -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
             -ms-user-select: none; /* Internet Explorer/Edge */
                 user-select: none; /* Non-prefixed version, currently
                                       supported by Chrome, Edge, Opera and Firefox */
     }
     .modal_count_stock{
       background:red;
     }
     .bg-orange{
          background:orange !important;
     }
     .bg-gray{
          background:#ddd;
     }
     .bg-white{
          background:white;
     }
     .bg-warning-x{
       background:#FFD760 !important;
     }
     .bg-success-x{
       background:#C2EF81 !important;
     }
     .bg-secondary-x{
       background:#aaa !important;
     }
     .bg-danger-x{
          background:#FF8886 !important;
     }
     .border-danger-x{
          border:1px solid #FF6863 !important;
     }  
     .fadex{
       opacity: 0.15 !important;
     }   
     .fadex2{
       opacity: 0.3 !important;
     }
     .peter-radio{
       display:block !important;
     }
     .peter-radio label{
       text-align:left !important;
       padding-left:10px;
     }
     .peter-radio label.active{
       font-weight:bold;
       border:1px solid #ddd !important;
     }
     .peter-radio input[type="radio"]{
       position:relative !important;
       margin-right:8px !important;
       background-color: #fff;
     }
     
     
     .peter-radio .custom-control-label{
       width:100%;
     }
     
     
     input.largerCheckbox{
       display:none;
          width: 28px;
          height: 28px;
     }
     .remove-border td{
       border:0 !important;
       padding-top:0 !important;
       padding-bottom:0 !important;
     }
     .modal-title {
       font-weight: bolder;
     }
     .gg-select {
       box-sizing: border-box;
       position: relative;
       display: block;
       transform: scale(var(--ggs, 1));
       width: 22px;
       height: 22px;
     }
     .gg-select::after,
     .gg-select::before {
       content: '';
       display: block;
       box-sizing: border-box;
       position: absolute;
       width: 8px;
       height: 8px;
       left: 7px;
       transform: rotate(-45deg);
     }
     .gg-select::before {
       border-left: 2px solid;
       border-bottom: 2px solid;
       bottom: 4px;
       opacity: 0.3;
     }
     .gg-select::after {
       border-right: 2px solid;
       border-top: 2px solid;
       top: 4px;
       opacity: 0.3;
     }
     th.sort {
       cursor: pointer;
       user-select: none;
       &.asc {
         .gg-select::after {
           opacity: 1;
         }
       }
       &.desc {
         .gg-select::before {
           opacity: 1;
         }
       }
     }
     
     
     
     </style>