import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin, BootstrapVueIcons } from 'bootstrap-vue'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'

import VueBarcodeScanner from 'vue-barcode-scanner' 
Vue.use(VueBarcodeScanner)

import VueSocketio from 'vue-socket.io-extended';
import io from 'socket.io-client';
//const url = "http://localhost:8080/"
//const url = "https://notify-server-337209.de.r.appspot.com/"
// const url = (process.env.NODE_ENV == "development") ? "https://notify-server-739126534a75.herokuapp.com/" : "https://notify-server-739126534a75.herokuapp.com/";
//const url = "https://notify-server-739126534a75.herokuapp.com/"


 const isDev = process.env.NODE_ENV == 'development'
//const url = isDev ? "http://localhost:8081/" : "https://notify-server-739126534a75.herokuapp.com/";
const url = isDev ? "http://localhost:8081/" : "https://notification-server.thaisugars.com/";








Vue.use(VueSocketio, io(url,{maxHttpBufferSize: 1e8}));

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(BootstrapVueIcons)


Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
